import { PlayerUsernameSelectionPageErrors } from '../../player/application/pages/PlayerUsernameSelectionPage/PlayerUsernameSelectionPage.constants';

export const PlayerRoutePrefix = '/p';
export const HostRoutePrefix = '/h';

export enum RouteNames {
  Host = `${HostRoutePrefix}/:teamId/:geniallyId/:sessionId`,
  HostWithoutSessionId = `${HostRoutePrefix}/:teamId/:geniallyId`,

  Player = `${PlayerRoutePrefix}/:geniallyId/:sessionId/:username`,
  PlayerUsernameSelection = `${PlayerRoutePrefix}/:geniallyId/:sessionId`,
  PlayerUsernameInUse = `${PlayerRoutePrefix}/:geniallyId/:sessionId?error=${PlayerUsernameSelectionPageErrors.UsernameInUse}&username=:username`,
  PlayerSessionBlocked = `${PlayerRoutePrefix}/:geniallyId/:sessionId?error=${PlayerUsernameSelectionPageErrors.SessionBlocked}&username=:username`,
  PlayersLimitReached = `${PlayerRoutePrefix}/players-limit-reached`,
  PlayerSessionFinder = PlayerRoutePrefix,

  PlayerKicked = `${PlayerRoutePrefix}/kicked`,

  NotFound = '/404',
  UnknownError = '/500',

  NoGeniallyAccess = '/403',
  GeniallyDraft = '/403/genially-draft',
  GeniallyWithPassword = '/403/genially-with-password',
}
