import { Fragment, useState } from 'react';
import { observer } from 'mobx-react';

import { BasicParagraph, BiggerParagraph, Button, Input } from '@genially/design-system';
import type { HostPlayP2pClient } from '@genially/p2p-client';
import type { BaseWebsocketClientToServerPongMessage } from '@genially/p2p-lib';
import { BaseWebsocketClientToServerMessageType } from '@genially/p2p-lib';

let cachedPingPid: number | undefined;

export const Benchmark = observer(({ client }: { client: HostPlayP2pClient }) => {
  const [numMessagesPerPlayer, setNumMessagesPerPlayer] = useState(10);
  const [messageSize, setMessageSize] = useState(512);

  const [pingPid, setPingPid] = useState<number | undefined>(cachedPingPid);

  const sendPing = () => {
    const message: BaseWebsocketClientToServerPongMessage = {
      type: BaseWebsocketClientToServerMessageType.Pong,
    };

    const messageInKb = JSON.stringify(message).length / 1024;

    client.benchmark(1, messageInKb, true);
  };

  const onSendPings = () => {
    cachedPingPid = window.setInterval(() => {
      sendPing();
    }, 1000);

    setPingPid(cachedPingPid);
  };

  const onStopPings = () => {
    if (cachedPingPid) {
      window.clearInterval(cachedPingPid);
      cachedPingPid = undefined;
      setPingPid(undefined);
    }
  };

  const onSendBenchmark = () => {
    onStopPings();

    client.benchmark(numMessagesPerPlayer, messageSize);
  };

  const onStopBenchmark = () => {
    client.stopBenchmark();
  };

  const benchmarkData = client.lastBenchmarkData;

  return (
    <div className="modal-content">
      <BiggerParagraph>Benchmark</BiggerParagraph>

      <BasicParagraph>Simulated messages per player:</BasicParagraph>
      <Input
        type="number"
        value={numMessagesPerPlayer.toString()}
        onChange={e => setNumMessagesPerPlayer(e.target.valueAsNumber)}
        placeholder="10"
      />
      <br />
      <BasicParagraph>Messages size (KB):</BasicParagraph>
      <Input
        type="number"
        value={messageSize.toString()}
        onChange={e => setMessageSize(e.target.valueAsNumber)}
        placeholder="512"
      />
      <br />

      <pre style={{ overflowY: 'auto' }}>
        <code>
          {benchmarkData ? (
            <Fragment>
              <div>
                <BasicParagraph>ID: {benchmarkData.id}</BasicParagraph>
                <br />
                <BasicParagraph>
                  Amount of messages sent per player: {benchmarkData.amountOfMessagesSent}
                </BasicParagraph>
                <br />
                <BasicParagraph>KB received: {benchmarkData.kbReceived}</BasicParagraph>
                <br />
                <BasicParagraph>
                  Amount of messages received / expected:{' '}
                  {benchmarkData.amountOfMessagesReceived} /{' '}
                  {benchmarkData.amountOfMessagesExpected}
                </BasicParagraph>
              </div>

              <div>
                <BasicParagraph>
                  Legend:
                  <br />
                  <span style={{ color: '#358517' }}>Means connected</span>
                  <br />
                  <span style={{ color: '#d5b43b' }}>Means disconnected</span>
                </BasicParagraph>
                <br />
                {Object.entries(benchmarkData.infoPerPlayer).map(([playerId, info]) => {
                  const {
                    amountOfMessagesReceived,
                    minTime,
                    maxTime,
                    avgTime,
                    kbReceived,
                  } = info;

                  const isConnected = client.isPlayerConnected(playerId);

                  return (
                    <BasicParagraph key={playerId} style={{ marginBottom: '0.5rem' }}>
                      <span style={{ color: isConnected ? '#358517' : '#d5b43b' }}>
                        Player {playerId}: {amountOfMessagesReceived} messages
                      </span>
                      <br />
                      Min: {minTime} ms, Max: {maxTime} ms, Avg: {avgTime} ms,{' '}
                      {kbReceived} KB
                    </BasicParagraph>
                  );
                })}
              </div>
            </Fragment>
          ) : (
            'No benchmark data'
          )}
        </code>
      </pre>

      <br />

      <div style={{ display: 'flex', gap: '1rem' }}>
        <Button disabled={benchmarkData?.running === true} onClick={onSendBenchmark}>
          Send benchmark
        </Button>
        <Button disabled={!benchmarkData?.running} onClick={onStopBenchmark}>
          Stop benchmark
        </Button>
        <Button disabled={pingPid !== undefined} onClick={onSendPings}>
          Send pings
        </Button>
        <Button disabled={pingPid === undefined} onClick={onStopPings}>
          Stop pings
        </Button>
      </div>
    </div>
  );
});
