import { useCallback, useEffect, useState } from 'react';
import Lottie from 'lottie-react';

import { LottieAnimations } from './types/lottieAnimations';
import { LottieAnimationsSequences } from './types/lottieAnimationsSequences';
import { Wrapper } from './LottieAnimation.styled';

interface LottieAnimationProps {
  data?: LottieAnimations;
  endLoopListener?: (() => void)[];
  handleIsCentered?: (isCentered: boolean) => void;
}

export const LottieAnimation = ({
  data,
  endLoopListener = [],
  handleIsCentered,
}: LottieAnimationProps) => {
  const [currentAnimationData, setCurrentAnimationData] = useState<object | null>(null);
  const [nextAnimationData, setNextAnimationData] = useState<object[]>([]);
  const [isCentered, setIsCentered] = useState<boolean>(false);

  const [show, setShow] = useState<boolean>(false);

  const handlePlay = useCallback(
    (animationData: LottieAnimations) => {
      const animations = LottieAnimationsSequences[animationData];

      if (!show) {
        setShow(true);

        setCurrentAnimationData(animations[0]);
      } else {
        setNextAnimationData(animations);
      }
    },
    [show],
  );

  const handleStop = () => {
    setShow(false);
  };

  useEffect(() => {
    if (data !== undefined) {
      handlePlay(data);
    } else {
      handleStop();
    }
  }, [data, handlePlay]);

  useEffect(() => {
    setIsCentered(
      !!currentAnimationData &&
        LottieAnimationsSequences[LottieAnimations.Spinner].includes(
          currentAnimationData as any,
        ),
    );
  }, [currentAnimationData]);

  useEffect(() => {
    handleIsCentered?.(isCentered);
  }, [handleIsCentered, isCentered]);

  const handleLoopComplete = () => {
    if (nextAnimationData.length > 0) {
      setCurrentAnimationData(nextAnimationData[0]);
      setNextAnimationData(nextAnimationData.slice(1));
    }

    endLoopListener.forEach(listener => listener());
  };

  return (
    <Wrapper $isCentered={isCentered}>
      {show && (
        <Lottie
          animationData={currentAnimationData}
          onLoopComplete={handleLoopComplete}
        />
      )}
    </Wrapper>
  );
};
