import { resolveAllTaskRefs } from '@genially/document-model';
import type { GeniallyContent } from '@genially/ui';
import { regenerateSlideItemsZIndexes } from '@genially/utils';

export const dataFormater = ({
  Areas = [],
  Arrows = [],
  ContainerBoxes = [],
  Audios = [],
  Videos = [],
  RichContents = [],
  Pins = [],
  Images = [],
  Svgs = [],
  Texts = [],
  Groups = [],
  Charts = [],
  Activities = [],
  ...restData
}: any): GeniallyContent => {
  const data = resolveAllTaskRefs(
    {
      ...regenerateSlideItemsZIndexes({
        Areas,
        Arrows,
        ContainerBoxes,
        Audios,
        Videos,
        RichContents,
        Pins,
        Images,
        Svgs,
        Texts,
        Groups,
        Charts,
        Activities,
      }),
      ...restData,
    },
    restData.tasks,
    true,
  );

  return {
    activities: data.Activities,
    areas: data.Areas,
    arrows: data.Arrows,
    audios: data.Audios,
    charts: data.Charts,
    containerBoxes: data.ContainerBoxes,
    groups: data.Groups,
    images: data.Images,
    pins: data.Pins,
    richContents: data.RichContents,
    svgs: data.Svgs,
    texts: data.Texts,
    videos: data.Videos,
    genially: data.Genially,
    slides: data.Slides,
    documentConfig: data.DocumentConfig,
    interactivityActions: data.InteractivityActions,
  };
};
