import type { Color } from '@genially/design-system';
import { NotificationManager as DsNotificationManager } from '@genially/design-system';
import type { NotificationMessage } from '@genially/design-system/build/components/notifications/NotificationManager';

const DEFAULT_TOAST_TIMEOUT = 3000;

export class NotificationManager {
  private color: Color;

  private notificationManager;

  constructor(color: Color) {
    this.color = color;
    this.notificationManager = DsNotificationManager;
  }

  success(message: NotificationMessage, timeOut = DEFAULT_TOAST_TIMEOUT) {
    this.notificationManager.success(
      message,
      timeOut,
      this.color.background.success.default(),
      this.color.content.primary.default(),
    );
  }

  error(message: NotificationMessage, timeOut = DEFAULT_TOAST_TIMEOUT) {
    this.notificationManager.error(
      message,
      timeOut,
      this.color.background.error.default(),
      this.color.content.primary.default(),
    );
  }

  warning(message: NotificationMessage, timeOut = DEFAULT_TOAST_TIMEOUT) {
    this.notificationManager.warning(
      message,
      timeOut,
      this.color.background.warning.default(),
      this.color.content.primary.default(),
    );
  }
}
