import type { ElementType } from 'react';
import styled, { css, keyframes } from 'styled-components';

const fadeOutKeyframes = keyframes({
  from: {
    opacity: 1,
  },

  to: {
    opacity: 0,
  },
});

interface withFadeOutAnimationProps {
  $hide?: boolean;
}

const withFadeOutAnimation = (Component: ElementType) =>
  styled(Component)<withFadeOutAnimationProps>(({ $hide }) => {
    if ($hide) {
      return css`
        animation: ${fadeOutKeyframes} 0.5s ease-in-out;
      `;
    }

    return '';
  });

export const Wrapper = withFadeOutAnimation(
  styled.div(({ theme: { color } }) => ({
    width: '100%',
    height: '100%',
    backgroundColor: color.background.reversed.default(),
    color: color.content.reversed.default(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })),
);
