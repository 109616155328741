import { useCallback, useEffect, useState } from 'react';
import { useWakeLock, type WakeLockOptions } from 'react-screen-wake-lock';

import { useDocumentVisibility } from '@genially/ui/build/hooks/useDocumentVisibility';

type useScreenWakeLockProps = WakeLockOptions;

export const useScreenWakeLock = (props?: useScreenWakeLockProps) => {
  const [manuallyReleased, setManuallyReleased] = useState(false);

  const {
    isSupported,
    release: internalRelease,
    released,
    request: internalRequest,
    type: internalType,
  } = useWakeLock(props);

  const documentVisibility = useDocumentVisibility();

  const request = useCallback(
    (type?: WakeLockType) => {
      if (isSupported && !internalType) {
        internalRequest(type);
      }
    },
    [isSupported, internalType, internalRequest],
  );

  const release = useCallback(
    (auto = false) => {
      if (!auto) {
        setManuallyReleased(true);
      }

      return internalRelease();
    },
    [internalRelease],
  );

  useEffect(() => {
    if (!!internalType && documentVisibility === 'hidden' && !released) {
      release(true);
    } else if (documentVisibility === 'visible' && released && !manuallyReleased) {
      request();
    }
  }, [documentVisibility, internalType, manuallyReleased, release, released, request]);

  return { isSupported, release, released, request, type: internalType };
};
