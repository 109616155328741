import styled from 'styled-components';

import type { Size } from '@genially/design-system';
import { BasicSubtitle, style } from '@genially/design-system';

import { ResponsiveText } from '../ResponsiveText/ResponsiveText';

interface HasRightComponentProps {
  $hasRightComponent: boolean;
}

interface IsOverlayProps {
  $isOverlay: boolean;
}

interface WrapperProps extends HasRightComponentProps {
  $opacity: number;
}

export const GeniallyLogoWrapper = styled.div(({ theme: { size } }) => ({
  width: size.units(12),
}));

const getWrapperWithLogoPadding = (
  size: Size,
  isOverlay?: boolean,
  hasRightComponent?: boolean,
) => {
  if (isOverlay) {
    return 0;
  }

  return hasRightComponent ? `0 ${size.units(16)}` : `0 ${size.units(3)}`;
};

export const WrapperWithLogo = styled.div<HasRightComponentProps & IsOverlayProps>(
  ({ theme: { size }, $hasRightComponent, $isOverlay }) => ({
    width: '100%',
    height: '100dvh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: getWrapperWithLogoPadding(size, $isOverlay, $hasRightComponent),

    ...style.mixins.responsive.md.downward({
      alignItems: 'center',
      gap: size.units(6),
    }),

    ...style.mixins.responsive.sm.downward({
      padding: $isOverlay ? 0 : `0 ${size.units(3)}`,
    }),
  }),
);

export const Wrapper = styled.div<WrapperProps & IsOverlayProps>(
  ({ theme: { size, color }, $opacity, $hasRightComponent, $isOverlay }) => ({
    width: '100%',
    height: $isOverlay ? '100%' : undefined,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: $isOverlay ? `0 ${size.units(6)}` : undefined,
    gap: size.units(9),

    ...style.mixins.responsive.md.downward({
      flexDirection: $hasRightComponent ? 'column' : undefined,
      gap: size.units(4),
      textAlign: $hasRightComponent ? 'center' : undefined,
      padding: $isOverlay ? `0 ${size.units(4)}` : undefined,
    }),

    ...style.mixins.responsive.sm.downward({
      flexDirection: $hasRightComponent ? 'column-reverse' : 'column',
      gap: size.units(2.5),
      padding: `0 ${size.units(3)}`,
    }),

    backgroundColor: color.background.reversed.default($opacity),
  }),
);

export const ErrorCode = styled(BasicSubtitle)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));

export const Title = styled(ResponsiveText)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),

  ...style.mixins.responsive.sm.downward({
    textAlign: 'center',
  }),
}));

export const Content = styled(ResponsiveText)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),

  ...style.mixins.responsive.sm.downward({
    textAlign: 'center',
  }),
}));

export const ButtonContainer = styled.div<HasRightComponentProps>(
  ({ theme: { size }, $hasRightComponent }) => ({
    display: 'flex',
    gap: size.units(2),

    ...style.mixins.responsive.md.downward({
      justifyContent: $hasRightComponent ? 'center' : undefined,
    }),

    ...style.mixins.responsive.xs.downward({
      width: '100%',
      flexDirection: 'column',

      '> button': {
        width: '100%',
      },
    }),
  }),
);

export const LeftColumn = styled.div<HasRightComponentProps>(
  ({ theme: { size }, $hasRightComponent }) => ({
    display: 'flex',
    flexDirection: 'column',

    maxWidth: $hasRightComponent ? undefined : size.units(60),
    flex: $hasRightComponent ? 1 : undefined,

    ...style.mixins.responsive.md.downward({
      justifyContent: $hasRightComponent ? 'flex-end' : undefined,
    }),

    ...style.mixins.responsive.sm.downward({
      alignItems: 'center',
      justifyContent: $hasRightComponent ? 'flex-end' : 'center',

      [`${ErrorCode}`]: {
        display: 'none',
      },
    }),

    [`${ErrorCode}`]: {
      marginBottom: size.units(2),
    },

    [`${Title}`]: {
      marginBottom: size.units(3),
    },

    [`${ButtonContainer}`]: {
      marginTop: size.units(3),
    },
  }),
);

export const Image = styled.img(() => ({
  width: '100%',
}));

export const RightColumn = styled.div<HasRightComponentProps>(
  ({ theme: { size }, $hasRightComponent }) => ({
    width: $hasRightComponent ? '100%' : undefined,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: $hasRightComponent ? 1 : undefined,

    [Image]: {
      maxWidth: size.units(75),
    },

    ...style.mixins.responsive.sm.downward({
      order: -1,

      [`${Image}`]: {
        maxWidth: size.units(34),
      },
    }),
  }),
);
