import type { ElementType } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { style } from '@genially/design-system';
import { UserAliasAvatar } from '@genially/ui/build/components/UserAliasAvatar/UserAliasAvatar';

import { ResponsiveText } from '../../../../../ResponsiveText/ResponsiveText';
import {
  fadeInAnimation,
  rotateAnimation,
  slideFromBackAnimation,
  translateAnimation,
} from './animations';
import {
  Position,
  PositionAnimationEnterAnimation,
  PositionAnimationSequence,
  PositionAnimationSize,
} from './constants';

export const StyledUserAliasAvatar = styled(UserAliasAvatar)(({ theme: { size } }) => ({
  width: '100%',
  height: '100%',

  '& > *': {
    fontSize: '1em',
  },
}));

const medalFadeInKeyframes = keyframes({
  from: {
    transform: 'translate(25%, 25%) rotateY(180deg) scale(0)',
    opacity: 0,
  },

  to: {
    transform: 'translate(25%, 25%) rotateY(180deg) scale(1)',
    opacity: 1,
  },
});

interface withMedalAnimationProps {
  $pinMedal?: boolean;
}

const withMedalAnimation = (Component: ElementType) =>
  styled(Component)<withMedalAnimationProps>(({ $pinMedal }) => {
    if ($pinMedal) {
      return css`
        animation: ${medalFadeInKeyframes} 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      `;
    }

    return '';
  });

export const Medal = withMedalAnimation(
  styled.img({
    width: '100%',
    height: '100%',
    aspectRatio: '1/1',
  }),
);

const enterSlideFromBackMedalAnimation = keyframes(slideFromBackAnimation);

const enterFadeInMedalAnimation = keyframes(fadeInAnimation);

const translateMedalAnimation = keyframes(translateAnimation);

const getAnimationData = (
  animation?: PositionAnimationSequence,
  enterAnimation?: PositionAnimationEnterAnimation,
) => {
  if (animation === PositionAnimationSequence.Enter) {
    if (enterAnimation === PositionAnimationEnterAnimation.FadeIn) {
      return fadeInAnimation;
    }

    return slideFromBackAnimation;
  }

  if (animation === PositionAnimationSequence.Flip) {
    return rotateAnimation;
  }

  if (animation === PositionAnimationSequence.Translate) {
    return translateAnimation;
  }

  return translateAnimation;
};

interface withMedalAnimationProps {
  $animationSequence?: PositionAnimationSequence;
  $enterAnimation?: PositionAnimationEnterAnimation;
}

const withMedalWrapperAnimation = (Component: ElementType) =>
  styled(Component)<withMedalAnimationProps>(
    ({ $animationSequence, $enterAnimation }) => {
      if ($animationSequence === PositionAnimationSequence.Enter) {
        if ($enterAnimation === PositionAnimationEnterAnimation.FadeIn) {
          return css`
            animation: ${enterFadeInMedalAnimation} 0.7s
              cubic-bezier(0.25, 0.46, 0.45, 0.94);
          `;
        }

        return css`
          animation: ${enterSlideFromBackMedalAnimation} 0.7s
            cubic-bezier(0.25, 0.46, 0.45, 0.94);
        `;
      }

      if ($animationSequence === PositionAnimationSequence.Translate) {
        return css`
          animation: ${translateMedalAnimation} 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        `;
      }

      return '';
    },
  );

interface MedalWrapperProps extends withMedalAnimationProps {
  $pinMedal?: boolean;
  $size?: PositionAnimationSize;
}

export const MedalWrapper = withMedalWrapperAnimation(
  styled.div<MedalWrapperProps>(
    ({ theme: { size }, $animationSequence, $enterAnimation, $pinMedal, $size }) => ({
      width: $size === PositionAnimationSize.Large ? size.units(42) : size.units(26),
      height: $size === PositionAnimationSize.Large ? size.units(42) : size.units(26),

      fontSize: $size === PositionAnimationSize.Large ? size.units(23) : size.units(12),

      position: 'absolute',
      borderRadius: '50%',
      transformStyle: 'preserve-3d',
      transform: getAnimationData($animationSequence, $enterAnimation).to.transform,
      transformOrigin: getAnimationData($animationSequence, $enterAnimation).to
        .transformOrigin,
      transition: 'transform 400ms ease-out',
      top: '50%',
      left: getAnimationData($animationSequence, $enterAnimation).to.left,
      zIndex: 1,

      [StyledUserAliasAvatar]: {
        backfaceVisibility: 'hidden',
        transform: 'rotateY(180deg)',
      },

      [Medal]: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        backfaceVisibility: 'hidden',

        ...($pinMedal && {
          position: 'absolute',
          transform: 'translate(25%, 25%) rotateY(180deg)',
          zIndex: 1,
          width: '40%',
          height: '40%',
        }),
      },

      ...style.mixins.responsive.xs.downward({
        width: $size === PositionAnimationSize.Large ? size.units(28) : size.units(20),
        height: $size === PositionAnimationSize.Large ? size.units(28) : size.units(20),

        fontSize: $size === PositionAnimationSize.Large ? size.units(14) : size.units(9),
      }),
    }),
  ),
);

export const getPositionNumberColor = (position: Position) => {
  if (position === Position.Gold) {
    return '#f7f7f6';
  }

  if (position === Position.Silver) {
    return '#f6f5f5';
  }

  return 'rgba(247,198,162,0.8)';
};

interface WrapperProps {
  $hide: boolean;
  $center?: boolean;
  $size?: PositionAnimationSize;
}

export const Wrapper = styled.div<WrapperProps>(
  ({ theme: { transition, size }, $hide, $center, $size }) => ({
    maxWidth: size.units(87.5),
    position: 'relative',
    opacity: $hide ? 0 : 1,
    transition: `${transition.standard('opacity')}, transform 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)`,

    ...($center && {
      transform: `translateX(${$size === PositionAnimationSize.Large ? size.units(42 / 4) : size.units(26 / 4)}) translatex(16px)`,

      ...style.mixins.responsive.xs.downward({
        transform: `translateX(${$size === PositionAnimationSize.Large ? size.units(28 / 4) : size.units(20 / 4)}) translatex(16px)`,
      }),
    }),

    ...style.mixins.responsive.xs.downward({
      maxWidth: `calc(100% - ${$size === PositionAnimationSize.Large ? size.units(28) : size.units(20)})`,
    }),
  }),
);

const userAliasEnter = keyframes({
  from: {
    transform: 'translateX(-50px)',
    opacity: 0,
  },

  top: {
    transform: 'translateX(0)',
    opacity: 1,
  },
});

interface WithUserAliasEnterAnimationProps {
  $show: boolean;
}

const WithUserAliasEnterAnimation = (Component: ElementType) =>
  styled(Component)<WithUserAliasEnterAnimationProps>(({ $show }) => {
    if ($show) {
      return css`
        animation: ${userAliasEnter} 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      `;
    }

    return '';
  });

export const UserAlias = WithUserAliasEnterAnimation(
  styled(ResponsiveText)<WithUserAliasEnterAnimationProps>(
    ({ theme: { color }, $show }) => ({
      color: color.content.reversed.default(),
      opacity: $show ? 1 : 0,
      whiteSpace: 'break-spaces',
      wordBreak: 'break-word',
    }),
  ),
);
