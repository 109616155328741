import { useContext } from 'react';

import type { PlayerClientContextType } from '../PlayerClientProvider';
import { PlayerClientContext } from '../PlayerClientProvider';

export const usePlayerClientContext = () => {
  const context = useContext(PlayerClientContext) as PlayerClientContextType;

  if (Object.keys(context).length === 0) {
    throw new Error('usePlayerClientContext must be used within a PlayerClientProvider');
  }

  return context;
};
