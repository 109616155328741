import { useTranslate } from 'react-polyglot';

import { BaseModal, Button, WarningModal } from '@genially/design-system';
import { HostPlayP2pClient } from '@genially/p2p-client';

import { Benchmark } from './components/Benchmark';

export const CheatModal = ({
  parentElement,
  closeModal,
  client,
}: {
  parentElement: HTMLElement;
  closeModal: () => void;
  client: HostPlayP2pClient;
}) => {
  return (
    <BaseModal
      close={closeModal}
      width={BaseModal.Size.LARGE}
      parentElement={parentElement}
    >
      <Benchmark client={client} />
    </BaseModal>
  );
};
