import type { P2pClient } from '@genially/p2p-client';

import { useModal } from '../../../../../bootstrap/application/components/app/components/ModalProvider/ModalProvider.context';
import { DebugModal } from '../DebugModal';

export const useShowDebugModal = (client: P2pClient<any, any, any, any>) => {
  const { openModal, closeModal } = useModal();

  const showDebugModal = () => {
    openModal(parentElement => (
      <DebugModal parentElement={parentElement} closeModal={closeModal} client={client} />
    ));
  };

  const hideDebugModal = () => {
    closeModal();
  };

  return {
    showDebugModal,
    hideDebugModal,
  };
};
