import { useCallback, useState } from 'react';

import { useEffectOnce } from '@genially/ui/build/hooks/useEffectOnce';

/**
 * Hook to sleep for a given amount of time
 * @param ms
 */
export const useSleep = (ms: number) => {
  const [ended, setEnded] = useState(false);
  const [stopped, setStopped] = useState(false);

  const [timeoutPid, setTimeoutPid] = useState<ReturnType<typeof setTimeout> | undefined>(
    undefined,
  );
  const running = timeoutPid !== undefined;

  const clear = useCallback(() => {
    if (timeoutPid) {
      clearTimeout(timeoutPid);
    }

    setTimeoutPid(undefined);
  }, [timeoutPid]);

  const start = useCallback(() => {
    clear();

    setEnded(false);
    setStopped(false);

    const timeout = setTimeout(() => {
      setEnded(true);
      clear();
    }, ms);

    setTimeoutPid(timeout);
  }, [clear, ms]);

  const stop = useCallback(() => {
    clear();

    setStopped(true);
  }, [clear]);

  useEffectOnce(() => {
    start();

    return () => {
      if (timeoutPid) {
        clearTimeout(timeoutPid);
      }
    };
  });

  const restart = useCallback(() => {
    start();
  }, [start]);

  return { running, ended, stopped, stop, restart };
};
