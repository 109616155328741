import { useCallback } from 'react';
import { useTranslate } from 'react-polyglot';
import { observer } from 'mobx-react';

import { PlaySidebarSummary } from '../../../../../../../../shared/application/components/PlaySidebarSummary/PlaySidebarSummary';
import { useNotificationManager } from '../../../../../../../../shared/application/services/useNotificationManager';
import { useHostClientContext } from '../../../HostClientProvider/hooks/useHostClientContext';
import { useHostGameContext } from '../../HostGame.context';

export const HostPlaySidebarSummary = observer(() => {
  const t = useTranslate();

  const { client } = useHostClientContext();
  const { sidebarSummary, viewConnection } = useHostGameContext();
  const { selectedQuestion } = sidebarSummary;
  const { notificationManager } = useNotificationManager();

  const handleOpenAnswerToggleVisibility = useCallback(
    (alias: string) => {
      if (!selectedQuestion) {
        return;
      }

      const isHidden = client.toggleOpenAnswerVisibility(
        alias,
        selectedQuestion?.interactiveQuestion.Id,
      );

      if (isHidden) {
        notificationManager.success(
          t('__new.play.ranking.creator.hiddenOpenAnswer.toast'),
        );
      }
    },
    [client, selectedQuestion, notificationManager, t],
  );

  return (
    <PlaySidebarSummary
      client={client}
      isOpen={sidebarSummary.isOpen}
      toggleOpen={sidebarSummary.toggle}
      selectedQuestion={selectedQuestion?.interactiveQuestion}
      omitAnimations
      countMySelfAsParticipant={false}
      isLastScoredQuestion={selectedQuestion?.isLastScored}
      connection={viewConnection.connection}
      toggleOpenAnswerVisibility={handleOpenAnswerToggleVisibility}
    />
  );
});
