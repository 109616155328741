import { useTranslate } from 'react-polyglot';

import { Button } from '@genially/design-system';
import { GeniallyIconIcon } from '@genially/icons';

import { useAuthenticatedUser } from '../../../../bootstrap/application/components/app/components/AuthenticatedUserProvider/AuthenticatedUserProvider.context';
import { config } from '../../../../bootstrap/application/config/config';
import { ErrorPageContent } from '../../components/ErrorPageContent/ErrorPageContent';
import NoGeniallyAccessErrorImage from './assets/NoGeniallyAccessErrorImage.png';

export const NoGeniallyAccessErrorPage = () => {
  const { authenticatedUser } = useAuthenticatedUser();
  const t = useTranslate();

  const handleButtonClick = () => {
    window.location.href = authenticatedUser
      ? config.urls.dashboard
      : `${config.urls.auth}/signup?from=play`;
  };

  const buttonTextKey = authenticatedUser
    ? '__new.play.finalScreen.autenticatedUser.button'
    : '__new.play.finalScreen.noAutenticatedUser.button';

  return (
    <ErrorPageContent
      imageSource={NoGeniallyAccessErrorImage as string}
      title={t('__new.play.audienceLimit.player.title')}
      content={t('__new.play.errorScreen.description')}
      button={
        <Button
          StartIcon={<GeniallyIconIcon />}
          onClick={handleButtonClick}
          variant={Button.Variant.CTA}
          size={Button.Size.BIG}
        >
          {t(buttonTextKey)}
        </Button>
      }
    />
  );
};
