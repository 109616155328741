import { QRCodeSVG } from 'qrcode.react';
import styled from 'styled-components';

import {
  BasicParagraph,
  BiggerSubtitle,
  BigSubtitle,
  style,
} from '@genially/design-system';
import { CopyIcon } from '@genially/icons';

import { HostGameBreakdownSteps } from '../../../../host/application/pages/HostGamePage/components/HostGame/HostGame.constants';
import { HostLobbyBreakdownSteps } from '../../../../host/application/pages/HostGamePage/components/HostLobby/HostLobby.constants';

export const TopWrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: size.units(1),

  ...style.mixins.responsive.custom(`${HostGameBreakdownSteps.forth}px`).upward({
    gap: size.units(2.5),
  }),
}));

export const ContentWrapper = styled.div(({ theme: { size } }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: size.units(2),

  ...style.mixins.responsive.custom(`${HostGameBreakdownSteps.forth}px`).upward({
    gap: size.units(2.5),
  }),
}));

export const StyledQRCodeSVG = styled(QRCodeSVG)(() => ({
  width: '100%',
  height: '40%',
  flexShrink: 0,

  ...style.mixins.responsive.custom(`${HostGameBreakdownSteps.forth}px`).upward({
    height: '70%',
  }),
}));

export const StyledCopyIcon = styled(CopyIcon)(({ theme: { size } }) => ({
  ...style.mixins.responsive.custom(`${HostLobbyBreakdownSteps.second}px`).downward({
    width: size.units(2),
    height: size.units(2),
  }),
}));

export const TextWrapper = styled.div(({ theme: { size, transition } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: size.units(2.5),
  cursor: 'pointer',
  transition: transition.standard(),

  ':hover': {
    transform: 'scale(0.95)',
  },
}));

export const StyledBasicParagraph = styled(BasicParagraph)(() => ({
  textAlign: 'center' as const,
}));

export const StyledBigSubtitle = styled(BigSubtitle)(() => ({
  textAlign: 'center' as const,
}));

export const ButtonsWrapper = styled.div(({ theme: { size } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: size.units(2),
}));
