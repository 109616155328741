import { useState } from 'react';
import { Flipped } from 'react-flip-toolkit';
import { useTranslate } from 'react-polyglot';
import { observer } from 'mobx-react';

import {
  BiggerSubtitle,
  BigSubtitle,
  Breakpoint,
  useBreakpoint,
} from '@genially/design-system';
import type { PlayerData } from '@genially/p2p-lib';

import { useResizeObserver } from '../../../../../../../../../../../packages/document-components/build';
import { useModal } from '../../../../../../../../bootstrap/application/components/app/components/ModalProvider/ModalProvider.context';
import { KickPlayerConfirmModal } from '../../../../../../../../shared/application/components/Lobby/components/PlayersList/components/KickPlayerConfirmModal/KickPlayerConfirmModal';
import { useNotificationManager } from '../../../../../../../../shared/application/services/useNotificationManager';
import { useHostClientContext } from '../../../HostClientProvider/hooks/useHostClientContext';
import { Participant } from './components/Participant/Participant';
import {
  Ellipsis,
  EmptyWrapper,
  StyledFlipper,
  WaittingUsers,
  Wrapper,
} from './Participants.styled';

export const Participants = observer(() => {
  const t = useTranslate();

  const [participantSize, setParticipantSize] = useState(Participant.Size.Medium);

  const ref = useResizeObserver<HTMLDivElement>(() => {
    if (!ref.current || participantSize === Participant.Size.Small) {
      return;
    }

    if (ref.current.scrollHeight > ref.current.clientHeight) {
      if (participantSize === Participant.Size.Large) {
        setTimeout(() => {
          setParticipantSize(Participant.Size.Medium);
        }, 200);
      } else {
        setParticipantSize(Participant.Size.Small);
      }
    }
  });

  const { openModal, closeModal } = useModal();

  const { client } = useHostClientContext();

  const { notificationManager } = useNotificationManager();

  const players = client.players.toReversed();

  const { isDownwards } = useBreakpoint();
  const isMobile = isDownwards(Breakpoint.xs);

  const kickPlayer = (player: PlayerData) => () => {
    client.kickPlayer(player.id);

    notificationManager.success(t('__new.play.expelPlayer.notification.success'));
  };

  const handleParticipantClick = (player: PlayerData) => () => {
    openModal(parentElement => (
      <KickPlayerConfirmModal
        parentElement={parentElement}
        closeModal={closeModal}
        playerUsername={player.username}
        handleKickPlayer={kickPlayer(player)}
      />
    ));
  };

  return (
    <Wrapper
      ref={ref}
      $centerAligned={
        players.length === 0 || (!isMobile && participantSize !== Participant.Size.Small)
      }
    >
      {players.length > 0 ? (
        <StyledFlipper
          flipKey={players.map(({ id }) => id).join('')}
          $size={participantSize}
        >
          {players.map(({ id, username }) => (
            <Flipped flipId={id} key={id}>
              <Participant
                name={username}
                size={participantSize}
                onClick={handleParticipantClick({ id, username })}
              />
            </Flipped>
          ))}
        </StyledFlipper>
      ) : (
        <EmptyWrapper>
          <WaittingUsers with={BiggerSubtitle} on={{ [Breakpoint.xs]: BigSubtitle }}>
            {t('__new.play.creatorLobby.join.text')}
          </WaittingUsers>
          <Ellipsis with={BiggerSubtitle} on={{ [Breakpoint.xs]: BigSubtitle }}>
            ...
          </Ellipsis>
        </EmptyWrapper>
      )}
    </Wrapper>
  );
});
