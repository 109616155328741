import { Fragment, useEffect, useState } from 'react';

import { BasicParagraph, BasicTitle, BigTitle } from '@genially/design-system';
import type { GeniallyData } from '@genially/document-model';
import { InteractiveQuestionType } from '@genially/document-model/build/module';

import { PlaySpinner } from '../../../../../../shared/application/components/PlaySpinner/PlaySpinner';
import { ViewApiService } from '../../../../../../shared/infrastructure/ViewApiService';
import { usePlayerClientContext } from '../PlayerClientProvider/hooks/usePlayerClientContext';
import { Image } from './interactive-questions/Image';
import { OpenAnswer } from './interactive-questions/OpenAnswer';
import { Quiz } from './interactive-questions/Quiz';
import { Sort } from './interactive-questions/Sort';
import { Survey } from './interactive-questions/Survey';
import { TrueFalse } from './interactive-questions/TrueFalse';
import { IQContainer, Wrapper } from './PlayerLoadTest.styled';

export const PlayerLoadTest = () => {
  const { client } = usePlayerClientContext();

  const [loadingGeniallyData, setLoadingGeniallyData] = useState(true);
  const [geniallyData, setGeniallyData] = useState<GeniallyData | undefined>(undefined);

  useEffect(() => {
    const fetchGeniallyData = async () => {
      const data = await ViewApiService.getViewData(client.geniallyId);
      setGeniallyData(data);
      setLoadingGeniallyData(false);
    };

    fetchGeniallyData();
  }, [client.geniallyId]);

  if (loadingGeniallyData || !geniallyData) {
    return (
      <Wrapper>
        <BigTitle data-cy="loader">Loading genially data</BigTitle>
        <PlaySpinner />
      </Wrapper>
    );
  }

  const { isFinished, currentSlide, isStarted } = client.game;

  const currentSlideInteractiveQuestions =
    geniallyData.Activities?.filter(iq => iq.IdSlide === currentSlide) ?? [];

  const buildInteractiveQuestionComponent = (iq: GeniallyData['Activities'][0]) => {
    switch (iq.Type) {
      case InteractiveQuestionType.OpenAnswer:
        return <OpenAnswer question={iq} />;
      case InteractiveQuestionType.Quiz:
        return <Quiz question={iq} />;
      case InteractiveQuestionType.TrueFalse:
        return <TrueFalse question={iq} />;
      case InteractiveQuestionType.Image:
        return <Image question={iq} />;
      case InteractiveQuestionType.Sort:
        return <Sort question={iq} />;
      case InteractiveQuestionType.Survey:
        return <Survey question={iq} />;
      default:
        console.warn(`Interactive question type ${iq.Type} is not supported`);
    }

    return null;
  };

  return (
    <Wrapper data-cy="load-test">
      <BigTitle>Load Test</BigTitle>
      <BasicParagraph data-cy="current-slide" data-slideId={currentSlide}>
        {currentSlide}
      </BasicParagraph>
      <BasicParagraph data-cy="game-started" data-isStarted={isStarted}>
        {isStarted ? 'Game is started' : 'Game is not started'}
      </BasicParagraph>
      <BasicParagraph data-cy="game-finished" data-isFinished={isFinished}>
        {isFinished ? 'Game is finished' : 'Game is not finished'}
      </BasicParagraph>

      {isStarted ? (
        <Fragment>
          <BasicParagraph>
            <span
              data-cy="amount-of-iq-in-current-slide"
              data-amount={currentSlideInteractiveQuestions.length}
            >
              {currentSlideInteractiveQuestions.length}
            </span>{' '}
            interactive questions on this slide
          </BasicParagraph>
          <IQContainer>
            <BasicTitle>Interactive Questions</BasicTitle>
            {currentSlideInteractiveQuestions.map(iq => (
              <div key={iq.Id}>{buildInteractiveQuestionComponent(iq)}</div>
            ))}
          </IQContainer>
        </Fragment>
      ) : undefined}
    </Wrapper>
  );
};
