import { Language } from '@genially/ui/build/utils/language';

export interface PluralizedValue {
  one?: string;
  other?: string;
  zero?: string;
}

export type Translations = Record<string, PluralizedValue | string>;

// Note: this function is written like this to give webpack hints on the languages
// Otherwise, it cannot know what files to extract into chunk
export const fetchLanguageFile = async (lang: Language): Promise<Translations> => {
  switch (lang) {
    case Language.Spanish:
      return import(
        /* webpackChunkName: "esKeys" */ '@genially/lang/dist/play/es.json'
      ).then(mod => mod.default);
    case Language.German:
      return import(
        /* webpackChunkName: "deKeys" */ '@genially/lang/dist/play/de.json'
      ).then(mod => mod.default);
    case Language.French:
      return import(
        /* webpackChunkName: "frKeys" */ '@genially/lang/dist/play/fr.json'
      ).then(mod => mod.default);
    case Language.Italian:
      return import(
        /* webpackChunkName: "itKeys" */ '@genially/lang/dist/play/it.json'
      ).then(mod => mod.default);
    case Language.Brazilian:
      return import(
        /* webpackChunkName: "ptbrKeys" */ '@genially/lang/dist/play/ptBr.json'
      ).then(mod => mod.default);
    default:
      return import(
        /* webpackChunkName: "enKeys" */ '@genially/lang/dist/play/en.json'
      ).then(mod => mod.default);
  }
};
