import { useState } from 'react';
import { observer } from 'mobx-react';

import { BasicParagraph, Button } from '@genially/design-system';
import type { AnyInteractiveQuestionItemData } from '@genially/document-model/build/module';

import { usePlayerClientContext } from '../../PlayerClientProvider/hooks/usePlayerClientContext';
import { Wrapper } from './IQ.styled';

export const IQ = observer(
  ({
    question,
    onSubmit,
  }: {
    question: AnyInteractiveQuestionItemData;
    onSubmit: () => void;
  }) => {
    const { client } = usePlayerClientContext();

    const sentConfirmed = client
      .getMyAnswers()
      .some(a => a.interactiveQuestionId === question.Id);

    const alreadyResolved = client.isResolved(question.Id);

    const [sent, setSent] = useState(sentConfirmed || alreadyResolved);

    const handleSubmit = () => {
      if (sent || alreadyResolved) return;

      onSubmit();
      setSent(true);
    };

    const sending = sent && !sentConfirmed;

    return (
      <Wrapper data-cy="interactive-question">
        <BasicParagraph data-cy="question-type" data-type={question.Type}>
          {question.Type}
        </BasicParagraph>
        <BasicParagraph data-cy="question-title" data-title={question.Question}>
          {question.Question.substring(0, 30)}...
        </BasicParagraph>
        <Button
          disabled={sentConfirmed || alreadyResolved}
          loading={sending}
          variant={Button.Variant.CTA}
          dataCy="submit"
          onClick={handleSubmit}
        >
          Send random answer
        </Button>
      </Wrapper>
    );
  },
);
