import BronzeMedal from './assets/bronze-medal.svg';
import GoldMedal from './assets/gold-medal.svg';
import SilverMedal from './assets/silver-medal.svg';

export enum Position {
  Gold,
  Silver,
  Bronze,
}

export enum PositionAnimationSequence {
  Enter,
  Flip,
  Translate,
  Exit,
}

export enum PositionAnimationSize {
  Medium,
  Large,
}

export enum PositionAnimationEnterAnimation {
  FadeIn,
  SlideFromBack,
}

export const ExitAnimationTime = {
  [Position.Gold]: 1500,
  [Position.Silver]: 500,
  [Position.Bronze]: 500,
};

export const ConfettiParticleCount = {
  [Position.Gold]: 90,
  [Position.Silver]: 60,
  [Position.Bronze]: 30,
};

export const ConfettiGravitiy = {
  [Position.Gold]: 2.75,
  [Position.Silver]: 3.75,
  [Position.Bronze]: 3.75,
};

export const MedalSource = {
  [Position.Gold]: GoldMedal,
  [Position.Silver]: SilverMedal,
  [Position.Bronze]: BronzeMedal,
};
