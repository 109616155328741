import type { PostOpenInteractiveQuestionAnswerRequestBody } from '@genially/contracts';
import { TrackingInteractiveQuestionType } from '@genially/contracts';
import type {
  AnyInteractiveQuestionItemData,
  OpenAnswerInteractiveQuestionItemData,
} from '@genially/document-model';

import { usePlayerClientContext } from '../../PlayerClientProvider/hooks/usePlayerClientContext';
import { IQ } from './IQ';

const getRandomString = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  return Array.from({ length: 240 }, () =>
    characters.charAt(Math.floor(Math.random() * characters.length)),
  ).join('');
};

export const OpenAnswer = ({
  question,
}: {
  question: AnyInteractiveQuestionItemData<OpenAnswerInteractiveQuestionItemData>;
}) => {
  const { client } = usePlayerClientContext();

  const handleSubmit = () => {
    const payload: PostOpenInteractiveQuestionAnswerRequestBody = {
      interactiveQuestionId: question.Id,
      timestamp: Date.now(),
      version: 1,
      type: TrackingInteractiveQuestionType.OpenAnswer,
      answerText: getRandomString(),
    };

    client.sendInteractiveQuestionAnswer(payload);
  };

  return <IQ question={question} onSubmit={handleSubmit} />;
};
