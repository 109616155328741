import styled from 'styled-components';

import { style } from '@genially/design-system';

export const StyledIframe = styled.iframe<{ $isLoaded: boolean }>(
  ({ theme: { size }, $isLoaded }) => ({
    width: '100%',
    height: size.units(65),
    display: $isLoaded ? 'block' : 'none',

    ...style.mixins.responsive.md.downward({
      height: size.units(40),
    }),

    ...style.mixins.responsive.sm.downward({
      height: size.units(20),
    }),
  }),
);

export const Wrapper = styled.div(() => ({
  width: '100%',
  height: '100%',
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  ...style.mixins.responsive.md.downward({
    alignItems: 'flex-start',
  }),
}));
