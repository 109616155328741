import { useEffect } from 'react';
import { observer } from 'mobx-react';
import screenfull from 'screenfull';

import type {
  HostPlayPostMessageConnectionPlayApi,
  HostPlayPostMessageConnectionViewApi,
} from '@genially/contracts';
import { HostPlayP2pClientEventType } from '@genially/p2p-client';
import { PlayUserType } from '@genially/p2p-lib';
import type { PenpalAsyncMethodReturns } from '@genially/ui';

import { GeniallyView } from '../../../../../../../../shared/application/components/GeniallyView/GeniallyView';
import { useFullScreen } from '../../../../../../../../shared/application/hooks/useFullScreen';
import { useHostClientContext } from '../../../HostClientProvider/hooks/useHostClientContext';
import { useHostGameContext } from '../../HostGame.context';
import { getInteractiveQuestionsDataFromClient } from './utils/getInteractiveQuestionsDataFromClient';

interface HostGeniallyViewProps {
  showGeniallyAsFullHeight?: boolean;
}

export const HostGeniallyView = observer(
  ({ showGeniallyAsFullHeight }: HostGeniallyViewProps) => {
    const { client } = useHostClientContext();

    const { viewConnection, sidebarSummary, interactiveQuestions } = useHostGameContext();

    const { toggleFullScreen, isAvailable } = useFullScreen({
      isConnected: viewConnection.isConnected,
      setIsFullScreen: viewConnection.connection?.setIsFullScreen,
    });

    const { geniallyId } = client;

    const resolveInteractiveQuestion = (id: string, isLastScored?: boolean) => {
      client.resolveInteractiveQuestion(id, isLastScored);
    };

    const handleSlideChange = (slideId: string) => {
      client.sendChangeSlide(slideId);
      sidebarSummary.close();
    };

    const handleToggleSidebarSummary = (
      selectedInteractiveQuestionId: string,
      open?: boolean,
    ) => {
      const selectedInteractiveQuestion = interactiveQuestions.find(
        i => i.Id === selectedInteractiveQuestionId,
      );

      if (!selectedInteractiveQuestion) {
        return;
      }

      const willBeOpened = open ?? !sidebarSummary.isOpen;

      if (willBeOpened) {
        sidebarSummary.open();
      } else {
        sidebarSummary.close();
      }

      if (willBeOpened) {
        sidebarSummary.setSelectedQuestion({
          interactiveQuestion: selectedInteractiveQuestion,
          isLastScored: false,
        });
      }
    };

    const methods: HostPlayPostMessageConnectionPlayApi = {
      onChangeSlide: handleSlideChange,
      resolveInteractiveQuestion,
      toggleSidebarSummary: handleToggleSidebarSummary,
      getInitialData: () => {
        return {
          isFullScreenAvailable: isAvailable,
          currentSlideId: client.game.currentSlide,
          answersPerInteractiveQuestionId: getInteractiveQuestionsDataFromClient(client),
        };
      },
      toggleFullScreen,
    };

    const handleConnectionChange = (
      conn?: PenpalAsyncMethodReturns<HostPlayPostMessageConnectionViewApi>,
    ) => {
      if (conn) {
        viewConnection.setConnection(conn);
      } else {
        viewConnection.setConnection(undefined);
      }
    };

    useEffect(() => {
      const handleInteractiveQuestionAnswerReceivedEvent = () => {
        if (viewConnection.isConnected) {
          viewConnection.connection.setAnswersPerInteractiveQuestionId(
            getInteractiveQuestionsDataFromClient(client),
          );
        }
      };

      client.on(
        HostPlayP2pClientEventType.InteractiveQuestionAnswerReceived,
        handleInteractiveQuestionAnswerReceivedEvent,
      );

      client.on(
        HostPlayP2pClientEventType.InteractiveQuestionResolved,
        handleInteractiveQuestionAnswerReceivedEvent,
      );

      return () => {
        client.off(
          HostPlayP2pClientEventType.InteractiveQuestionAnswerReceived,
          handleInteractiveQuestionAnswerReceivedEvent,
        );

        client.off(
          HostPlayP2pClientEventType.InteractiveQuestionResolved,
          handleInteractiveQuestionAnswerReceivedEvent,
        );
      };
    }, [client, viewConnection.connection, viewConnection.isConnected]);

    return (
      <GeniallyView<
        HostPlayPostMessageConnectionPlayApi,
        HostPlayPostMessageConnectionViewApi
      >
        type={PlayUserType.Host}
        geniallyId={geniallyId}
        methods={methods}
        showGeniallyAsFullHeight={showGeniallyAsFullHeight}
        onConnectionCreated={handleConnectionChange}
        onConnectionDestroyed={handleConnectionChange}
      />
    );
  },
);
