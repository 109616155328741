import { useEffect, useState } from 'react';

import type { AsyncHookResult } from '@genially/ui';

import { ViewApiService } from '../../infrastructure/ViewApiService';

export const useFetchInitialSlide = (geniallyId: string): AsyncHookResult<string> => {
  const [initialSlide, setInitialSlide] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!geniallyId) return;

    ViewApiService.getInitialSlideId(geniallyId).then(setInitialSlide);
  }, [geniallyId]);

  if (initialSlide === undefined) {
    return {
      loading: true,
      data: undefined,
      error: undefined,
    };
  }

  return {
    loading: false,
    data: initialSlide,
    error: undefined,
  };
};
