import { useEffect, useMemo, useState } from 'react';
import type { CallSender } from 'penpal';
import { useTheme } from 'styled-components';

import type { PlayUserType } from '@genially/p2p-lib';
import { type PenalMethods, type PenpalAsyncMethodReturns } from '@genially/ui';
import { hasOnetrustPermissionToLoadLogRocket } from '@genially/ui/build/business/support/integrations/LogRocket/loadLogRocketIntegration';
import { Penpal } from '@genially/ui/build/components/Penpal/Penpal';
import { calculate } from '@genially/utils';

import { config } from '../../../../bootstrap/application/config/config';
import { PlaySpinner } from '../PlaySpinner/PlaySpinner';
import { IframeWrapper, SpinnerWrapper, Wrapper } from './GeniallyView.styled';

enum GeniallyViewSize {
  Default = 'default',
  Small = 'small',
}

interface GeniallyViewProps<
  PlayPostMessageApi extends PenalMethods,
  ViewPostMessageApi extends CallSender,
> {
  geniallyId: string;
  methods?: PlayPostMessageApi;
  type: PlayUserType;

  showGeniallyAsFullHeight?: boolean;
  size?: GeniallyViewSize;

  onConnectionCreated: (connection: PenpalAsyncMethodReturns<ViewPostMessageApi>) => void;
  onConnectionDestroyed: () => void;
}

export const GeniallyView = <
  PlayPostMessageApi extends PenalMethods = PenalMethods,
  ViewPostMessageApi extends CallSender = CallSender,
>({
  geniallyId,
  methods,
  type,

  showGeniallyAsFullHeight,
  size = GeniallyViewSize.Default,

  onConnectionCreated,
  onConnectionDestroyed,
}: GeniallyViewProps<PlayPostMessageApi, ViewPostMessageApi>) => {
  const theme = useTheme();

  const IframeSizes = useMemo(() => {
    if (size === GeniallyViewSize.Small) {
      return {
        width: theme.size.units(41),
        height: theme.size.units(18.5),
      };
    }

    return {
      width: '100%',
      height: '100%',
    };
  }, [size, theme.size]);

  const [connection, setConnection] = useState<
    undefined | PenpalAsyncMethodReturns<ViewPostMessageApi>
  >(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(!connection);
  }, [connection]);

  useEffect(() => {
    if (connection) {
      onConnectionCreated(connection);
    } else {
      onConnectionDestroyed();
    }
  }, [connection, onConnectionCreated, onConnectionDestroyed]);

  const iframeUrl = calculate(() => {
    const url = `${config.urls.view}/${geniallyId}?fromApp=play&disableShareButton=true&type=${type}`;

    if (hasOnetrustPermissionToLoadLogRocket()) {
      return `${url}&lrAllowed=true`;
    }

    return url;
  });

  return (
    <Wrapper $fullHeight={showGeniallyAsFullHeight}>
      {isLoading ? (
        <SpinnerWrapper $height={IframeSizes.height}>
          <PlaySpinner height="auto" />
        </SpinnerWrapper>
      ) : undefined}
      <IframeWrapper
        $hidden={isLoading}
        style={{
          width: IframeSizes.width,
          height: IframeSizes.height,
        }}
      >
        <Penpal
          hidden={isLoading}
          frameBorder="0"
          src={iframeUrl}
          allowFullScreen
          scrolling="yes"
          sandbox="allow-scripts allow-same-origin allow-popups allow-modals"
          methods={methods}
          setChild={setConnection}
          childOrigin="*"
        />
      </IframeWrapper>
    </Wrapper>
  );
};

GeniallyView.Size = GeniallyViewSize;
