import styled from 'styled-components';

export const Wrapper = styled.div(({ theme: { color, size } }) => ({
  '*': {
    color: color.content.reversed.default(),
  },

  display: 'flex',
  flexDirection: 'column',
  gap: size.units(1),

  padding: size.units(3),
}));

export const IQContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: size.units(3),

  width: '100%',

  borderTop: '1px solid white',

  marginTop: size.units(3),
  paddingTop: size.units(3),
}));
