export class GeniallyUser {
  public readonly id: string;

  public readonly username: string;

  public readonly nickname: string;

  public readonly photo: string;

  public readonly registeredDate: string;

  public readonly role: number;

  public readonly sector: number;

  constructor({
    id,
    username,
    nickname,
    photo,
    registeredDate,
    role,
    sector,
  }: {
    id: string;
    username: string;
    nickname: string;
    photo: string;
    registeredDate: string;
    role: number;
    sector: number;
  }) {
    this.sector = sector;
    this.role = role;
    this.registeredDate = registeredDate;
    this.photo = photo;
    this.nickname = nickname;
    this.username = username;
    this.id = id;
  }

  static fromPrimitives(data: {
    DateRegister: string;
    Id: string;
    NewRole: number;
    NewSector: number;
    NickName: string;
    Photo: string;
    UserName: string;
  }): GeniallyUser {
    return new GeniallyUser({
      id: data.Id,
      username: data.UserName,
      nickname: data.NickName,
      photo: data.Photo,
      registeredDate: data.DateRegister,
      role: data.NewRole,
      sector: data.NewSector,
    });
  }
}
