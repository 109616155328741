import { Fragment, useState } from 'react';
import { useTranslate } from 'react-polyglot';

import {
  BasicTitle,
  BiggerSubtitle,
  Breakpoint,
  Button,
  Input,
  useBreakpoint,
} from '@genially/design-system';
import { PlayP2pClient, PlayP2pRestClient } from '@genially/p2p-client';
import { UserAliasAvatar } from '@genially/ui/build/components/UserAliasAvatar/UserAliasAvatar';
import { calculate } from '@genially/utils/build/module';

import { SessionBlockedError } from './components/SessionBlockedError/SessionBlockedError';
import { SessionBlockedNotification } from './components/SessionBlockedNotification/SessionBlockedNotification';
import { UsernameIsTakenError } from './components/UsernameIsTakenError/UsernameIsTakenError';
import {
  ContentWrapper,
  ErrorWrapper,
  StyledArrowEndPointIcon,
  StyledResponsiveText,
  UsernameSelectionWrapper,
  Wrapper,
} from './PlayerUsernameSelection.styled';

interface PlayerUsernameSelectionProps {
  geniallyId: string;
  sessionId: string;
  username?: string;
  isUsernameTaken?: boolean;
  isSessionBlocked?: boolean;
  onUsernameSelected: (username: string) => void;
}

const UserAliasMaxLength = 40;

export const PlayerUsernameSelection = ({
  geniallyId,
  sessionId,
  username: usernameFromParams,
  isUsernameTaken = false,
  isSessionBlocked: isSessionBlockedFromParams = false,
  onUsernameSelected,
}: PlayerUsernameSelectionProps) => {
  const t = useTranslate();

  const [username, setUsername] = useState(usernameFromParams ?? '');
  const trimmedUsername = username.trim();

  const [checking, setChecking] = useState(false);

  const [isSessionBlocked, setIsSessionBlocked] = useState(isSessionBlockedFromParams);
  const [isUsernameInUse, setIsUsernameInUse] = useState(isUsernameTaken);

  const isResponsive = useBreakpoint().isDownwards(Breakpoint.xs);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue === '') {
      setUsername('');
      return;
    }

    setIsSessionBlocked(false);
    setIsUsernameInUse(false);

    const newUsername = inputValue.replace(/-/g, '');
    setUsername(newUsername);
  };

  const handleClick = async () => {
    if (!PlayP2pClient.isValidUsername(trimmedUsername)) {
      return;
    }

    setChecking(true);

    const inUse = await PlayP2pRestClient.withGeniallyId({ geniallyId }).isUsernameInUse({
      sessionId,
      username: trimmedUsername,
    });

    if (inUse) {
      setIsUsernameInUse(true);

      setChecking(false);

      return;
    }

    onUsernameSelected(trimmedUsername);
  };

  const handleEnter = () => {
    if (!checking) {
      handleClick();
    }
  };

  const ErrorComponent = calculate(() => {
    if (isUsernameInUse) {
      return <UsernameIsTakenError />;
    }

    if (isSessionBlocked) {
      return <SessionBlockedError />;
    }

    return undefined;
  });

  return (
    <Fragment>
      <SessionBlockedNotification
        show={ErrorComponent !== undefined && isSessionBlocked}
      />
      <Wrapper>
        <StyledResponsiveText with={BasicTitle} on={{ xs: BiggerSubtitle }}>
          {t('__new.play.playerAccess.username.title')}
        </StyledResponsiveText>

        <ContentWrapper>
          <UsernameSelectionWrapper>
            <UserAliasAvatar name={username} size={UserAliasAvatar.Size.Big} />
            <Input
              autofocus
              blurOnEnter={false}
              size={Input.Size.BIG}
              placeholder="Ej: Javi Potter"
              value={username}
              maxLength={UserAliasMaxLength}
              onChange={handleUsernameChange}
              onEnter={handleEnter}
              status={ErrorComponent !== undefined ? Input.Status.ERROR : undefined}
            />

            {isResponsive && ErrorComponent !== undefined ? ErrorComponent : undefined}

            <Button
              variant={Button.Variant.CTA}
              size={Button.Size.BIG}
              width={isResponsive ? '100%' : undefined}
              disabled={checking || username.length === 0}
              onClick={handleClick}
              loading={checking}
            >
              <StyledArrowEndPointIcon />
            </Button>
          </UsernameSelectionWrapper>

          {!isResponsive && ErrorComponent !== undefined ? (
            <ErrorWrapper>{ErrorComponent}</ErrorWrapper>
          ) : undefined}
        </ContentWrapper>
      </Wrapper>
    </Fragment>
  );
};
