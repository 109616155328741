/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { useTranslate } from 'react-polyglot';

import { Tooltip } from '@genially/design-system';
import { PlayP2pRestClient, SessionIdLength } from '@genially/p2p-client';
import { useIsMounted } from '@genially/ui/build/hooks/useIsMounted';

import { RouteNames } from '../../../../../../bootstrap/domain/RouteNames';
import { ResponsiveText } from '../../../../../../shared/application/components/ResponsiveText/ResponsiveText';
import { useNavigation } from '../../../../../../shared/application/hooks/useNavigation';
import { SessionInput } from './components/SessionInput/SessionInput';
import {
  ErrorInfoWrapper,
  StyledBasicParagraph,
  StyledBasicTitle,
  StyledBiggerSubtitle,
  StyledBigParagraph,
  StyledInfoFilledIcon,
  StyledWarningBasicIcon,
  TextWrapper,
  Wrapper,
} from './PlayerSessionSelection.styled';

const isValidSessionId = (value: string) => {
  return new RegExp(`^\\d{${SessionIdLength}}$`).test(value);
};

const sessionIdFromQueryParam =
  new URLSearchParams(window.location.search).get('sessionId') ?? undefined;

export const PlayerSessionSelection = () => {
  const t = useTranslate();

  const { navigate } = useNavigation();

  const isMounted = useIsMounted();

  const [checkingSessionId, setCheckingSessionId] = useState(false);
  const [isSessionIdValid, setIsSessionIdValid] = useState<boolean | undefined>(
    undefined,
  );

  const handleJoinSession = (geniallyId: string, sessionId: string) => {
    navigate({
      to: RouteNames.PlayerUsernameSelection,
      params: { sessionId, geniallyId },
    });
  };

  const handleComplete = (sessionId: string) => {
    if (sessionId.length !== SessionIdLength) return;

    if (!isValidSessionId(sessionId)) {
      setIsSessionIdValid(undefined);

      return;
    }

    setCheckingSessionId(true);

    PlayP2pRestClient.discoverGeniallyId({ sessionId })
      .then(({ geniallyId }) => {
        if (!geniallyId) {
          setIsSessionIdValid(false);
        } else {
          setIsSessionIdValid(true);

          handleJoinSession(geniallyId, sessionId);
        }
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => {
        if (!isMounted()) return;

        setCheckingSessionId(false);
      });
  };

  const handleEmptyCode = () => {
    setIsSessionIdValid(undefined);
  };

  return (
    <Wrapper>
      <TextWrapper>
        <ResponsiveText with={StyledBasicTitle} on={{ xs: StyledBiggerSubtitle }}>
          {t('__new.play.playerAccess.code.title')}
        </ResponsiveText>

        <Tooltip
          text={t('__new.play.playerAccess.code.tooltip')}
          placement={Tooltip.Position.TOP}
          variant={Tooltip.Variant.LIGHT}
        >
          <StyledInfoFilledIcon />
        </Tooltip>
      </TextWrapper>

      <SessionInput
        initialValue={sessionIdFromQueryParam}
        length={SessionIdLength}
        checking={checkingSessionId}
        hasErrors={isSessionIdValid === false}
        onComplete={handleComplete}
        onEmptyCode={handleEmptyCode}
      />

      {isSessionIdValid === false && (
        <ErrorInfoWrapper>
          <StyledWarningBasicIcon />

          <ResponsiveText with={StyledBigParagraph} on={{ xs: StyledBasicParagraph }}>
            {t('__new.play.playerAccess.code.error')}
          </ResponsiveText>
        </ErrorInfoWrapper>
      )}
    </Wrapper>
  );
};
