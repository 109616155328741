import styled from 'styled-components';

import { style } from '@genially/design-system';

export const SpinnerWrapper = styled.div<{ $height: string }>(({ $height }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: $height,
}));

export const IframeWrapper = styled.div<{ $hidden?: boolean }>(
  ({ $hidden, theme: { borderRadius } }) => ({
    width: '100%',
    height: '100%',

    '> iframe': {
      width: '100%',
      height: '100%',

      borderRadius: borderRadius.medium,
    },

    display: $hidden ? 'none' : undefined,
  }),
);

export const Wrapper = styled.div<{ $fullHeight?: boolean }>(({ $fullHeight }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  flexGrow: 1,

  ...style.mixins.responsive.sm.upward({
    height: $fullHeight ? '100%' : undefined,
    maxHeight: '99%',
  }),
}));
