import styled from 'styled-components';

export const Wrapper = styled.div<{ $show: boolean }>(
  ({ theme: { transition }, $show }) => ({
    pointerEvents: $show ? undefined : 'none',
    userSelect: $show ? undefined : 'none',

    position: 'absolute',
    top: 0,
    left: 0,

    zIndex: 2,

    width: '100%',
    height: '100%',

    transition: transition.standard(),

    opacity: $show ? 1 : 0,
  }),
);
