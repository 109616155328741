import type { PostImageInteractiveQuestionRequestBody } from '@genially/contracts';
import { TrackingInteractiveQuestionType } from '@genially/contracts';
import type {
  AnyInteractiveQuestionItemData,
  ImageInteractiveQuestionItemData,
} from '@genially/document-model';

import { usePlayerClientContext } from '../../PlayerClientProvider/hooks/usePlayerClientContext';
import { IQ } from './IQ';

export const Image = ({
  question,
}: {
  question: AnyInteractiveQuestionItemData<ImageInteractiveQuestionItemData>;
}) => {
  const { client } = usePlayerClientContext();

  const handleSubmit = () => {
    const randomAnswer = Math.floor(Math.random() * question.Answers.length);

    const payload: PostImageInteractiveQuestionRequestBody = {
      interactiveQuestionId: question.Id,
      timestamp: Date.now(),
      version: 1,
      type: TrackingInteractiveQuestionType.Image,
      answerIds: [question.Answers[randomAnswer].Id],
      timeTakenToAnswer: 0,
    };

    client.sendInteractiveQuestionAnswer(payload);
  };

  return <IQ question={question} onSubmit={handleSubmit} />;
};
