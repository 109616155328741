import type { PostQuizInteractiveQuestionAnswerRequestBody } from '@genially/contracts';
import { TrackingInteractiveQuestionType } from '@genially/contracts';
import type {
  AnyInteractiveQuestionItemData,
  QuizInteractiveQuestionItemData,
} from '@genially/document-model';

import { usePlayerClientContext } from '../../PlayerClientProvider/hooks/usePlayerClientContext';
import { IQ } from './IQ';

export const Quiz = ({
  question,
}: {
  question: AnyInteractiveQuestionItemData<QuizInteractiveQuestionItemData>;
}) => {
  const { client } = usePlayerClientContext();

  const handleSubmit = () => {
    const randomAnswer = Math.floor(Math.random() * question.Answers.length);

    const payload: PostQuizInteractiveQuestionAnswerRequestBody = {
      interactiveQuestionId: question.Id,
      timestamp: Date.now(),
      version: 1,
      type: TrackingInteractiveQuestionType.Quiz,
      answerIds: [question.Answers[randomAnswer].Id],
      timeTakenToAnswer: 0,
    };

    client.sendInteractiveQuestionAnswer(payload);
  };

  return <IQ question={question} onSubmit={handleSubmit} />;
};
