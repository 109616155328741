import { Fragment, useState } from 'react';
import { useTranslate } from 'react-polyglot';

import { List, Spinner, useBreakpoint, useOutsideClick } from '@genially/design-system';
import {
  ArrowRightToBracketIcon,
  DisplayDownIcon,
  DisplayUpIcon,
  LockIcon,
  PodiumIcon,
  QrIcon,
  UnlockIcon,
} from '@genially/icons';
import { calculate } from '@genially/utils';

import { useModal } from '../../../../../../../../../../../../bootstrap/application/components/app/components/ModalProvider/ModalProvider.context';
import { RouteNames } from '../../../../../../../../../../../../bootstrap/domain/RouteNames';
import { QrCodeModal } from '../../../../../../../../../../../../shared/application/components/QrCodeModal/QrCodeModal';
import { useNavigation } from '../../../../../../../../../../../../shared/application/hooks/useNavigation';
import { useNotificationManager } from '../../../../../../../../../../../../shared/application/services/useNotificationManager';
import { geniallyHasSelfCorrectingQuestions } from '../../../../../../../../../../../../shared/application/utils/geniallyHasSelfCorrectingQuestions';
import { splitSessionCodeByDash } from '../../../../../../../../../../../../shared/application/utils/splitSessionCodeByDash';
import { useHostClientContext } from '../../../../../../../HostClientProvider/hooks/useHostClientContext';
import { HostGameBreakdownSteps } from '../../../../../../HostGame.constants';
import { useHostGameContext } from '../../../../../../HostGame.context';
import {
  ButtonText,
  Divider,
  LeftSide,
  RightSide,
  StyledOtherVertIcon,
  Wrapper,
} from './CollapsableGameControlButtons.styled';

export enum CollapsableGameControlButtonsOption {
  ShowRanking = 'ShowRanking',
  ShowQrCode = 'ShowQrCode',
  ToggleLockSession = 'ToggleLockSession',
  FinishGame = 'FinishGame',
}

export type CollapsableGameControlButtonsOptions = {
  text: string;
  value: CollapsableGameControlButtonsOption;
  Icon: React.ReactElement;
};

const DEFAULT_TOAST_TIMEOUT = 3000;

export const CollapsableGameControlButtons = ({
  onShowRanking,
  onFinishGame,
  isFinishingGame,
}: {
  onShowRanking: () => void;
  onFinishGame: () => void;
  isFinishingGame: boolean;
}) => {
  const t = useTranslate();

  const { client } = useHostClientContext();
  const { interactiveQuestions } = useHostGameContext();

  const { geniallyId, sessionId } = client;

  const { getFullUrl } = useNavigation();

  const { isDownwards } = useBreakpoint();

  const { openModal, closeModal } = useModal();

  const { notificationManager } = useNotificationManager();

  const [isOpen, setIsOpen] = useState(false);

  const isMobile = isDownwards(HostGameBreakdownSteps.forth);

  const sessionIdSplitByDash = splitSessionCodeByDash(sessionId);

  const { isSessionBlocked } = client.game;

  const hasSelfCorrectingQuestions =
    geniallyHasSelfCorrectingQuestions(interactiveQuestions);

  const handleToggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const showQrCodeModal = () => {
    openModal(parentElement => (
      <QrCodeModal
        url={getFullUrl({
          to: RouteNames.PlayerUsernameSelection,
          params: {
            geniallyId,
            sessionId,
          },
        })}
        sessionId={sessionIdSplitByDash}
        geniallyId={geniallyId}
        parentElement={parentElement}
        closeModal={closeModal}
      />
    ));

    setIsOpen(false);
  };

  const handleFinishGame = () => {
    onFinishGame();
  };

  const toggleIsSessionBlocked = () => {
    client.setIsSessionBlocked(!isSessionBlocked);

    notificationManager.success(
      isSessionBlocked
        ? t('__new.play.creatorLobby.join.QRinstructions.toast.sessionUnblock')
        : t('__new.play.creatorLobby.join.QRinstructions.toast.sessionBlock'),
      DEFAULT_TOAST_TIMEOUT,
    );

    setIsOpen(false);
  };

  const onOptionClick = (option: CollapsableGameControlButtonsOption) => () => {
    switch (option) {
      case CollapsableGameControlButtonsOption.ShowRanking:
        onShowRanking();
        break;
      case CollapsableGameControlButtonsOption.ShowQrCode:
        showQrCodeModal();
        break;
      case CollapsableGameControlButtonsOption.ToggleLockSession:
        toggleIsSessionBlocked();
        break;
      case CollapsableGameControlButtonsOption.FinishGame:
        handleFinishGame();
        break;
      default:
        break;
    }
  };

  const options = calculate<CollapsableGameControlButtonsOptions[]>(() => {
    const defaultOptions: CollapsableGameControlButtonsOptions[] = [
      {
        text: '__new.play.creatorLobby.join.QRinstructions.menu.accessCode',
        value: CollapsableGameControlButtonsOption.ShowQrCode,
        Icon: <QrIcon />,
      },
      {
        text: isSessionBlocked
          ? '__new.play.creatorLobby.join.QRinstructions.menu.sessionUnblock'
          : '__new.play.creatorLobby.join.QRinstructions.menu.sessionBlock',
        value: CollapsableGameControlButtonsOption.ToggleLockSession,
        Icon: isSessionBlocked ? <LockIcon /> : <UnlockIcon />,
      },
      {
        text: '__new.play.closeSession.modal.title',
        value: CollapsableGameControlButtonsOption.FinishGame,
        Icon: isFinishingGame ? (
          <Spinner color="rgba(82, 95, 255, 1)" />
        ) : (
          <ArrowRightToBracketIcon />
        ),
      },
    ];

    if (hasSelfCorrectingQuestions) {
      return [
        {
          text: '__new.play.navbarController.button.viewRanking',
          value: CollapsableGameControlButtonsOption.ShowRanking,
          Icon: <PodiumIcon />,
        },
        ...defaultOptions,
      ];
    }

    return defaultOptions;
  });

  const dropdownRef = useOutsideClick(e => {
    e.stopImmediatePropagation();
    setIsOpen(false);
  });

  const { Icon: DefaultIcon, value: defaultAction, text: defaultText } = options[0];

  return (
    <Wrapper ref={dropdownRef} $isMobile={isMobile}>
      {isMobile ? (
        <StyledOtherVertIcon onClick={handleToggleIsOpen} />
      ) : (
        <Fragment>
          <LeftSide>
            {DefaultIcon}
            <ButtonText onClick={onOptionClick(defaultAction)}>
              {t(defaultText)}
            </ButtonText>
          </LeftSide>
          <Divider />
          <RightSide onClick={handleToggleIsOpen}>
            {isOpen ? <DisplayUpIcon /> : <DisplayDownIcon />}
          </RightSide>
        </Fragment>
      )}
      <List
        referenceElement={dropdownRef}
        width="auto"
        show={isOpen}
        placement={List.Placement.BOTTOM_START}
        scrollListener={false}
      >
        {options.map(option => {
          const handleItemAction = onOptionClick(option.value);

          return (
            <List.Item
              key={option.value}
              onItemAction={handleItemAction}
              text={t(option.text)}
              LeftComponent={option.Icon}
            />
          );
        })}
      </List>
    </Wrapper>
  );
};
