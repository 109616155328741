import React, { Fragment, useEffect } from 'react';
import { useTranslate } from 'react-polyglot';

import { Button } from '@genially/design-system';
import { GeniallyIconIcon } from '@genially/icons';

import { useAuthenticatedUser } from '../../../../../../bootstrap/application/components/app/components/AuthenticatedUserProvider/AuthenticatedUserProvider.context';
import { config } from '../../../../../../bootstrap/application/config/config';
import { RouteNames } from '../../../../../../bootstrap/domain/RouteNames';
import { FullPageContent } from '../../../../../../shared/application/components/FullPageContent/FullPageContent';
import { useNavigation } from '../../../../../../shared/application/hooks/useNavigation';
import { usePlayerClientContext } from '../PlayerClientProvider/hooks/usePlayerClientContext';
import image from './assets/image.png';
import { GeniallyGalleryIframe } from './components/GeniallyGalleryIframe/GeniallyGalleryIframe';

export const PlayerGameFinished = () => {
  const t = useTranslate();

  const { client } = usePlayerClientContext();

  const { navigate } = useNavigation();

  const { authenticatedUser } = useAuthenticatedUser();

  const handleGoToDashboard = () => {
    window.location.href = config.urls.dashboard;
  };

  const handleGoToRegister = () => {
    window.location.href = `${config.urls.auth}/signup?from=play`;
  };

  useEffect(() => {
    if (!client.game.isFinished) {
      navigate({
        to: RouteNames.Player,
        params: {
          sessionId: client.sessionId,
          geniallyId: client.geniallyId,
          username: client.username,
        },
      });
    }
  }, [
    client.game.isFinished,
    client.geniallyId,
    client.sessionId,
    client.username,
    navigate,
  ]);

  if (!client.game.isFinished) {
    // To avoid the blinking of the page when the user is redirected to the Player page
    return null;
  }

  const description = authenticatedUser
    ? '__new.play.finalScreen.autenticatedUser.description'
    : '__new.play.finalScreen.noAutenticatedUser.description';

  const buttonTextKey = authenticatedUser
    ? '__new.play.finalScreen.autenticatedUser.button'
    : '__new.play.finalScreen.noAutenticatedUser.button';

  const handleButtonClick = authenticatedUser ? handleGoToDashboard : handleGoToRegister;

  const handleSecondaryButtonClick = () => {
    window.location.href = `${config.urls.web}?from=watermark-play`;
  };

  return (
    <Fragment>
      <FullPageContent
        title={t('__new.play.finalScreen.tittle.v2')}
        content={t(description)}
        RightComponent={<GeniallyGalleryIframe />}
        showLogo
        button={
          <Button variant={Button.Variant.CTA} onClick={handleButtonClick}>
            {t(buttonTextKey)}
          </Button>
        }
        secondButton={
          authenticatedUser ? undefined : (
            <Button variant={Button.Variant.ACTION} onClick={handleSecondaryButtonClick}>
              {t('__new.play.finalScreen.noAutenticatedUser.button2')}
            </Button>
          )
        }
      />
    </Fragment>
  );
};
