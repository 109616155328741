import { Fragment } from 'react';
import { observer } from 'mobx-react';

import { Tooltip } from '@genially/design-system';
import { StatisticsIcon } from '@genially/icons';
import { PlayUserType } from '@genially/p2p-lib';
import { FEATURE_FLAGS } from '@genially/ui/build/constants/featureFlags';
import { calculate } from '@genially/utils/build/module';

import { useModal } from '../../../../bootstrap/application/components/app/components/ModalProvider/ModalProvider.context';
import { CheckIsGeniallyCompatibleGuard } from '../../../../bootstrap/application/components/app/Routes/guards/CheckIsGeniallyCompatibleGuard';
import { Header } from '../../../../bootstrap/application/components/app/Routes/layouts/CommonLayout/components/Header/Header';
import { useShowDebugModal } from '../../../../shared/application/components/DebugModal/hooks/useShowDebugModal';
import { LottieCountdown } from '../../../../shared/application/components/LottieCountdown/LottieCountdown';
import { Ping } from '../../../../shared/application/components/PlayAppVersion/Ping';
import { useScreenWakeLockWhilePlaying } from '../../../../shared/application/hooks/useScreenWakeLockWhilePlaying';
import { BenchmarkButton } from './components/BenchmarkButton/BenchmarkButton';
import { CheatModal } from './components/CheatModal/CheatModal';
import { useHostClientContext } from './components/HostClientProvider/hooks/useHostClientContext';
import { HostGame } from './components/HostGame/HostGame';
import { HostGameFinished } from './components/HostGameFinished/HostGameFinished';
import { HostLobby } from './components/HostLobby/HostLobby';
import { FloatingButton, FloatingButtons } from './HostGamePage.styled';

export const HostGamePage = observer(() => {
  const { client } = useHostClientContext();
  const { openModal, closeModal } = useModal();

  const canShowDebugButtons = client.hasFeatureFlag(
    FEATURE_FLAGS.FEAT_LT4_PLAY_BENCHMARK,
  );
  const { showDebugModal } = useShowDebugModal(client);
  Ping.isVisible = canShowDebugButtons;

  const openBenchmarkModal = () => {
    openModal(parentElement => (
      <CheatModal client={client} parentElement={parentElement} closeModal={closeModal} />
    ));
  };

  const BenchmarkFloatingButton = calculate(() => {
    if (!canShowDebugButtons) {
      return null;
    }

    return (
      <FloatingButton onClick={openBenchmarkModal}>
        <Tooltip text="Benchmark">
          <BenchmarkButton />
        </Tooltip>
      </FloatingButton>
    );
  });

  const DebugFloatingButton = calculate(() => {
    if (!canShowDebugButtons) {
      return null;
    }

    return (
      <FloatingButton onClick={showDebugModal}>
        <Tooltip text="Debug">
          <StatisticsIcon />
        </Tooltip>
      </FloatingButton>
    );
  });

  useScreenWakeLockWhilePlaying({ client });

  const FloatingButtonsComponent = (
    <FloatingButtons>
      {BenchmarkFloatingButton}
      {DebugFloatingButton}
    </FloatingButtons>
  );

  if (client.game.isFinished) {
    return (
      <Fragment>
        {FloatingButtonsComponent}
        <HostGameFinished />
      </Fragment>
    );
  }

  if (!client.game.isStarted) {
    return (
      <Fragment>
        <Header />
        {FloatingButtonsComponent}
        <HostLobby />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <LottieCountdown show />
      {FloatingButtonsComponent}
      <CheckIsGeniallyCompatibleGuard
        geniallyId={client.geniallyId}
        userType={PlayUserType.Host}
      >
        <HostGame />
      </CheckIsGeniallyCompatibleGuard>
    </Fragment>
  );
});
