import type { ComponentType, HTMLAttributes, ReactNode } from 'react';

import type { Breakpoint } from '@genially/design-system';
import { PixelsPerBreakpoint, useBreakpoint } from '@genially/design-system';
import { calculate } from '@genially/utils/build/module';

export interface ResponsiveTextProps
  extends HTMLAttributes<HTMLHeadElement | HTMLParagraphElement> {
  with: ComponentType;
  on?: { [key in Breakpoint | number]?: ComponentType };
  children?: ReactNode;
  dangerouslySetInnerHTML?: {
    __html: string;
  };
}

export const ResponsiveText = ({
  with: With,
  on = {},
  children,
  ...props
}: ResponsiveTextProps) => {
  const breakpoint = useBreakpoint();

  const Component = calculate(() => {
    if (!on) {
      return With;
    }

    const pixelBreakpoints: [number, Breakpoint | number][] = Object.keys(on).map(bp => {
      const bpAsBreakPoint = bp as Breakpoint;

      try {
        const pixels = parseInt(bp, 10);

        if (!Number.isNaN(pixels)) {
          return [pixels, pixels];
        }

        return [PixelsPerBreakpoint[bpAsBreakPoint], bpAsBreakPoint];
      } catch {
        return [PixelsPerBreakpoint[bpAsBreakPoint], bpAsBreakPoint];
      }
    });

    const breakpointInUse = pixelBreakpoints.find(([pixels]) =>
      breakpoint.isDownwards(pixels),
    );

    return breakpointInUse ? (on[breakpointInUse[1]] as ComponentType) : With;
  });

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...props}>{children}</Component>;
};
