import { Fragment } from 'react';
import { observer } from 'mobx-react';

import { Tooltip } from '@genially/design-system';
import { StatisticsIcon } from '@genially/icons';
import { FEATURE_FLAGS } from '@genially/ui/build/constants/featureFlags';
import { calculate } from '@genially/utils/build/module';

import { Header } from '../../../../bootstrap/application/components/app/Routes/layouts/CommonLayout/components/Header/Header';
import { RouteNames } from '../../../../bootstrap/domain/RouteNames';
import { useShowDebugModal } from '../../../../shared/application/components/DebugModal/hooks/useShowDebugModal';
import { LottieCountdown } from '../../../../shared/application/components/LottieCountdown/LottieCountdown';
import { Ping } from '../../../../shared/application/components/PlayAppVersion/Ping';
import { useNavigation } from '../../../../shared/application/hooks/useNavigation';
import { useScreenWakeLockWhilePlaying } from '../../../../shared/application/hooks/useScreenWakeLockWhilePlaying';
import { usePlayerClientContext } from './components/PlayerClientProvider/hooks/usePlayerClientContext';
import { PlayerGame } from './components/PlayerGame/PlayerGame';
import { PlayerGameFinished } from './components/PlayerGameFinished/PlayerGameFinished';
import { PlayerLoadTest } from './components/PlayerLoadTest/PlayerLoadTest';
import { PlayerLobby } from './components/PlayerLobby/PlayerLobby';
import {
  FloatingButton,
  FloatingButtons,
} from '../../../../host/application/pages/HostGamePage/HostGamePage.styled';

export const PlayerGamePage = observer(() => {
  const { client } = usePlayerClientContext();
  const { getQueryParams } = useNavigation();

  const { loadTest } = getQueryParams(RouteNames.Player);

  const { showDebugModal } = useShowDebugModal(client);
  const canShowDebugButtons = client.hasFeatureFlag(
    FEATURE_FLAGS.FEAT_LT4_PLAY_BENCHMARK,
  );

  Ping.isVisible = canShowDebugButtons;

  const DebugFloatingButton = calculate(() => {
    if (!canShowDebugButtons) {
      return null;
    }

    return (
      <FloatingButton onClick={showDebugModal}>
        <Tooltip text="Debug">
          <StatisticsIcon />
        </Tooltip>
      </FloatingButton>
    );
  });

  const FloatingButtonsComponent = (
    <FloatingButtons>{DebugFloatingButton}</FloatingButtons>
  );

  useScreenWakeLockWhilePlaying({ client });

  if (loadTest) {
    return <PlayerLoadTest />;
  }

  if (client.game.isFinished) {
    return <PlayerGameFinished />;
  }

  if (!client.game.isStarted) {
    return (
      <Fragment>
        <Header />
        {FloatingButtonsComponent}
        <PlayerLobby />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <LottieCountdown show />
      {FloatingButtonsComponent}
      <Header disablePointerEvents />
      <PlayerGame />
    </Fragment>
  );
});
