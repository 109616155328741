import UIConfig from '@genially/ui/build/config/config';

const replaceUrl = (defaultUrl: string, dynamicSlug: string) => {
  switch (UIConfig.appEnv) {
    case 'dynamic':
      return `https://${window.location.hostname}`.replace('play-', `${dynamicSlug}-`);
    default:
      return defaultUrl;
  }
};

export const config = {
  urls: {
    view: replaceUrl(UIConfig.appUrls.view, 'view'),
    loggerServer: UIConfig.appUrls.loggerServer,
    play: replaceUrl(UIConfig.appUrls.play, 'play'),
    dashboard: UIConfig.appUrls.panel,
    editor: UIConfig.appUrls.editor,
    api: replaceUrl(UIConfig.appUrls.newApi, 'api'),
    auth: replaceUrl(UIConfig.appUrls.auth, 'auth'),
    web: replaceUrl(UIConfig.appUrls.web, 'web'),
  },
  appEnv: UIConfig.appEnv,
};
