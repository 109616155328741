import { MatchResult } from '../../constants';
import { StatusColor } from './constants';
import { Wrapper } from './Shape.styled';

interface ShapeProps {
  status: MatchResult;
}

export const Shape = ({ status }: ShapeProps) => {
  const color = StatusColor[status];

  return (
    <Wrapper>
      <svg
        width="100%"
        viewBox="0 0 149 148"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M98.5581 145.352L78.1144 132.431L59.6107 148L48.3687 126.586L24.5581 130.818L25.5331 106.65L2.79281 98.4087L15.7183 77.9701L0.149414 59.4613L21.5629 48.2244L17.3315 24.4087L41.4939 25.3836L49.7357 2.6434L70.1793 15.5689L88.683 0L99.925 21.4135L123.736 17.1821L122.766 41.3495L145.501 49.5913L132.575 70.0299L148.149 88.5387L126.731 99.7756L130.962 123.586L106.8 122.616L98.5581 145.352Z"
          fill="inherit"
        />
      </svg>
    </Wrapper>
  );
};

Shape.Status = MatchResult;
