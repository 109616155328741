import paperSrc from './assets/paper.svg';
import rockSrc from './assets/rock.svg';
import scissorsSrc from './assets/scissors.svg';

export enum Option {
  Rock,
  Paper,
  Scissors,
}

export const ResourceSrc = {
  [Option.Rock]: rockSrc,
  [Option.Paper]: paperSrc,
  [Option.Scissors]: scissorsSrc,
};

export enum AnimationSequence {
  Stop,
  Start,
  ShowResult,
}

export enum MatchResult {
  Win,
  Draw,
  Lose,
}
