import type { ReactElement, ReactNode } from 'react';
import { createContext, Fragment, useCallback, useContext, useState } from 'react';
import { observer } from 'mobx-react';

import { useOutsideClick } from '@genially/design-system';

import { Container, PositionalContainer, Wrapper } from './Modal.styled';

export interface ModalProviderContextType {
  openModal: (
    modal: ((parentElement: HTMLDivElement) => ReactElement) | ReactElement,
  ) => string;
  closeModal: (modalId?: string) => void;
}

const ModalProviderContext = createContext({});

export interface ModalProviderProps {
  children: ReactNode;
}

const ModalProvider = observer(({ children }: ModalProviderProps) => {
  const [openedModals, setOpenedModals] = useState<Record<string, ReactElement>>({});

  const hasOpenedModals = Object.keys(openedModals).length > 0;

  const containerRef = useOutsideClick(() => {
    if (hasOpenedModals) {
      setOpenedModals({});
    }
  });

  const openModal = useCallback<ModalProviderContextType['openModal']>(
    (modal: ((parentElement: HTMLDivElement) => ReactElement) | ReactElement) => {
      const id = Math.random().toString(36).substring(7);

      const container = containerRef.current;

      if (container) {
        const ModalComponent = typeof modal === 'function' ? modal(container) : modal;

        setOpenedModals(prev => {
          const currentModalsCount = Object.keys(prev).length;

          return {
            ...prev,
            [id]: (
              <PositionalContainer $zIndex={currentModalsCount + 10000}>
                {ModalComponent}
              </PositionalContainer>
            ),
          };
        });
      }

      return id;
    },
    [containerRef],
  );

  const closeModal = useCallback<ModalProviderContextType['closeModal']>(
    (modalId?: string) => {
      if (modalId) {
        setOpenedModals(prev => {
          const newModals = { ...prev };
          delete newModals[modalId];
          return newModals;
        });
      } else {
        setOpenedModals({});
      }
    },
    [],
  );

  const context: ModalProviderContextType = {
    openModal,
    closeModal,
  };

  return (
    <ModalProviderContext.Provider value={context}>
      <Wrapper $show={hasOpenedModals}>
        <Container ref={containerRef}>
          {Object.entries(openedModals).map(([key, el]) => (
            <Fragment key={key}>{el}</Fragment>
          ))}
        </Container>
      </Wrapper>
      {children}
    </ModalProviderContext.Provider>
  );
});

const useModal = () => {
  const context = useContext(ModalProviderContext) as ModalProviderContextType;

  if (Object.keys(context).length === 0) {
    throw new Error('useModalProvider must be used within a ModalProvider');
  }

  return context;
};

export { ModalProvider, useModal };
