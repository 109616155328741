import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './bootstrap/application/components/app/App';

ReactDOM.render(
  <App />,

  document.getElementById('root'),
);
