import type { ElementType } from 'react';
import { Flipper } from 'react-flip-toolkit';
import styled, { css, keyframes } from 'styled-components';

import { type Size, style } from '@genially/design-system';

import { ResponsiveText } from '../../../../../../../../shared/application/components/ResponsiveText/ResponsiveText';
import { Size as ParticipantSize } from './components/Participant/constants';

interface WrapperProps {
  $centerAligned?: boolean;
}

export const Wrapper = styled.div<WrapperProps>(({ theme, $centerAligned }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: $centerAligned ? 'center' : 'flex-start',

  width: '100%',
  height: '100%',

  overflow: 'hidden auto',

  ...style.mixins.scrollbar(theme, true),

  ...style.mixins.responsive.xs.downward({
    paddingRight: theme.size.units(0.5),
  }),
}));

const getFlipperGap = (size: Size, participantSize: ParticipantSize) => {
  switch (participantSize) {
    case ParticipantSize.Small:
      return size.units(1);
    case ParticipantSize.Medium:
      return size.units(1.5);
    case ParticipantSize.Large:
    default:
      return size.units(3);
  }
};

interface StyledFlipperProps {
  $size: ParticipantSize;
}

export const StyledFlipper = styled(Flipper)<StyledFlipperProps>(
  ({ theme: { size }, $size }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: getFlipperGap(size, $size),

    ...style.mixins.responsive.xs.downward({
      flexDirection: 'column',
      width: '100%',
    }),
  }),
);

export const EmptyWrapper = styled.div({
  display: 'flex',
});

export const WaittingUsers = styled(ResponsiveText)(({ theme: { color } }) => ({
  color: color.content.tertiary.default(),
}));

const ellipsisKeyframes = keyframes({
  from: { clipPath: 'inset(0 100% 0 0)' },
  '25%': { clipPath: 'inset(0 66.6% 0 0)' },
  '50%': { clipPath: 'inset(0 33.3% 0 0)' },
  '75%': { clipPath: 'inset(0 0 0 0)' },
});

const withEllipsisAnimation = (Component: ElementType) =>
  styled(Component)(
    () => css`
      animation: ${ellipsisKeyframes} steps(1, end) 2s infinite;
    `,
  );

export const Ellipsis = withEllipsisAnimation(
  styled(WaittingUsers)({
    overflow: 'hidden',
    display: 'inline-block',
    verticalAlign: 'bottom',

    '&:after': {
      content: '\\2026',
    },
  }),
);
