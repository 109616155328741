import { useEffect, useState } from 'react';

import { CoreApiService } from '../../../../../../../shared/infrastructure/CoreApiService';

export type FeatureFlags = string[];

type UseFetchFeatureFlags =
  | {
      loadingFeatureFlags: true;
      fetchFeatureFlagError?: Error;
      featureFlags: undefined;
    }
  | {
      loadingFeatureFlags: false;
      fetchFeatureFlagError: undefined;
      featureFlags: FeatureFlags;
    };

export const useFetchFeatureFlags = (teamId: string): UseFetchFeatureFlags => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags | undefined>(undefined);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    if (featureFlags !== undefined) return;

    setLoading(true);

    CoreApiService.getFeatureFlags(teamId)
      .then(ffs => {
        setFeatureFlags(ffs);
      })
      .catch(e => {
        setError(e as Error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [featureFlags, teamId]);

  if (error) {
    return {
      featureFlags: undefined,
      loadingFeatureFlags: true,
      fetchFeatureFlagError: error,
    };
  }

  if (loading || featureFlags === undefined) {
    return {
      featureFlags: undefined,
      loadingFeatureFlags: true,
    };
  }

  return {
    featureFlags,
    loadingFeatureFlags: false,
    fetchFeatureFlagError: undefined,
  };
};
