export const fadeInAnimation = {
  from: {
    left: '50%',
    transform: 'translate(-50%, -50%) rotateY(0) scale(0)',
    opacity: 0,
  },

  to: {
    left: '50%',
    transform: 'translate(-50%, -50%) rotateY(0) scale(1)',
    opacity: 1,
    transformOrigin: 'center center',
  },
};

export const slideFromBackAnimation = {
  from: {
    left: '50%',
    transform: 'translate(800px, -50%) rotateY(-30deg) scale(0)',
    transformOrigin: '-100% 50%',
    opacity: 0,
  },

  to: {
    ...fadeInAnimation.to,
    transformOrigin: '-1800px 50%',
  },
};

export const rotateAnimation = {
  from: fadeInAnimation.to,

  to: {
    left: '50%',
    transform: 'translate(-50%, -50%) scale(1) rotateY(180deg)',
    transformOrigin: 'center center',
  },
};

export const translateAnimation = {
  from: rotateAnimation.to,

  to: {
    left: 0,
    transform: 'translate(-150%, -50%) translateX(-32px) scale(0.5) rotateY(180deg)',
    transformOrigin: 'right center',
  },
};
