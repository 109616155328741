import { toJS } from 'mobx';

import type { InteractiveQuestionsData } from '@genially/contracts';
import type { HostPlayP2pClient } from '@genially/p2p-client';

export const getInteractiveQuestionsDataFromClient = (
  client: HostPlayP2pClient,
): InteractiveQuestionsData => {
  return Object.fromEntries(
    Object.entries(client.game.asJS.interactiveQuestionAnswers).map(([key, value]) => [
      key,
      {
        answers: value.playerUserAnswers.map(p => toJS(p.answer)),
        isResolved: value.isResolved,
      },
    ]),
  );
};
