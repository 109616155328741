import { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { Breakpoint, Logo, useBreakpoint } from '@genially/design-system';
import { PlayP2pClientEventType } from '@genially/p2p-client';

import { RouteNames } from '../../../../../../bootstrap/domain/RouteNames';
import { useNavigation } from '../../../../../../shared/application/hooks/useNavigation';
import { useOpenGenially } from '../../../../../../shared/application/hooks/useOpenGenially';
import { useHostClientContext } from '../HostClientProvider/hooks/useHostClientContext';
import { Header } from './components/Header/Header';
import { Participants } from './components/Participants/Participants';
import { ParticipantsInfo } from './components/ParticipantsInfo/ParticipantsInfo';
import {
  Content,
  HeaderWrapper,
  LogoWrapper,
  ParticipantsInfoWrapper,
  Separator,
  Wrapper,
} from './HostLobby.styled';

export const HostLobby = observer(() => {
  const [showAll, setShowall] = useState(false);
  const { replaceUrl } = useNavigation();

  const handleLogoClick = useOpenGenially();

  const { isDownwards } = useBreakpoint();

  const isMobile = isDownwards(Breakpoint.xs);

  const { client } = useHostClientContext();

  const { geniallyId, sessionId, teamId } = client;

  const handleHeaderAnimationEnd = () => {
    setShowall(true);
  };

  useEffect(() => {
    replaceUrl({
      to: RouteNames.Host,
      params: {
        geniallyId,
        teamId,
        sessionId,
      },
    });
  }, [geniallyId, teamId, sessionId, replaceUrl]);

  useEffect(() => {
    client.on(PlayP2pClientEventType.Error, error => {
      console.error(error);
    });
  }, [geniallyId, client, replaceUrl, teamId]);

  return (
    <Wrapper>
      <HeaderWrapper
        onAnimationEnd={handleHeaderAnimationEnd}
        $isMobile={isMobile}
        $hasAnimation={!showAll}
      >
        <Header showAll={showAll} />
      </HeaderWrapper>

      {showAll && (
        <Fragment>
          {isMobile && (
            <Fragment>
              <Separator />
              <ParticipantsInfoWrapper>
                <ParticipantsInfo />
              </ParticipantsInfoWrapper>
            </Fragment>
          )}

          <Content>
            <Participants />
          </Content>
        </Fragment>
      )}

      <LogoWrapper onClick={handleLogoClick}>
        <Logo variant={Logo.Variant.LIGHT} hasAnimation />
      </LogoWrapper>
    </Wrapper>
  );
});
