import { PlayUserType } from '@genially/p2p-lib';

import { HostRoutePrefix, PlayerRoutePrefix } from '../../../../../domain/RouteNames';

export const getUserTypeFromUrl = () => {
  const urlObject = new URL(window.location.href);
  const { pathname } = urlObject;

  if (pathname.startsWith(HostRoutePrefix)) {
    return PlayUserType.Host;
  }

  if (pathname.startsWith(PlayerRoutePrefix)) {
    return PlayUserType.Player;
  }

  return undefined;
};
