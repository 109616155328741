import styled from 'styled-components';

import { style } from '@genially/design-system';
import { ArrowEndPointIconIcon, ReplaceIcon } from '@genially/icons';

import { ResponsiveText } from '../../../../../../shared/application/components/ResponsiveText/ResponsiveText';

export const Wrapper = styled.div(({ theme: { size } }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: size.units(4),
  padding: `${size.units(3)} ${size.units(2)} ${size.units(12)} ${size.units(2)}`,

  ...style.mixins.responsive.xs.downward({
    gap: size.units(1),
  }),
}));

export const StyledResponsiveText = styled(ResponsiveText)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));

export const UsernameSelectionWrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  gap: size.units(1.5),
  alignItems: 'center',
  width: '100%',

  ...style.mixins.responsive.xs.downward({
    flexDirection: 'column',
    gap: size.units(2),
  }),
}));

export const StyledArrowEndPointIcon = styled(ArrowEndPointIconIcon)(
  ({ theme: { color, size } }) => ({
    width: size.units(2),
    height: size.units(2),
    color: color.content.reversed.default(),
  }),
);

export const ErrorWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ContentWrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: size.units(2),
  width: '100%',
  maxWidth: size.units(69),
}));
