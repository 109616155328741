import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import type {
  HostPlayPostMessageConnectionViewApi,
  PlayPostMessageInteractiveQuestion,
} from '@genially/contracts';
import { HostPlayP2pClientEventType } from '@genially/p2p-client';
import type { PenpalAsyncMethodReturns } from '@genially/ui';
import { calculate } from '@genially/utils/build/module';

import { PlaySpinner } from '../../../../../../shared/application/components/PlaySpinner/PlaySpinner';
import { useGetInteractiveQuestion } from '../../../../../../shared/application/hooks/useGetInteractiveQuestion';
import { useHostClientContext } from '../HostClientProvider/hooks/useHostClientContext';

type ViewConnectionState =
  | {
      isConnected: false;
      connection: undefined;
      setConnection: (
        connection:
          | PenpalAsyncMethodReturns<HostPlayPostMessageConnectionViewApi>
          | undefined,
      ) => void;
    }
  | {
      isConnected: true;
      connection: PenpalAsyncMethodReturns<HostPlayPostMessageConnectionViewApi>;
      setConnection: (
        connection:
          | PenpalAsyncMethodReturns<HostPlayPostMessageConnectionViewApi>
          | undefined,
      ) => void;
    };
type HostGameContextSelectedQuestion = {
  interactiveQuestion: PlayPostMessageInteractiveQuestion;
  isLastScored: boolean;
};
export type HostGameContextType = {
  viewConnection: ViewConnectionState;

  sidebarSummary: {
    isOpen: boolean;
    open: () => void;
    close: () => void;
    toggle: () => void;

    selectedQuestion: HostGameContextSelectedQuestion | undefined;
    setSelectedQuestion: (question: HostGameContextSelectedQuestion) => void;
    removeSelectedQuestion: () => void;
  };

  interactiveQuestions: PlayPostMessageInteractiveQuestion[];
};

const HostGameContext = createContext({});

export interface HostGameProps {
  children: ReactNode;
}

const HostGameProvider = observer(({ children }: HostGameProps) => {
  const { client } = useHostClientContext();

  const { isLoading: isLoadingInteractiveQuestions, interactiveQuestions } =
    useGetInteractiveQuestion(client.geniallyId);

  const [connection, setConnection] = useState<
    PenpalAsyncMethodReturns<HostPlayPostMessageConnectionViewApi> | undefined
  >();

  const [isSidebarSummaryOpen, setIsSidebarSummaryOpen] = useState(false);

  const [selectedQuestion, setSelectedQuestion] = useState<
    HostGameContextSelectedQuestion | undefined
  >(undefined);

  const viewConnection = calculate(() => {
    if (connection) {
      return {
        isConnected: true,
        setConnection,
        connection,
      };
    }

    return {
      isConnected: false,
      setConnection,
    };
  });

  const openSidebarSummary = useCallback(() => {
    setIsSidebarSummaryOpen(true);
  }, []);

  const closeSidebarSummary = useCallback(() => {
    setIsSidebarSummaryOpen(false);
  }, []);

  const toggleSidebarSummary = useCallback(() => {
    setIsSidebarSummaryOpen(prev => !prev);
  }, []);

  const removeSelectedQuestion = useCallback(() => {
    setSelectedQuestion(undefined);
  }, []);

  const sidebarSummary = {
    isOpen: isSidebarSummaryOpen,
    selectedQuestion,
    setSelectedQuestion,
    removeSelectedQuestion,
    open: openSidebarSummary,
    close: closeSidebarSummary,
    toggle: toggleSidebarSummary,
  };

  useEffect(() => {
    if (connection) {
      if (isSidebarSummaryOpen && !selectedQuestion) {
        connection.setSidebarSummaryState({ open: false });
      }

      if (!selectedQuestion) return;

      if (isSidebarSummaryOpen) {
        connection.setSidebarSummaryState({
          open: true,
          interactiveQuestionId: selectedQuestion.interactiveQuestion.Id,
        });
      } else {
        connection.setSidebarSummaryState({ open: false });
      }
    }
  }, [connection, isSidebarSummaryOpen, selectedQuestion]);

  useEffect(() => {
    if (!isSidebarSummaryOpen) {
      setSelectedQuestion(undefined);
    }
  }, [isSidebarSummaryOpen]);

  const handleInteractiveQuestionResolved = useCallback(
    async ({
      interactiveQuestionId,
      isLastScored,
    }: {
      interactiveQuestionId: string;
      isLastScored?: boolean;
    }) => {
      const interactiveQuestion = interactiveQuestions.find(
        i => i.Id === interactiveQuestionId,
      );

      if (!interactiveQuestion) {
        return;
      }

      setSelectedQuestion({ interactiveQuestion, isLastScored: isLastScored ?? false });
      setIsSidebarSummaryOpen(true);
    },
    [interactiveQuestions],
  );

  useEffect(() => {
    client.on(
      HostPlayP2pClientEventType.InteractiveQuestionResolved,
      handleInteractiveQuestionResolved,
    );

    return () => {
      client.off(
        HostPlayP2pClientEventType.InteractiveQuestionResolved,
        handleInteractiveQuestionResolved,
      );
    };
  }, [client, handleInteractiveQuestionResolved]);

  if (isLoadingInteractiveQuestions) {
    return <PlaySpinner />;
  }

  const context: HostGameContextType = {
    viewConnection: viewConnection as ViewConnectionState,
    sidebarSummary,
    interactiveQuestions,
  };

  return <HostGameContext.Provider value={context}>{children}</HostGameContext.Provider>;
});

const useHostGameContext = () => {
  const context = useContext(HostGameContext) as HostGameContextType;

  if (Object.keys(context).length === 0) {
    throw new Error('useHostGameContext must be used within a HostGameProvider');
  }

  return context;
};

export { HostGameProvider, useHostGameContext };
