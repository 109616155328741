import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslate } from 'react-polyglot';
import { observer } from 'mobx-react';

import type {
  PlayerPlayPostMessageConnectionViewApi,
  PlayPostMessageInteractiveQuestion,
} from '@genially/contracts';
import { PlayerPlayP2pClientEventType } from '@genially/p2p-client';
import type { PenpalAsyncMethodReturns } from '@genially/ui';
import { calculate } from '@genially/utils/build/module';

import { PlaySpinner } from '../../../../../../shared/application/components/PlaySpinner/PlaySpinner';
import { useGetInteractiveQuestion } from '../../../../../../shared/application/hooks/useGetInteractiveQuestion';
import { useNotificationManager } from '../../../../../../shared/application/services/useNotificationManager';
import { usePlayerClientContext } from '../PlayerClientProvider/hooks/usePlayerClientContext';

type ViewConnectionState =
  | {
      isConnected: false;
      connection: undefined;
      setConnection: (
        connection:
          | PenpalAsyncMethodReturns<PlayerPlayPostMessageConnectionViewApi>
          | undefined,
      ) => void;
    }
  | {
      isConnected: true;
      connection: PenpalAsyncMethodReturns<PlayerPlayPostMessageConnectionViewApi>;
      setConnection: (
        connection:
          | PenpalAsyncMethodReturns<PlayerPlayPostMessageConnectionViewApi>
          | undefined,
      ) => void;
    };
type PlayerGameContextSelectedQuestion = {
  interactiveQuestion: PlayPostMessageInteractiveQuestion;
  isLastScored: boolean;
};
export type PlayerGameContextType = {
  viewConnection: ViewConnectionState;

  sidebarSummary: {
    isOpen: boolean;
    open: () => void;
    close: () => void;
    toggle: () => void;

    selectedQuestion: PlayerGameContextSelectedQuestion | undefined;
    setSelectedQuestion: (question: PlayerGameContextSelectedQuestion) => void;
    removeSelectedQuestion: () => void;
  };

  interactiveQuestions: PlayPostMessageInteractiveQuestion[];
};

const PlayerGameContext = createContext({});

export interface PlayerGameProps {
  children: ReactNode;
}

const PlayerGameProvider = observer(({ children }: PlayerGameProps) => {
  const t = useTranslate();

  const { client } = usePlayerClientContext();

  const { isLoading: isLoadingInteractiveQuestions, interactiveQuestions } =
    useGetInteractiveQuestion(client.geniallyId);

  const [connection, setConnection] = useState<
    PenpalAsyncMethodReturns<PlayerPlayPostMessageConnectionViewApi> | undefined
  >();

  const { notificationManager } = useNotificationManager();

  const [isSidebarSummaryOpen, setIsSidebarSummaryOpen] = useState(false);

  const [selectedQuestion, setSelectedQuestion] = useState<
    PlayerGameContextSelectedQuestion | undefined
  >(undefined);

  const viewConnection = calculate(() => {
    if (connection) {
      return {
        isConnected: true,
        setConnection,
        connection,
      };
    }

    return {
      isConnected: false,
      setConnection,
    };
  });

  const openSidebarSummary = useCallback(() => {
    setIsSidebarSummaryOpen(true);
  }, []);

  const closeSidebarSummary = useCallback(() => {
    setIsSidebarSummaryOpen(false);
  }, []);

  const toggleSidebarSummary = useCallback(() => {
    setIsSidebarSummaryOpen(prev => !prev);
  }, []);

  const removeSelectedQuestion = useCallback(() => {
    setSelectedQuestion(undefined);
  }, []);

  const sidebarSummary = {
    isOpen: isSidebarSummaryOpen,
    selectedQuestion,
    setSelectedQuestion,
    removeSelectedQuestion,
    open: openSidebarSummary,
    close: closeSidebarSummary,
    toggle: toggleSidebarSummary,
  };

  useEffect(() => {
    const handleNotifyAnswerReceived = ({
      interactiveQuestionId,
    }: {
      interactiveQuestionId: string;
    }) => {
      if (connection) {
        connection.notifyAnswerReceived(interactiveQuestionId);
      }
    };

    const handleNotifyInteractiveQuestionResolved = ({
      interactiveQuestionId,
    }: {
      interactiveQuestionId: string;
    }) => {
      if (connection) {
        connection.notifyInteractiveQuestionResolved(interactiveQuestionId);
      }
    };

    const handleNotifyToggleOpenAnswerVisibility = ({
      userAlias,
      isHidden,
    }: {
      userAlias: string;
      isHidden: boolean;
    }) => {
      if (isHidden && userAlias === client.myPlayerData.username) {
        notificationManager.warning(
          t('__new.play.ranking.participant.hiddenOpenAnswer.toast'),
        );
      }
    };

    client.on(
      PlayerPlayP2pClientEventType.InteractiveQuestionAnswered,
      handleNotifyAnswerReceived,
    );

    client.on(
      PlayerPlayP2pClientEventType.InteractiveQuestionResolved,
      handleNotifyInteractiveQuestionResolved,
    );

    client.on(
      PlayerPlayP2pClientEventType.ToggleOpenAnswerVisibility,
      handleNotifyToggleOpenAnswerVisibility,
    );

    return () => {
      client.off(
        PlayerPlayP2pClientEventType.InteractiveQuestionAnswered,
        handleNotifyAnswerReceived,
      );

      client.off(
        PlayerPlayP2pClientEventType.InteractiveQuestionResolved,
        handleNotifyInteractiveQuestionResolved,
      );

      client.off(
        PlayerPlayP2pClientEventType.ToggleOpenAnswerVisibility,
        handleNotifyToggleOpenAnswerVisibility,
      );
    };
  }, [client, connection, notificationManager, t]);

  useEffect(() => {
    if (!isSidebarSummaryOpen) {
      setSelectedQuestion(undefined);
    }
  }, [isSidebarSummaryOpen]);

  if (isLoadingInteractiveQuestions) {
    return <PlaySpinner />;
  }

  const context: PlayerGameContextType = {
    viewConnection: viewConnection as ViewConnectionState,
    sidebarSummary,
    interactiveQuestions,
  };

  return (
    <PlayerGameContext.Provider value={context}>{children}</PlayerGameContext.Provider>
  );
});

const usePlayerGameContext = () => {
  const context = useContext(PlayerGameContext) as PlayerGameContextType;

  if (Object.keys(context).length === 0) {
    throw new Error('usePlayerGameContext must be used within a PlayerGameProvider');
  }

  return context;
};

export { PlayerGameProvider, usePlayerGameContext };
