import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { PlayP2pClient } from '@genially/p2p-client';
import { PlayUserType } from '@genially/p2p-lib';

import { RouteNames } from '../../../bootstrap/domain/RouteNames';
import type { RouteNavigation } from './useNavigation';
import { useNavigation } from './useNavigation';

export interface HostProps {
  teamId: string;
  geniallyId: string;
  sessionId?: string;
}

export interface PlayerProps {
  sessionId: string;
  geniallyId: string;
  username?: string;
}

export const HostParamsToValidate: { key: keyof HostProps; isOptional: boolean }[] = [
  { key: 'teamId', isOptional: false },
  { key: 'geniallyId', isOptional: false },
  { key: 'sessionId', isOptional: true },
];

export const PlayerParamsToValidate: { key: keyof PlayerProps; isOptional: boolean }[] = [
  { key: 'sessionId', isOptional: false },
  { key: 'geniallyId', isOptional: false },
  { key: 'username', isOptional: true },
];

type Parameters =
  | (typeof HostParamsToValidate)[number]
  | (typeof PlayerParamsToValidate)[number];

const validationFunctions: Record<
  Parameters['key'],
  (v: unknown, isOptional: boolean) => boolean
> = {
  geniallyId: (value: unknown, isOptional: boolean) => {
    if (isOptional && value === undefined) {
      return true;
    }

    return PlayP2pClient.isValidGeniallyId(value);
  },
  sessionId: (value: unknown, isOptional: boolean) => {
    if (isOptional && value === undefined) {
      return true;
    }

    return PlayP2pClient.isValidSessionId(value);
  },
  teamId: (value: unknown, isOptional: boolean) => {
    if (isOptional && value === undefined) {
      return true;
    }

    return PlayP2pClient.isValidTeamId(value);
  },
  username: (value: unknown, isOptional: boolean) => {
    if (isOptional && value === undefined) {
      return true;
    }

    if (typeof value !== 'string') {
      return false;
    }

    return PlayP2pClient.isValidUsername(value);
  },
};

export const useValidateParams = <T extends HostProps | PlayerProps>(
  role: PlayUserType,
  routeNavigationOnInvalidParams: RouteNavigation = {
    to: RouteNames.NotFound,
  },
): T | undefined => {
  const params = useParams();
  const { navigate } = useNavigation();

  const getParamKeysToValidate = useCallback(() => {
    if (role === PlayUserType.Host) {
      return HostParamsToValidate;
    }

    return PlayerParamsToValidate;
  }, [role]);

  useEffect(() => {
    const paramKeysToValidate = getParamKeysToValidate();

    for (const paramKeyValidation of paramKeysToValidate) {
      const validationFunction = validationFunctions[paramKeyValidation.key];

      if (!validationFunction) {
        console.warn(
          `No validation function found for parameter ${paramKeyValidation.key}`,
        ); // eslint-disable-line no-console
        navigate(routeNavigationOnInvalidParams);

        break;
      }

      const parameter = params[paramKeyValidation.key];

      if (!validationFunction(parameter, paramKeyValidation.isOptional)) {
        console.warn(
          `Invalid parameter ${paramKeyValidation.key} (Optional=${paramKeyValidation.isOptional}) with value ${parameter}`,
        ); // eslint-disable-line no-console

        navigate(routeNavigationOnInvalidParams);
        break;
      }
    }
  }, [getParamKeysToValidate, navigate, params, routeNavigationOnInvalidParams]);

  return (params ?? {}) as unknown as T | undefined;
};
