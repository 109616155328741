export enum GeniallyIncompatibleErrorCause {
  DRAFT = 'Draft',
  HAS_PASSWORD = 'HasPassword',
}

export class GeniallyIncompatibleError extends Error {
  private constructor(public readonly cause: GeniallyIncompatibleErrorCause) {
    super(`Genially is incompatible: ${cause}`);
  }

  static causeIsDraft(): GeniallyIncompatibleError {
    return new GeniallyIncompatibleError(GeniallyIncompatibleErrorCause.DRAFT);
  }

  static causeHasPassword(): GeniallyIncompatibleError {
    return new GeniallyIncompatibleError(GeniallyIncompatibleErrorCause.HAS_PASSWORD);
  }
}
