import { useContext } from 'react';

import {
  LottieAnimationManagerContext,
  type LottieAnimationManagerContextType,
} from '../context/LottieAnimationManager.context';

export const useLottieAnimationManager = () => {
  const context = useContext(
    LottieAnimationManagerContext,
  ) as LottieAnimationManagerContextType;

  if (Object.keys(context).length === 0) {
    throw new Error(
      'useLottieAnimationManager must be used within a LottieAnimationManagerProvider',
    );
  }

  return context;
};
