import styled from 'styled-components';

import { Wrapper as HeaderWrapper } from './components/Header/Header.styled';

interface LottieAnimationWrapperProps {
  $isCentered?: boolean;
}

export const LottieAnimationWrapper = styled.div<LottieAnimationWrapperProps>(
  ({ $isCentered }) => ({
    width: '100%',

    height: $isCentered ? '100%' : undefined,

    userSelect: 'none',
    pointerEvents: 'none',
  }),
);

export const Wrapper = styled.div(({ theme: { color } }) => ({
  margin: '0 auto',
  position: 'relative',
  overflow: 'auto',
  overflowX: 'hidden',

  display: 'flex',
  '> *': {
    flexGrow: 1,
  },

  backgroundColor: color.background.reversed.default(),
  minHeight: '100dvh',

  [LottieAnimationWrapper]: {
    position: 'absolute',
    bottom: '0',
    left: '0',
  },

  [HeaderWrapper]: {
    position: 'absolute',
    top: 0,
    left: 0,

    zIndex: 1,
  },
}));
