import { createRef, Fragment, useMemo, useState } from 'react';

import { Spinner } from '@genially/design-system';
import { SessionIdLength } from '@genially/p2p-client';
import { isTabletOrMobile } from '@genially/ui/build/utils/devices/device';
import { useEffectOnce } from '@genially/ui/src/hooks/useEffectOnce';

import { config } from '../../../../../../../../bootstrap/application/config/config';
import {
  InputsContainer,
  InputSeparator,
  SpinnerWrapper,
  StyledInput,
  Wrapper,
} from './SessionInput.styled';

const isNumber = (value: string) => {
  return /^\d+$/.test(value);
};

const isValidSessionId = (value: string | undefined): value is string => {
  if (value === undefined) return false;

  if (value.length !== SessionIdLength) return false;

  return isNumber(value);
};

interface SessionInputProps {
  initialValue?: string;

  length: number;
  checking: boolean;
  hasErrors?: boolean;

  onComplete: (sessionId: string) => void;
  onEmptyCode?: () => void;
}

export const SessionInput = ({
  initialValue,
  length,
  checking,
  hasErrors,
  onComplete,
  onEmptyCode,
}: SessionInputProps) => {
  const [sessionId, setSessionId] = useState(
    isValidSessionId(initialValue) ? initialValue : '',
  );

  const inputs = useMemo(() => {
    return Array.from({ length }, () => createRef<HTMLInputElement>());
  }, [length]);

  const handleKeyDown =
    (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
      const hasValue = !!event.currentTarget.value;

      if (event.key !== 'Backspace' || index === 0 || hasValue) return;

      inputs[index - 1].current?.focus();
    };

  const handleBeforeInput = (e: React.FormEvent<HTMLInputElement>) => {
    const inputValue = e.nativeEvent as InputEvent;
    const regex = /^[0-9]+$/;

    if (inputValue.data && !regex.test(inputValue.data)) {
      e.preventDefault();
    }
  };

  const updateSessionId = (index: number, value?: number) => {
    const newSessionId = `${sessionId.substring(0, index)}${value ?? ' '}${sessionId.substring(index + 1)}`;

    setSessionId(newSessionId);

    return newSessionId;
  };

  useEffectOnce(() => {
    if (isValidSessionId(initialValue)) {
      onComplete(initialValue);
    }
  });

  const handleInputChange =
    (index: number, currentValue: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (checking) return;

      const isDeleting = !isNumber(event.target.value.trim());

      if (isDeleting) {
        let newSessionId;
        if (currentValue) {
          newSessionId = updateSessionId(index);
        } else if (index > 0) {
          inputs[index - 1]?.current?.focus();

          newSessionId = updateSessionId(index - 1);
        }

        if (newSessionId?.trim().length === 0 && onEmptyCode) {
          onEmptyCode();
        }

        return;
      }

      if (event.target.value.length > 1) {
        // eslint-disable-next-line prefer-destructuring, no-param-reassign
        event.target.value = event.target.value[event.target.value.length - 1];
      }

      const newSessionId = updateSessionId(index, +event.target.value);

      setSessionId(newSessionId);

      if (index < SessionIdLength - 1) {
        inputs[index + 1].current?.focus();
      }

      if (newSessionId.length === SessionIdLength) {
        onComplete(newSessionId);
      }
    };

  const moveCursorAtEnd = (index: number) => {
    const input = inputs[index].current;

    if (!input) return;

    input.selectionStart = 1;
    input.selectionEnd = 1;
  };

  const handleFocus = (index: number) => () => {
    if (!isTabletOrMobile) {
      moveCursorAtEnd(index);
    }
  };

  const handleClick = (index: number) => () => {
    if (!isTabletOrMobile) {
      moveCursorAtEnd(index);
    }
  };

  const extractSessionId = (text: string): string | null => {
    const allowedDomains = [config.urls.play];

    const sessionIdRegex = new RegExp(`^\\d{${SessionIdLength}}$`);

    if (sessionIdRegex.test(text)) return text;

    const urlRegex = new RegExp(`^(${allowedDomains.join('|')})/p/[^/]+/(\\d{6})$`);
    const match = text.match(urlRegex);
    return match ? match[2] : null;
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (checking) return;

    const clipboardText = event.clipboardData.getData('text');
    const sessionCode = extractSessionId(clipboardText);

    if (!sessionCode || sessionCode.length !== SessionIdLength) {
      return;
    }

    setSessionId(sessionCode);

    onComplete(sessionCode);
  };

  const mobileProps: React.ComponentProps<'input'> = isTabletOrMobile
    ? {
        type: 'number',
        inputMode: 'numeric',
        pattern: '[0-9]*',
      }
    : {};

  return (
    <Wrapper>
      <InputsContainer>
        {inputs.map((ref, index, arr) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <StyledInput
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...mobileProps}
              ref={ref}
              value={sessionId[index] ?? ''}
              disabled={checking}
              $hasErrors={hasErrors}
              autoFocus={index === 0}
              onFocus={handleFocus(index)}
              onClick={handleClick(index)}
              onKeyDown={handleKeyDown(index)}
              onBeforeInput={handleBeforeInput}
              onChange={handleInputChange(index, sessionId[index]?.trim())}
              onPaste={handlePaste}
            />
            {index + 1 === arr.length / 2 && <InputSeparator />}
          </Fragment>
        ))}
      </InputsContainer>

      {checking && (
        <SpinnerWrapper>
          <Spinner size={32} />
        </SpinnerWrapper>
      )}
    </Wrapper>
  );
};
