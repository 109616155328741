import type { ElementType } from 'react';
import styled, { css, keyframes } from 'styled-components';

import {
  BasicSubtitle,
  BiggerSubtitle,
  type Size,
  SmallParagraph,
  SmallSubtitle,
} from '@genially/design-system';
import { CloseSmallIcon, MuteIcon, SoundIcon } from '@genially/icons';

import { AnimationSequence, MatchResult, Option } from './constants';

export const StyledCloseSmallIcon = styled(CloseSmallIcon)(
  ({ theme: { color, size } }) => ({
    color: color.content.secondary.default(),
    width: size.units(2),
    height: size.units(2),
    cursor: 'pointer',
  }),
);

export const StyledSoundIcon = styled(SoundIcon)(({ theme: { color, size } }) => ({
  color: color.content.secondary.default(),
  width: size.units(2),
  height: size.units(2),
  cursor: 'pointer',
}));

export const StyledMuteIcon = styled(MuteIcon)(({ theme: { color, size } }) => ({
  color: color.content.secondary.default(),
  width: size.units(2),
  height: size.units(2),
  cursor: 'pointer',
}));

export const Wrapper = styled.div(({ theme: { color, size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: size.units(4),
  alignItems: 'center',
}));

export const ContentWrapper = styled.div(({ theme: { color, size } }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: size.units(3),
  alignItems: 'center',
  backgroundColor: color.background.quaternary.default(),
  padding: `${size.units(7.25)} ${size.units(4)} ${size.units(4)} ${size.units(4)}`,
  borderRadius: size.units(3),

  [StyledCloseSmallIcon]: {
    position: 'absolute',
    top: size.units(2),
    right: size.units(2),
  },

  [`${StyledSoundIcon}, ${StyledMuteIcon}`]: {
    position: 'absolute',
    top: size.units(2),
    left: size.units(2),
  },
}));

const scaleInKeyframes = keyframes({
  '0%': {
    transform: 'scale(0)',
  },

  '100%': {
    transform: 'scale(1)',
  },
});

const scaleOutKeyframes = keyframes({
  '0%': {
    transform: 'scale(1)',
  },

  '100%': {
    transform: 'scale(0)',
  },
});

interface withScaleInAnimationProps {
  $show: boolean;
}

const withScaleAnimation = (Component: ElementType) =>
  styled(Component)<withScaleInAnimationProps>(
    ({ $show }) => css`
      animation: ${$show ? scaleInKeyframes : scaleOutKeyframes} 0.3s linear forwards;
    `,
  );

export const ShapeImageScaleAnimationWrapper = withScaleAnimation(styled.div({}));

const rotateKeyframes = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },

  '100%': {
    transform: 'rotate(360deg)',
  },
});

const withRotateAnimation = (Component: ElementType) =>
  styled(Component)(
    () => css`
      animation: ${rotateKeyframes} 4s linear infinite;
    `,
  );

export const ShapeImageWrapper = withRotateAnimation(
  styled.div(({ theme: { size } }) => ({
    width: size.units(19),
  })),
);

export const ShapeWrapper = styled.div({});

export const GameWrapper = styled.div(({ theme: { color, size } }) => ({
  position: 'relative',
  display: 'flex',
  gap: size.units(6),

  [ShapeWrapper]: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: `translateX(-50%) translateY(${size.units(2)})`,
  },
}));

export const ResultWrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: size.units(2),
  width: size.units(12),
}));

export const StyledSmallParagraph = styled(SmallParagraph)(({ theme: { color } }) => ({
  color: color.content.primary.default(),
}));

const startPlayerKeyframes = keyframes({
  '0%': {
    transform: 'rotate(0)',
  },

  '20%': {
    transform: 'rotate(-20deg)',
  },

  '40%': {
    transform: 'rotate(0)',
  },

  '60%': {
    transform: 'rotate(-20deg)',
  },

  '80%': {
    transform: 'rotate(0)',
  },

  '100%': {
    transform: 'rotate(-20deg)',
  },
});

const showResultPlayerKeyframes = keyframes({
  '0%': {
    transform: 'rotate(-20deg)',
  },

  '100%': {
    transform: 'rotate(0)',
  },
});

const startBotKeyframes = keyframes({
  '0%': {
    transform: 'rotate(0)',
  },

  '20%': {
    transform: 'rotate(20deg)',
  },

  '40%': {
    transform: 'rotate(0)',
  },

  '60%': {
    transform: 'rotate(20deg)',
  },

  '80%': {
    transform: 'rotate(0)',
  },

  '100%': {
    transform: 'rotate(20deg)',
  },
});

const showResultBotKeyframes = keyframes({
  '0%': {
    transform: 'rotate(20deg)',
  },

  '100%': {
    transform: 'rotate(0)',
  },
});

interface withAnimationSequenceProps {
  $animationSequence: AnimationSequence;
}

const withAnimationSequence = (Component: ElementType, isPlayer: boolean) =>
  styled(Component)<withAnimationSequenceProps>(({ $animationSequence }) => {
    if ($animationSequence === AnimationSequence.Start) {
      return css`
        animation: ${isPlayer ? startPlayerKeyframes : startBotKeyframes} 1s linear
          forwards;
      `;
    }

    if ($animationSequence === AnimationSequence.ShowResult) {
      return css`
        animation: ${isPlayer ? showResultPlayerKeyframes : showResultBotKeyframes} 0.2s
          linear;
      `;
    }

    return undefined;
  });

export const ResultPlayerImageWrapper = withAnimationSequence(
  styled.div(({ theme: { size } }) => ({
    display: 'flex',
    alignItems: 'center',

    transformOrigin: 'left center',
    height: size.units(15.375),
  })),
  true,
);

const getResultPlayerHeight = (option: Option, size: Size) => {
  if (option === Option.Rock) {
    return size.units(13.25);
  }

  return size.units(15.4);
};

const getResultPlayerTop = (option: Option, size: Size) => {
  if (option === Option.Rock) {
    return size.units(1.5);
  }

  return size.units(0);
};

interface ResultPlayerProps {
  $option: Option;
}

export const ResultPlayerImage = styled.img<ResultPlayerProps>(
  ({ theme: { size }, $option }) => ({
    position: 'relative',
    height: getResultPlayerHeight($option, size),
    top: getResultPlayerTop($option, size),

    transform: 'rotate(90deg)',
  }),
);

export const ResultBotImageWrapper = withAnimationSequence(
  styled.div(({ theme: { size } }) => ({
    display: 'flex',
    alignItems: 'center',

    transformOrigin: 'right center',
    height: size.units(15.375),
  })),
  false,
);

export const ResultBotImage = styled(ResultPlayerImage)({
  transform: 'rotate(90deg) scaleY(-1)',
});

export const StyledBiggerSubtitle = styled(BiggerSubtitle)(({ theme: { color } }) => ({
  color: color.content.primary.default(),
}));

export const OptionsWrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  gap: size.units(5),
  alignItems: 'flex-end',
}));

export const OptionWrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: size.units(1),
  alignItems: 'center',
}));

export const StyledSmallSubtitle = styled(SmallSubtitle)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));

const getOptionHeight = (option: Option, size: Size) => {
  if (option === Option.Rock) {
    return size.units(6.5);
  }

  return size.units(7.5);
};

interface OptionImageProps {
  $isSelected: boolean;
  $option: Option;
}

export const OptionImage = styled.img<OptionImageProps>(
  ({ theme: { size, transition }, $isSelected, $option }) => ({
    height: getOptionHeight($option, size),

    opacity: $isSelected ? 1 : 0.3,

    transition: transition.standard('opacity'),

    cursor: 'pointer',

    filter:
      'invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(99%) contrast(103%)',
  }),
);
