import styled from 'styled-components';

import { style } from '@genially/design-system';

export const Wrapper = styled.div({
  maxWidth: '40%',

  ...style.mixins.responsive.sm.downward({
    maxWidth: '100%',
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
  }),
});
