import { useTranslate } from 'react-polyglot';

import { Button, WarningModal } from '@genially/design-system';

export const FinishGameModal = ({
  parentElement,
  closeModal,
  handleFinishGame,
}: {
  parentElement: HTMLElement;
  closeModal: () => void;
  handleFinishGame: () => void;
}) => {
  const t = useTranslate();

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <WarningModal
      parentElement={parentElement}
      title={t('__new.play.closeSession.modal.title')}
      description={t('__new.play.closeSession.modal.description')}
      withoutImage
      close={() => closeModal()}
      PrimaryButton={
        <Button variant={Button.Variant.DANGER} onClick={handleFinishGame}>
          {t('__new.play.closeSession.modal.button.cta')}
        </Button>
      }
      SecondaryButton={
        <Button onClick={handleCloseModal} variant={Button.Variant.PLAIN}>
          {t('__new.modal.button.cancel')}
        </Button>
      }
    />
  );
};
