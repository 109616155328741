import styled from 'styled-components';

import { style } from '@genially/design-system';

export const LogoWrapper = styled.div(({ theme: { size } }) => ({
  height: size.units(6),
  cursor: 'pointer',

  ...style.mixins.responsive.xs.downward({
    height: size.units(5),
  }),

  svg: {
    width: 'auto',
  },
}));

export const Wrapper = styled.div(({ theme: { size } }) => ({
  position: 'relative',
  width: '100%',
  zIndex: 1,

  [LogoWrapper]: {
    position: 'absolute',
    bottom: size.units(2),
    left: size.units(2),
    zIndex: 3,
  },
}));
