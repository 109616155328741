import axios from 'axios';

import { decodeError } from '@genially/ui/build/utils/decodeError';

import { config } from '../../bootstrap/application/config/config';

const client = axios.create({
  baseURL: `${config.urls.api}/api`,
  withCredentials: true,
});

export const CoreApiService = {
  getFeatureFlags: (teamId: string): Promise<string[]> => {
    return client
      .get<string[]>('/v1/users/@me/feature-flags', { params: { teamId } })
      .then(res => res.data)
      .catch(error => {
        console.error(decodeError(error));

        return [];
      });
  },
};
