import { createContext, useCallback, useMemo, useState } from 'react';

import { LottieAnimation } from '../LottieAnimation';
import type { LottieAnimations } from '../types/lottieAnimations';

export interface LottieAnimationManagerContextType {
  play: (data: LottieAnimations) => void;
  stop: () => void;
  addEndLoopListener: (listener: () => void) => void;
  removeEndLoopListener: (listener: () => void) => void;
}

export const LottieAnimationManagerContext = createContext({});

interface LottieAnimationManagerProviderProps {
  children: React.ReactNode;
  Wrapper: (props: { children: React.ReactNode; $isCentered: boolean }) => JSX.Element;
}

export const LottieAnimationManagerProvider = ({
  children,
  Wrapper,
}: LottieAnimationManagerProviderProps) => {
  const [animationsData, setAnimationsData] = useState<LottieAnimations | undefined>(
    undefined,
  );

  const [isCentered, setIsCentered] = useState<boolean>(false);

  const [endLoopListeners, setEndLoopListeners] = useState<(() => void)[]>([]);

  const play = useCallback<LottieAnimationManagerContextType['play']>(animations => {
    setAnimationsData(animations);
  }, []);

  const stop = useCallback<LottieAnimationManagerContextType['stop']>(() => {
    setAnimationsData(undefined);
  }, []);

  const addEndLoopListener = useCallback<
    LottieAnimationManagerContextType['addEndLoopListener']
  >(listener => {
    setEndLoopListeners(prev => {
      return !prev.includes(listener) ? [...prev, listener] : prev;
    });
  }, []);

  const removeEndLoopListener = useCallback<
    LottieAnimationManagerContextType['removeEndLoopListener']
  >(listener => {
    setEndLoopListeners(prev => {
      return prev.filter(l => l !== listener);
    });
  }, []);

  const context: LottieAnimationManagerContextType = useMemo(
    () => ({
      play,
      stop,
      addEndLoopListener,
      removeEndLoopListener,
    }),
    [play, stop, addEndLoopListener, removeEndLoopListener],
  );

  return (
    <LottieAnimationManagerContext.Provider value={context}>
      {children}

      <Wrapper $isCentered={isCentered}>
        <LottieAnimation
          data={animationsData}
          endLoopListener={endLoopListeners}
          handleIsCentered={setIsCentered}
        />
      </Wrapper>
    </LottieAnimationManagerContext.Provider>
  );
};
