import { config } from '../../../bootstrap/application/config/config';
import { RouteNames } from '../../../bootstrap/domain/RouteNames';
import type { NotificationManager } from '../services/NotificationManager';

export const shareSessionCode = async ({
  sessionId,
  geniallyId,
  notificationManager,
  isMobile,
  t,
}: {
  geniallyId: string;
  sessionId: string;
  notificationManager: NotificationManager;
  isMobile?: boolean;
  t: any;
}) => {
  try {
    const sanitizedSessionId = sessionId.replace(/-/g, '');

    const urlRoute = RouteNames.PlayerUsernameSelection.replace(
      ':geniallyId',
      geniallyId,
    ).replace(':sessionId', sanitizedSessionId);

    const urlToCopy = `${config.urls.play}${urlRoute}`;

    if (isMobile && navigator.share) {
      try {
        await navigator.share({
          title: 'Live session',
          text: t('__new.play.creatorLobby.join.mobile.sharedText'),
          url: urlToCopy,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      notificationManager.success(t('__new.play.creatorLobby.join.copiedCodeTooltip'));
      await navigator.clipboard.writeText(urlToCopy);
    }
  } catch (e) {
    console.error(e);

    notificationManager.error(t('__new.play.creatorLobby.join.noCopiedCodeTooltip'));
  }
};
