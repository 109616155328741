import styled from 'styled-components';

import { WarningBasicIcon } from '@genially/icons';

import { ResponsiveText } from '../../../../../../../../shared/application/components/ResponsiveText/ResponsiveText';

export const ErrorInfoWrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  gap: size.units(1),
  alignItems: 'baseline',
}));

export const StyledResponsiveText = styled(ResponsiveText)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));

export const StyledWarningBasicIcon = styled(WarningBasicIcon)(
  ({ theme: { color, size } }) => ({
    width: size.units(2),
    height: size.units(2),
    minWidth: size.units(2),
    minHeight: size.units(2),
    color: color.content.error.default(),
  }),
);
