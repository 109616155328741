/* eslint-disable consistent-return */
import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-polyglot';
import { observer } from 'mobx-react';

import { NotificationManager } from '@genially/design-system';
import type { PlayerPlayP2pClient } from '@genially/p2p-client';
import {
  P2pClientEventType,
  PlayerPlayP2pClientEventType,
  PlayP2pClientEventType,
} from '@genially/p2p-client';
import { PlayErrorCodes } from '@genially/p2p-lib';

import { RouteNames } from '../../../../../../bootstrap/domain/RouteNames';
import { Ping } from '../../../../../../shared/application/components/PlayAppVersion/Ping';
import { useFetchInitialSlide } from '../../../../../../shared/application/hooks/useFetchInitialSlide';
import { useNavigation } from '../../../../../../shared/application/hooks/useNavigation';
import { useSleep } from '../../../../../../shared/application/hooks/useSleep';
import type { PlayerProps } from '../../../../../../shared/application/hooks/useValidateParams';
import { PlayerPreLobby } from './components/PlayerPreLobby/PlayerPreLobby';
import { SessionLostOverlay } from './components/SessionLostOverlay/SessionLostOverlay';
import { createPlayerPlayP2pClient } from './utils/createPlayerPlayP2pClient';

export type PlayerClientContextType = {
  client: PlayerPlayP2pClient;
};

export const PlayerClientContext = createContext({});

export const MinimumDelayOfPreLobby = 2000;
export const TimeoutOfSessionJoin = 120000;

const TimeoutOfSessionLostOverlay = 120000;
const TimeoutOfShowHostDisconnectedNotification = 2500;
const TimeoutOfPlayerConnectedWithoutHost = 60 * 60 * 1000;

export const PlayerClientProvider = observer(
  ({ params, children }: { params: PlayerProps; children: React.ReactNode }) => {
    const t = useTranslate();

    const client = useRef<PlayerPlayP2pClient | undefined>(undefined);

    const { navigate, replaceUrl } = useNavigation();

    const minimumDelayOfPreLobby = useSleep(MinimumDelayOfPreLobby);
    const timeoutOfSessionJoin = useSleep(TimeoutOfSessionJoin);

    const [showSessionLostOverlay, setShowSessionLostOverlay] = useState(false);

    const [connecting, setConnecting] = useState(false);

    const initialSlideFetch = useFetchInitialSlide(params.geniallyId);

    const isReady = useCallback(
      (
        clientToAssert: PlayerPlayP2pClient | undefined,
      ): clientToAssert is PlayerPlayP2pClient => {
        return (
          !connecting &&
          !!clientToAssert &&
          !!initialSlideFetch.data &&
          !minimumDelayOfPreLobby.running
        );
      },
      [connecting, initialSlideFetch.data, minimumDelayOfPreLobby.running],
    );

    useEffect(() => {
      if (connecting) return;
      if (!initialSlideFetch.data) return;
      if (client.current) return;

      const { geniallyId, sessionId, username } = params;

      if (!username) {
        navigate({
          to: RouteNames.PlayerUsernameSelection,
          params: {
            geniallyId,
            sessionId,
          },
        });

        return;
      }

      const newClient = createPlayerPlayP2pClient({
        geniallyId,
        username,
        sessionId,
        initialSlide: initialSlideFetch.data,
      });

      const allowToJoin = () => {
        setConnecting(false);
        timeoutOfSessionJoin.stop();

        newClient.off(PlayerPlayP2pClientEventType.AllowPlayerToJoin, allowToJoin);
      };

      newClient.on(PlayerPlayP2pClientEventType.AllowPlayerToJoin, allowToJoin);

      const onGameFinished = () => {
        setConnecting(false);
        timeoutOfSessionJoin.stop();

        newClient.off(PlayerPlayP2pClientEventType.GameFinished, onGameFinished);
      };

      newClient.on(PlayerPlayP2pClientEventType.GameFinished, onGameFinished);

      newClient.on(PlayerPlayP2pClientEventType.Kicked, () => {
        replaceUrl({
          to: RouteNames.PlayerKicked,
        });
      });

      newClient.on(PlayerPlayP2pClientEventType.InvalidUsername, () => {
        replaceUrl({
          to: RouteNames.PlayerUsernameSelection,
          params: {
            geniallyId,
            sessionId,
          },
        });
      });

      newClient.on(PlayerPlayP2pClientEventType.NoGameFound, () => {
        NotificationManager.error(t('__new.play.accessError.notification'));

        navigate({
          to: RouteNames.PlayerSessionFinder,
        });
      });

      newClient.on(PlayerPlayP2pClientEventType.SessionBlocked, () => {
        navigate({
          to: RouteNames.PlayerSessionBlocked,
          params: {
            username: newClient.username,
            geniallyId: newClient.geniallyId,
            sessionId: newClient.sessionId,
          },
        });
      });

      newClient.on(PlayerPlayP2pClientEventType.PlayersLimitReached, () => {
        navigate({
          to: RouteNames.PlayersLimitReached,
        });
      });

      newClient.on(PlayP2pClientEventType.Error, code => {
        if (code === PlayErrorCodes.UsernameInUse) {
          newClient.dispose();

          navigate({
            to: RouteNames.PlayerUsernameInUse,
            params: {
              username: newClient.username,
              geniallyId: newClient.geniallyId,
              sessionId: newClient.sessionId,
            },
          });
        }
      });

      setConnecting(true);
      client.current = newClient;

      newClient.start();
    }, [
      connecting,
      initialSlideFetch.data,
      params,
      timeoutOfSessionJoin,
      navigate,
      t,
      replaceUrl,
    ]);

    const isGameFinished = !!client.current?.game?.isFinished;
    const isConnected = !!client.current?.isConnectedToHost;

    useEffect(() => {
      if (!isConnected && !isGameFinished) {
        let hostDisconnectedNotification:
          | ReturnType<typeof NotificationManager.error>
          | undefined;

        const removeNotification = () => {
          if (hostDisconnectedNotification) {
            NotificationManager.remove(hostDisconnectedNotification);
          }
        };

        // Prevent micro-disconnections from showing the notification
        const showHostDisconnectedNotificationTimeout = window.setTimeout(() => {
          hostDisconnectedNotification = NotificationManager.error(
            t('__new.play.internetError.notification'),
            0,
          );
        }, TimeoutOfShowHostDisconnectedNotification);

        const showSessionLostOverlayTimeout = window.setTimeout(() => {
          setShowSessionLostOverlay(true);
          removeNotification();
        }, TimeoutOfSessionLostOverlay);

        const timeoutIdOfPlayerConnectedWithoutHost = window.setTimeout(() => {
          client.current?.dispose();
        }, TimeoutOfPlayerConnectedWithoutHost);

        return () => {
          clearTimeout(showHostDisconnectedNotificationTimeout);
          clearTimeout(showSessionLostOverlayTimeout);
          clearTimeout(timeoutIdOfPlayerConnectedWithoutHost);

          setShowSessionLostOverlay(false);

          removeNotification();
        };
      }
    }, [t, isConnected, isGameFinished]);

    useEffect(() => {
      return () => {
        if (client.current) {
          client.current.dispose();
        }
      };
    }, []);

    useEffect(() => {
      if (timeoutOfSessionJoin.ended) {
        navigate({ to: RouteNames.UnknownError });
      }
    }, [navigate, timeoutOfSessionJoin]);

    useEffect(() => {
      return () => {
        Ping.reset();
      };
    }, []);

    useEffect(() => {
      if (client.current?.pingTime) {
        Ping.value = client.current.pingTime;
      }
    }, [client.current?.pingTime]);

    if (!isReady(client.current)) {
      return <PlayerPreLobby />;
    }

    const contextValue: PlayerClientContextType = {
      client: client.current,
    };

    return (
      <PlayerClientContext.Provider value={contextValue}>
        <SessionLostOverlay show={showSessionLostOverlay} />
        {children}
      </PlayerClientContext.Provider>
    );
  },
);
