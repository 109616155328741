import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { calculate } from '@genially/utils/build/module';

import { config } from '../../../bootstrap/application/config/config';
import {
  HostRoutePrefix,
  PlayerRoutePrefix,
  RouteNames,
} from '../../../bootstrap/domain/RouteNames';
import { PlayerUsernameSelectionPageErrors } from '../../../player/application/pages/PlayerUsernameSelectionPage/PlayerUsernameSelectionPage.constants';

export type RouteNavigation =
  | {
      to: RouteNames.Host;
      params: {
        teamId: string;
        geniallyId: string;
        sessionId: string;
      };
    }
  | {
      to: RouteNames.HostWithoutSessionId;
      params: {
        teamId: string;
        geniallyId: string;
      };
    }
  | {
      to: RouteNames.PlayerUsernameSelection;
      params: {
        sessionId: string;
        geniallyId: string;
      };
    }
  | {
      to: RouteNames.Player;
      params: {
        sessionId: string;
        geniallyId: string;
        username: string;
      };
      queryParams?: {
        loadTest?: boolean;
      };
    }
  | {
      to: RouteNames.PlayerUsernameInUse;
      params: {
        username: string;
        sessionId: string;
        geniallyId: string;
      };
    }
  | {
      to: RouteNames.PlayerSessionFinder;
    }
  | {
      to: RouteNames.PlayerKicked;
    }
  | {
      to: RouteNames.PlayerSessionBlocked;
      params: {
        username: string;
        sessionId: string;
        geniallyId: string;
      };
    }
  | {
      to: RouteNames.PlayersLimitReached;
    }
  | {
      to: RouteNames.NotFound;
    }
  | {
      to: RouteNames.UnknownError;
    }
  | {
      to: RouteNames.NoGeniallyAccess;
    }
  | {
      to: RouteNames.GeniallyDraft;
      params: {
        geniallyId: string;
      };
    }
  | {
      to: RouteNames.GeniallyWithPassword;
      params: {
        geniallyId: string;
      };
    };

type RouteNavigationWithQueryParams = Extract<RouteNavigation, { queryParams?: any }>;

export const useNavigation = () => {
  const nativeNavigate = useNavigate();

  const buildUrl = useCallback((route: RouteNavigation) => {
    const url = calculate(() => {
      switch (route.to) {
        case RouteNames.Host:
          return `${HostRoutePrefix}/${route.params.teamId}/${route.params.geniallyId}/${route.params.sessionId}`;
        case RouteNames.HostWithoutSessionId:
          return `${HostRoutePrefix}/${route.params.teamId}/${route.params.geniallyId}`;

        case RouteNames.Player:
          return `${PlayerRoutePrefix}/${route.params.geniallyId}/${route.params.sessionId}/${route.params.username}`;
        case RouteNames.PlayerUsernameSelection:
          return `${PlayerRoutePrefix}/${route.params.geniallyId}/${route.params.sessionId}`;
        case RouteNames.PlayerUsernameInUse:
          return `${PlayerRoutePrefix}/${route.params.geniallyId}/${route.params.sessionId}?error=${PlayerUsernameSelectionPageErrors.UsernameInUse}&username=${route.params.username}`;
        case RouteNames.PlayerSessionBlocked:
          return `${PlayerRoutePrefix}/${route.params.geniallyId}/${route.params.sessionId}?error=${PlayerUsernameSelectionPageErrors.SessionBlocked}&username=${route.params.username}`;
        case RouteNames.PlayersLimitReached:
          return `${PlayerRoutePrefix}/players-limit-reached`;
        case RouteNames.PlayerSessionFinder:
          return PlayerRoutePrefix;

        case RouteNames.PlayerKicked:
          return `${PlayerRoutePrefix}/kicked`;

        case RouteNames.NotFound:
          return '/404';

        case RouteNames.UnknownError:
          return '/500';

        case RouteNames.NoGeniallyAccess:
          return '/403';

        case RouteNames.GeniallyDraft:
          return `/403/genially-draft?geniallyId=${route.params.geniallyId}`;

        case RouteNames.GeniallyWithPassword:
          return `/403/genially-with-password?geniallyId=${route.params.geniallyId}`;

        default:
          throw new Error(`Invalid route: ${route}`);
      }
    });

    if ('queryParams' in route && route.queryParams) {
      const filteredUndefinedParams = Object.fromEntries(
        Object.entries(route.queryParams).filter(([, value]) => value !== undefined),
      );

      const queryParamsAsStrings: Record<string, string> = {};

      Object.entries(filteredUndefinedParams).forEach(([key, value]) => {
        queryParamsAsStrings[key] = value.toString();
      });

      const queryParams = new URLSearchParams(queryParamsAsStrings);
      return `${url}?${queryParams.toString()}`;
    }

    return url;
  }, []);

  const getQueryParams = useCallback((route: RouteNavigationWithQueryParams['to']) => {
    const currentQueryParams = new URLSearchParams(window.location.search);

    switch (route) {
      case RouteNames.Player:
        return {
          loadTest: currentQueryParams.get('loadTest') === 'true',
        };
      default:
        return {};
    }
  }, []);

  const navigate = useCallback(
    (route: RouteNavigation) => {
      nativeNavigate(buildUrl(route));
    },
    [buildUrl, nativeNavigate],
  );

  const replaceUrl = useCallback(
    (route: RouteNavigation) => {
      nativeNavigate(buildUrl(route), { replace: true });
    },
    [buildUrl, nativeNavigate],
  );

  return {
    /**
     * Navigates to the given route
     */
    navigate,
    /**
     * Replaces the current URL with the given route. User can't go back to the previous URL
     */
    replaceUrl,
    /**
     * Returns the query params for the given route
     */
    getQueryParams,
    getFullUrl: useCallback(
      (route: RouteNavigation) => {
        return `${config.urls.play}${buildUrl(route)}`;
      },
      [buildUrl],
    ),
  };
};
