import type { PostSortInteractiveQuestionRequestBody } from '@genially/contracts';
import { TrackingInteractiveQuestionType } from '@genially/contracts';
import type {
  AnyInteractiveQuestionItemData,
  SortInteractiveQuestionItemData,
} from '@genially/document-model';

import { usePlayerClientContext } from '../../PlayerClientProvider/hooks/usePlayerClientContext';
import { IQ } from './IQ';

export const Sort = ({
  question,
}: {
  question: AnyInteractiveQuestionItemData<SortInteractiveQuestionItemData>;
}) => {
  const { client } = usePlayerClientContext();

  const handleSubmit = () => {
    const randomAnswersOrder = [...question.Answers]
      .sort(() => Math.random() - 0.5)
      .map(a => a.Id);

    const payload: PostSortInteractiveQuestionRequestBody = {
      interactiveQuestionId: question.Id,
      timestamp: Date.now(),
      version: 1,
      type: TrackingInteractiveQuestionType.Sort,
      answerIds: randomAnswersOrder,
      timeTakenToAnswer: 0,
    };

    client.sendInteractiveQuestionAnswer(payload);
  };

  return <IQ question={question} onSubmit={handleSubmit} />;
};
