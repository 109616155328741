import { PlayAppVersion } from '../../../../../../../../../shared/application/components/PlayAppVersion/PlayAppVersion';
import { Wrapper } from './Header.styled';

export const Header = ({ disablePointerEvents }: { disablePointerEvents?: boolean }) => {
  return (
    <Wrapper $disablePointerEvents={disablePointerEvents}>
      <PlayAppVersion />
    </Wrapper>
  );
};
