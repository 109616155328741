import type { ComponentProps } from 'react';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-polyglot';
import { observer } from 'mobx-react';
import { useTheme } from 'styled-components';

import { List, Tooltip, useBreakpoint, useOutsideClick } from '@genially/design-system';
import type { PlayerData } from '@genially/p2p-lib';
import { UserAliasAvatar } from '@genially/ui/build/components/UserAliasAvatar/UserAliasAvatar';
import { calculate } from '@genially/utils/build/module';

import { remToPx } from '../../../../../../../../../../../packages/document-components/build';
import { useModal } from '../../../../../../../../bootstrap/application/components/app/components/ModalProvider/ModalProvider.context';
import { config } from '../../../../../../../../bootstrap/application/config/config';
import { RouteNames } from '../../../../../../../../bootstrap/domain/RouteNames';
import { LiveLabel } from '../../../../../../../../shared/application/components/LiveLabel/LiveLabel';
import { KickPlayerConfirmModal } from '../../../../../../../../shared/application/components/Lobby/components/PlayersList/components/KickPlayerConfirmModal/KickPlayerConfirmModal';
import { PlayAppVersion } from '../../../../../../../../shared/application/components/PlayAppVersion/PlayAppVersion';
import { QrCodeModal } from '../../../../../../../../shared/application/components/QrCodeModal/QrCodeModal';
import { useNavigation } from '../../../../../../../../shared/application/hooks/useNavigation';
import { useNotificationManager } from '../../../../../../../../shared/application/services/useNotificationManager';
import { splitSessionCodeByDash } from '../../../../../../../../shared/application/utils/splitSessionCodeByDash';
import { useHostClientContext } from '../../../HostClientProvider/hooks/useHostClientContext';
import { HostGameBreakdownSteps } from '../../HostGame.constants';
import { useHostGameContext } from '../../HostGame.context';
import { GameControlButtons } from './components/GameControlButtons/GameControlButtons';
import { KickIcon } from '../../../../../../../../shared/application/components/Lobby/components/PlayersList/PlayersList.styled';
import {
  BlockedText,
  CentralSide,
  CentralSideWrapper,
  LeftSide,
  LiveAndVersionWrapper,
  PlayersCount,
  PlayersCountWrapper,
  PlayersText,
  PlayIconAndLinkWrapper,
  PlayLinkText,
  RightSide,
  SessionCode,
  SessionCodeText,
  StyledGeniallyIcon,
  StyledLockIcon,
  StyledUnlockIcon,
  StyledUserIcon,
  StyledWarningIcon,
  Wrapper,
} from './GameControlHeader.styled';

type ListOffsetFn = ComponentProps<typeof List>['offset'];

export const GameControlHeader = observer(() => {
  const t = useTranslate();

  const theme = useTheme();

  const { client } = useHostClientContext();
  const { sidebarSummary } = useHostGameContext();

  const { isDownwards, isUpwards } = useBreakpoint();

  const { openModal, closeModal } = useModal();

  const { getFullUrl } = useNavigation();

  const { notificationManager } = useNotificationManager();

  const [showList, setShowList] = useState(false);

  const [playerListHovered, setPlayerListHovered] = useState('');

  const { players, geniallyId, sessionId } = client;

  const sessionIdSplitByDash = splitSessionCodeByDash(sessionId);

  const playUrl = new URL(config.urls.play).hostname;

  const sortedPlayers = players.sort((a, b) => a.username.localeCompare(b.username));

  const listRef = useRef<HTMLDivElement | null>(null);

  const isMobile = isDownwards(HostGameBreakdownSteps.forth);

  const cleanList = () => {
    setShowList(false);
    setPlayerListHovered('');
  };

  const forwardedListRef = useOutsideClick(
    useCallback(event => {
      if (
        !listRef.current?.contains(event.target as Node) &&
        !forwardedListRef.current?.contains(event.target as Node)
      ) {
        cleanList();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  const handleMouseEnter = useCallback(() => {
    setShowList(true);
  }, []);

  const handleMouseLeave = useCallback(
    (event: any) => {
      if (
        !listRef.current?.contains(event.relatedTarget as Node) &&
        !forwardedListRef.current?.contains(event.relatedTarget as Node)
      ) {
        cleanList();
      }
    },
    [forwardedListRef],
  );

  const handleShowRanking = () => {
    sidebarSummary.removeSelectedQuestion();
    sidebarSummary.open();
  };

  const handleToggleIsSessionBlocked = () => {
    client.setIsSessionBlocked(!client.game.isSessionBlocked);
  };

  const handleOpenModal = () => {
    if (client.game.isSessionBlocked) {
      return;
    }

    openModal(parentElement => (
      <QrCodeModal
        url={getFullUrl({
          to: RouteNames.PlayerUsernameSelection,
          params: {
            geniallyId,
            sessionId,
          },
        })}
        sessionId={sessionIdSplitByDash}
        geniallyId={geniallyId}
        parentElement={parentElement}
        closeModal={closeModal}
      />
    ));
  };

  const kickPlayer = (player: PlayerData) => () => {
    client.kickPlayer(player.id);

    notificationManager.success(t('__new.play.expelPlayer.notification.success'));
  };

  const handleKickPlayer = (player: PlayerData) => () => {
    cleanList();

    openModal(parentElement => (
      <KickPlayerConfirmModal
        parentElement={parentElement}
        closeModal={closeModal}
        playerUsername={player.username}
        handleKickPlayer={kickPlayer(player)}
      />
    ));
  };

  const listOffset: ListOffsetFn = () => {
    return [remToPx(-1), 0];
  };

  useEffect(() => {
    const listElement = forwardedListRef.current;

    listElement?.addEventListener('mouseenter', handleMouseEnter);
    listElement?.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      listElement?.removeEventListener('mouseenter', handleMouseEnter);
      listElement?.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [forwardedListRef, handleMouseEnter, handleMouseLeave]);

  const CentralSideComponent = calculate(() => {
    if (isMobile) {
      return undefined;
    }

    return (
      <Fragment>
        <CentralSideWrapper
          $isClickable={!client.game.isSessionBlocked}
          onClick={handleOpenModal}
        >
          {isUpwards(HostGameBreakdownSteps.third) ? (
            <PlayIconAndLinkWrapper>
              <StyledGeniallyIcon />
              <PlayLinkText>{playUrl}</PlayLinkText>
            </PlayIconAndLinkWrapper>
          ) : undefined}

          {client.game.isSessionBlocked ? (
            <BlockedText>
              {t('__new.play.navbarController.accessCode.closedTitle')}
            </BlockedText>
          ) : (
            <SessionCodeText>
              {t('__new.play.navbarController.accessCode.openTitle')}{' '}
              <SessionCode as="span">{client.sessionId}</SessionCode>
            </SessionCodeText>
          )}
        </CentralSideWrapper>
        {client.game.isSessionBlocked ? (
          <Tooltip text={t('__new.play.navbarController.accessCodeTitle.openTooltip')}>
            <StyledLockIcon onClick={handleToggleIsSessionBlocked} />
          </Tooltip>
        ) : (
          <Tooltip text={t('__new.play.navbarController.accessCodeTitle.closeTooltip')}>
            <StyledUnlockIcon onClick={handleToggleIsSessionBlocked} />
          </Tooltip>
        )}
      </Fragment>
    );
  });

  return (
    <Wrapper>
      <LeftSide>
        <LiveAndVersionWrapper>
          <LiveLabel />
          <PlayAppVersion />
        </LiveAndVersionWrapper>
        <PlayersCountWrapper
          ref={listRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <StyledUserIcon />
          <PlayersCount $showWarning={client.isPlayersLimitReached}>
            {players.length}{' '}
            <PlayersText as="span" $showWarning={client.isPlayersLimitReached}>
              {t('__new.play.creatorLobby.participants')}
            </PlayersText>
          </PlayersCount>
          {client.isPlayersLimitReached && <StyledWarningIcon />}
        </PlayersCountWrapper>
      </LeftSide>
      <CentralSide>{CentralSideComponent}</CentralSide>
      <RightSide>
        <GameControlButtons
          showCollapsedMenu={isDownwards(HostGameBreakdownSteps.second)}
          onShowRanking={handleShowRanking}
        />
      </RightSide>

      <List
        show={showList}
        size={List.Size.SMALL}
        forwardedRef={forwardedListRef}
        width={theme.size.units(30)}
        referenceElement={listRef}
        placement={List.Placement.BOTTOM_START}
        visibleItems={6}
        variant={List.Variant.DARK}
        offset={listOffset}
      >
        {sortedPlayers.map(player => {
          const handleHoverPlayer = () => {
            setPlayerListHovered(player.id);
          };

          const isHovered = playerListHovered === player.id;

          const KickIconButton = (
            <Tooltip text={t('__new.play.expelPlayer.tooltip')}>
              <KickIcon />
            </Tooltip>
          );

          return (
            <List.Item
              key={player.id}
              text={player.username}
              size={List.Item.Size.MEDIUM}
              LeftComponent={
                <UserAliasAvatar
                  name={player.username}
                  size={UserAliasAvatar.Size.Small}
                />
              }
              RightComponent={isHovered || isMobile ? KickIconButton : undefined}
              onMouseEnter={handleHoverPlayer}
              isHovered={isHovered}
              onItemAction={handleKickPlayer(player)}
            />
          );
        })}
      </List>
    </Wrapper>
  );
});
