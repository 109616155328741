import styled from 'styled-components';

import { SmallTitle, style } from '@genially/design-system';
import { CopyIcon } from '@genially/icons';

import { ResponsiveText } from '../../../../../../../../shared/application/components/ResponsiveText/ResponsiveText';
import { HostLobbyBreakdownSteps } from '../../HostLobby.constants';

export const StyledSmallTitle = styled(SmallTitle)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));

export const StyledResponsiveTitle = styled(ResponsiveText)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));

export const StyledResponsiveSessionId = styled(ResponsiveText)(
  ({ theme: { color } }) => ({
    color: color.content.reversed.default(),
  }),
);

export const TitleWrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: size.units(2),

  ...style.mixins.responsive.custom(`${HostLobbyBreakdownSteps.second}px`).downward({
    gap: size.units(0.5),
  }),
}));

export const Wrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  gap: size.units(4),
  height: 'max-content',
  width: 'max-content',

  ...style.mixins.responsive.custom(`${HostLobbyBreakdownSteps.second}px`).downward({
    gap: size.units(1.5),
  }),
}));

export const SessionCodeWrapper = styled.div(({ theme: { size, transition } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: size.units(2),
  cursor: 'pointer',
  transition: transition.standard(),

  ':hover': {
    transform: 'scale(0.95)',
  },
}));

export const StyledCopyIcon = styled(CopyIcon)(({ theme: { color, size } }) => ({
  color: color.content.reversed.default(),

  ...style.mixins.responsive.custom(`${HostLobbyBreakdownSteps.second}px`).downward({
    width: size.units(2),
    height: size.units(2),
  }),
}));
