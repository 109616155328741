import type {
  GetAllOpenQuestionAnswersResponse,
  GetSurveyResultResponse,
  GetUserQuestionAnswersRankingResponse,
  PostOpenInteractiveQuestionAnswerRequestBody,
  PostScoreableInteractiveQuestionAnswerRequestBody,
  PostSurveyInteractiveQuestionRequestBody,
  TrackingDataCommonResponse,
} from '@genially/contracts';
import { TrackingInteractiveQuestionType } from '@genially/contracts';
import type { GameStateModel, InteractiveQuestionStateModel } from '@genially/p2p-client';
import type { InteractiveQuestionState, PlayerUserAnswer } from '@genially/p2p-lib';
import { UserTrackingData } from '@genially/ui/build/hooks/useFetchUserTrackingData/models/UserTrackingData';
import { processActivitiesDone } from '@genially/ui/build/utils/analytics/processActivitiesDone';
import { processHits } from '@genially/ui/build/utils/analytics/processHits';
import {
  processAttempts,
  processQuestions,
} from '@genially/ui/build/utils/analytics/processQuestions';

import { dataFormater } from '../../application/utils/dataFormater';
import { ViewApiService } from '../ViewApiService';

const areOpenAnswers = (
  answer: InteractiveQuestionState['playerUserAnswers'][number],
): answer is InteractiveQuestionState<
  PostOpenInteractiveQuestionAnswerRequestBody & { isHidden?: boolean }
>['playerUserAnswers'][number] => {
  return answer.answer.type === TrackingInteractiveQuestionType.OpenAnswer;
};

const areScoreableAnswers = (
  answer: InteractiveQuestionState['playerUserAnswers'][number],
): answer is InteractiveQuestionState<PostScoreableInteractiveQuestionAnswerRequestBody>['playerUserAnswers'][number] => {
  return [
    TrackingInteractiveQuestionType.Quiz,
    TrackingInteractiveQuestionType.TrueFalse,
    TrackingInteractiveQuestionType.Image,
    TrackingInteractiveQuestionType.Sort,
  ].includes(answer.answer.type);
};

const areSurveyAnswers = (
  answer: InteractiveQuestionState['playerUserAnswers'][number],
): answer is InteractiveQuestionState<PostSurveyInteractiveQuestionRequestBody>['playerUserAnswers'][number] => {
  return [TrackingInteractiveQuestionType.Survey].includes(answer.answer.type);
};

export class PlayerAnswersInterpreter {
  private state: Map<string, InteractiveQuestionState>;

  private geniallyId: string;

  private constructor(geniallyId: string, state: Map<string, InteractiveQuestionState>) {
    this.geniallyId = geniallyId;
    this.state = state;
  }

  static from(geniallyId: string, game: GameStateModel) {
    return new PlayerAnswersInterpreter(
      geniallyId,
      new Map(Object.entries(game.asJS.interactiveQuestionAnswers)),
    );
  }

  interpretOpenAnswers(interactiveQuestionId: string): GetAllOpenQuestionAnswersResponse {
    const interactiveQuestionState = this.state.get(interactiveQuestionId);

    if (!interactiveQuestionState) {
      return [];
    }

    const processedAnswers = interactiveQuestionState.playerUserAnswers
      .filter(areOpenAnswers as any)
      .filter(({ answer }) => answer.interactiveQuestionId === interactiveQuestionId);

    processedAnswers.sort((a, b) => b.answer.timestamp - a.answer.timestamp);

    return processedAnswers.map(({ player, answer }) => ({
      geniallyUserId: '',
      userAlias: player.username,
      registeredAt: new Date(answer.timestamp).toISOString(),
      answerText: (answer as any).answerText,
      isHidden: answer.isHidden,
    }));
  }

  interpretSurvey(interactiveQuestionId: string): GetSurveyResultResponse {
    const interactiveQuestionState = this.state.get(interactiveQuestionId);

    if (!interactiveQuestionState) {
      return [];
    }

    const processedAnswers = interactiveQuestionState.playerUserAnswers
      .filter(areSurveyAnswers as any)
      .filter(({ answer }) => answer.interactiveQuestionId === interactiveQuestionId);

    const answers = processedAnswers.flatMap(i => (i.answer as any).answerIds);
    const totalAnswersPerAnswerId: { [key: string]: number } = {};

    answers.forEach(answerId => {
      if (totalAnswersPerAnswerId[answerId] !== undefined) {
        totalAnswersPerAnswerId[answerId] += 1;
      } else {
        totalAnswersPerAnswerId[answerId] = 1;
      }
    });

    return Object.entries(totalAnswersPerAnswerId).map(([answerId, totalAnswers]) => ({
      geniallyId: this.geniallyId,
      interactiveQuestionId,
      answerId,
      totalAnswers,
    }));
  }

  interpretRanking(): GetUserQuestionAnswersRankingResponse {
    const allScoreableAnswers = Array.from(this.state.values())
      .flatMap(i => i.playerUserAnswers)
      .filter(areScoreableAnswers);

    const uniqueScoreableAnswersPerUsername: Record<
      string,
      PlayerUserAnswer<PostScoreableInteractiveQuestionAnswerRequestBody>[]
    > = {};

    for (const answer of allScoreableAnswers) {
      const {
        player: { username },
        answer: answerData,
      } = answer;

      if (uniqueScoreableAnswersPerUsername[username] === undefined) {
        uniqueScoreableAnswersPerUsername[username] = [];
      }

      const alreadyAnswered = uniqueScoreableAnswersPerUsername[username].find(
        i => i.answer.interactiveQuestionId === answerData.interactiveQuestionId,
      )?.answer;

      // Get answer with the biggest time taken to answer
      if (
        alreadyAnswered === undefined ||
        alreadyAnswered.timestamp < answerData.timestamp
      ) {
        uniqueScoreableAnswersPerUsername[username].push(answer);
      }
    }

    const rankingData = Object.entries(uniqueScoreableAnswersPerUsername).map(
      ([username, answers]) => ({
        username,
        timeTakenToAnswer: answers.reduce(
          (acc, i) => acc + i.answer.timeTakenToAnswer,
          0,
        ),
        correctAnswers: answers.filter(i => i.isCorrect).length,
        wrongAnswers: answers.filter(i => !i.isCorrect).length,
        timestamp: Math.max(...answers.map(i => i.answer.timestamp)),
      }),
    );

    // The order of what thing is more important to the ranking is
    // right answers > wrong answers > time taken to answer > last answer registered at

    const sortedRankingData = rankingData.sort((a, b) => {
      if (a.correctAnswers !== b.correctAnswers) {
        return b.correctAnswers - a.correctAnswers;
      }

      if (a.wrongAnswers !== b.wrongAnswers) {
        return b.wrongAnswers - a.wrongAnswers;
      }

      if (a.timeTakenToAnswer !== b.timeTakenToAnswer) {
        return a.timeTakenToAnswer - b.timeTakenToAnswer;
      }

      return a.timestamp - b.timestamp;
    });

    const response: GetUserQuestionAnswersRankingResponse = sortedRankingData.map(
      rankingItem => {
        return {
          userAlias: rankingItem.username,
          geniallyUserId: '',
          rightAnswers: rankingItem.correctAnswers,
          wrongAnswers: rankingItem.wrongAnswers,
          timeTakenToAnswerSum: rankingItem.timeTakenToAnswer,
          lastAnswerRegisteredAt: new Date(rankingItem.timestamp).toISOString(),
        };
      },
    );

    return response;
  }

  async interpretUserTrackingData(): Promise<UserTrackingData[]> {
    const geniallyData = await ViewApiService.getViewData(this.geniallyId);
    const geniallyContent = dataFormater(geniallyData);

    const answersPerUser: Map<string, TrackingDataCommonResponse> = new Map();

    Array.from(this.state.values())
      .flatMap(i => i.playerUserAnswers)
      .forEach(i => {
        const trackingResponse = answersPerUser.get(i.player.username);

        if (trackingResponse) {
          trackingResponse.answers.push(i.answer);
        } else {
          const data = {
            answers: [i.answer],
            userAlias: i.player.username,
            firstSessionCreatedAt: 0,
            numberOfSessions: 1,
            geniallyId: '',
            visitedSlides: [],
          };

          answersPerUser.set(i.player.username, data);
        }
      });

    const trackingCommonResponse = Array.from(answersPerUser.values());

    const response = trackingCommonResponse.map(userData => {
      const questions = processQuestions(userData, geniallyContent);
      const questionsAttempts = processAttempts(userData, geniallyContent);
      const activitiesDone = processActivitiesDone(userData, geniallyContent);
      const hits = processHits(questions);

      return UserTrackingData.of({
        userAlias: userData.userAlias,
        questions,
        questionsAttempts,
        activitiesDone,
        hits,
        totalAccess: 0,
        sessionDuration: 0,
        visitedSlidesAmountOverTotalSlides: '',
        firstAccessDate: new Date(),
      });
    });

    return response;
  }
}
