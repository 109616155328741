import styled from 'styled-components';

export const PodiumAnimationWrapper = styled.div({});

export const Wrapper = styled.div({
  position: 'relative',
  height: '100%',

  [PodiumAnimationWrapper]: {
    position: 'absolute',
    width: '100vw',
    height: '100%',
    right: 0,
    top: 0,
    zIndex: 3,
  },
});
