import { useCallback, useEffect, useRef, useState } from 'react';
import type { HowlOptions } from 'howler';
import { Howl } from 'howler';

import type {
  HostPlayPostMessageConnectionViewApi,
  PlayerPlayPostMessageConnectionViewApi,
} from '@genially/contracts';
import type { PenpalAsyncMethodReturns } from '@genially/ui';

type SpriteMap = {
  [key: string]: [number, number];
};

type UsePlayAudioParams = {
  url: string;
  sprites?: SpriteMap;
  maxDuration?: number;
  connection?:
    | PenpalAsyncMethodReturns<HostPlayPostMessageConnectionViewApi>
    | PenpalAsyncMethodReturns<PlayerPlayPostMessageConnectionViewApi>;
};

const FADE_OUT_DURATION = 1000;

export const usePlayAudio = ({
  url,
  sprites,
  maxDuration,
  connection,
}: UsePlayAudioParams) => {
  const soundRef = useRef<Howl | null>(null);

  if (!soundRef.current) {
    const options: HowlOptions = {
      src: [url],
      sprite: sprites || undefined,
    };
    soundRef.current = new Howl(options);
  }

  const playAudio = useCallback(
    async (spriteKey?: string) => {
      const isAudioMuted = await connection?.isMuted();

      if (!soundRef.current || isAudioMuted) return;

      const soundId =
        spriteKey && sprites && sprites[spriteKey]
          ? soundRef.current.play(spriteKey)
          : soundRef.current.play();

      if (maxDuration) {
        setTimeout(() => {
          if (soundRef.current) {
            soundRef.current.fade(1, 0, FADE_OUT_DURATION, soundId);

            setTimeout(() => {
              soundRef.current?.stop(soundId);
            }, FADE_OUT_DURATION);
          }
        }, maxDuration - FADE_OUT_DURATION);
      }
    },
    [connection, maxDuration, sprites],
  );

  return playAudio;
};
