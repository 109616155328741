import { useEffect, useMemo, useState } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import screenfull from 'screenfull';

import type {
  PlayerPlayPostMessageConnectionPlayApi,
  PlayerPlayPostMessageConnectionViewApi,
} from '@genially/contracts';
import type { InteractiveQuestionAnswerPayload } from '@genially/p2p-lib';
import { PlayUserType } from '@genially/p2p-lib';
import type { PenpalAsyncMethodReturns } from '@genially/ui';

import { GeniallyView } from '../../../../../../../shared/application/components/GeniallyView/GeniallyView';
import { useFullScreen } from '../../../../../../../shared/application/hooks/useFullScreen';
import { usePlayerClientContext } from '../../PlayerClientProvider/hooks/usePlayerClientContext';
import { usePlayerGameContext } from '../../PlayerGame/PlayerGame.context';

interface PlayerGeniallyViewProps {
  showGeniallyAsFullHeight?: boolean;
}

export const PlayerGeniallyView = observer(
  ({ showGeniallyAsFullHeight }: PlayerGeniallyViewProps) => {
    const { client } = usePlayerClientContext();
    const { viewConnection, sidebarSummary } = usePlayerGameContext();

    const { toggleFullScreen, isAvailable } = useFullScreen({
      isConnected: viewConnection.isConnected,
      setIsFullScreen: viewConnection.connection?.setIsFullScreen,
    });

    const [currentSlideId, setCurrentSlideId] = useState(client.game.currentSlide);

    const resolvedInteractiveQuestionIds = useMemo(
      () =>
        Object.entries(client.game.interactiveQuestionAnswers)
          .filter(([, value]) => value.isResolved)
          .map(([key]) => key),
      [client.game.interactiveQuestionAnswers],
    );

    const handleInteractiveQuestionAnswered = (
      data: InteractiveQuestionAnswerPayload,
    ) => {
      client.sendInteractiveQuestionAnswer(data);
    };

    const handleScoreableInteractiveQuestionAnswered = async (
      data: InteractiveQuestionAnswerPayload,
      isCorrect: boolean,
    ) => {
      await client.sendScoreableInteractiveQuestionAnswer({ answer: data, isCorrect });
    };

    const methods: PlayerPlayPostMessageConnectionPlayApi = {
      onInteractiveQuestionAnswered: handleInteractiveQuestionAnswered,
      onScoreableInteractiveQuestionAnswered: handleScoreableInteractiveQuestionAnswered,
      getInitialData: () => {
        return {
          isFullScreenAvailable: isAvailable,
          currentSlideId: client.game.currentSlide,
          answers: toJS(
            Object.values(client.game.asJS.interactiveQuestionAnswers)
              .flatMap(a => a.playerUserAnswers)
              .filter(a => a.player.username === client.username)
              .map(a => a.answer),
            { recurseEverything: true },
          ),
          resolvedInteractiveQuestions: resolvedInteractiveQuestionIds,
        };
      },
      toggleFullScreen,
    };

    const handleConnectionChange = (
      conn?: PenpalAsyncMethodReturns<PlayerPlayPostMessageConnectionViewApi>,
    ) => {
      if (conn) {
        viewConnection.setConnection(conn);
      } else {
        viewConnection.setConnection(undefined);
      }
    };

    useEffect(() => {
      if (currentSlideId === client.game.currentSlide) {
        return;
      }

      sidebarSummary.close();
      setCurrentSlideId(client.game.currentSlide);
    }, [client.game.currentSlide, currentSlideId, sidebarSummary]);

    useEffect(() => {
      if (!viewConnection.isConnected) {
        return;
      }

      viewConnection.connection.changeSlide(currentSlideId);
    }, [currentSlideId, viewConnection.connection, viewConnection.isConnected]);

    useEffect(() => {
      if (!viewConnection.isConnected) {
        return;
      }

      viewConnection.connection.setResolvedInteractiveQuestions(
        resolvedInteractiveQuestionIds,
      );
    }, [
      resolvedInteractiveQuestionIds,
      viewConnection.connection,
      viewConnection.isConnected,
    ]);

    return (
      <GeniallyView<
        PlayerPlayPostMessageConnectionPlayApi,
        PlayerPlayPostMessageConnectionViewApi
      >
        geniallyId={client.geniallyId}
        type={PlayUserType.Player}
        methods={methods}
        showGeniallyAsFullHeight={showGeniallyAsFullHeight}
        onConnectionCreated={handleConnectionChange}
        onConnectionDestroyed={handleConnectionChange}
      />
    );
  },
);
