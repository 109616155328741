import styled, { keyframes } from 'styled-components';

import { SmallSubtitle } from '@genially/design-system';
import { LiveIcon } from '@genially/icons';

const live = keyframes({
  '0%, 80%, 100%': {
    transform: 'scale(0.75)',
  },
  '40%': {
    transform: 'scale(1.0)',
  },
});

export const StyledLiveIcon = styled(LiveIcon)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));

export const Wrapper = styled(
  styled.div(({ theme: { size, color } }) => ({
    padding: `${size.units(0.5)} ${size.units(1)}`,
    backgroundColor: color.background.tertiary.default(0.4),
    borderRadius: size.units(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: size.units(0.5),
  })),
)`
  & > ${StyledLiveIcon} {
    animation: ${live} 1.4s infinite ease-in-out;
  }
`;

export const LiveTitle = styled(SmallSubtitle)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
}));
