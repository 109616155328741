import { useTranslate } from 'react-polyglot';

import { Banner } from '@genially/design-system';

export const SessionBlockedNotification = ({ show }: { show: boolean }) => {
  const t = useTranslate();

  return (
    <Banner
      show={show}
      variant={Banner.Variant.DANGER}
      text={t('__new.play.navbarController.playerNotification.closedSession')}
    />
  );
};
