import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';
import { observer } from 'mobx-react';

import { useEffectOnce } from '@genially/ui/build/hooks/useEffectOnce';

import { PlaySpinner } from '../../../../../../shared/application/components/PlaySpinner/PlaySpinner';
import type { GeniallyUser } from '../../../../../../shared/domain/GeniallyUser';
import { AuthApiService } from '../../../../../../shared/infrastructure/AuthApiService';

export interface AuthenticatedUserContextType {
  authenticatedUser: GeniallyUser | undefined;
}

const AuthenticatedUserProviderContext = createContext({});

export interface AuthenticatedUserProps {
  children: ReactNode;
}

const AuthenticatedUserProvider = observer(({ children }: AuthenticatedUserProps) => {
  const [authenticatedUser, setAuthenticatedUser] = useState<GeniallyUser | undefined>(
    undefined,
  );

  const [fetchingUser, setFetchingUser] = useState(true);

  useEffectOnce(() => {
    AuthApiService.getAuthenticatedUser()
      .then(user => {
        setAuthenticatedUser(user);
      })
      .catch(e => {
        console.error('Error fetching authenticated user:', e);
      })
      .finally(() => {
        setFetchingUser(false);
      });
  });

  if (fetchingUser) {
    return <PlaySpinner />;
  }

  const context: AuthenticatedUserContextType = {
    authenticatedUser,
  };

  return (
    <AuthenticatedUserProviderContext.Provider value={context}>
      {children}
    </AuthenticatedUserProviderContext.Provider>
  );
});

const useAuthenticatedUser = () => {
  const context = useContext(
    AuthenticatedUserProviderContext,
  ) as AuthenticatedUserContextType;

  if (Object.keys(context).length === 0) {
    throw new Error(
      'useAuthenticatedUser must be used within a AuthenticatedUserProvider',
    );
  }

  return context;
};

export { AuthenticatedUserProvider, useAuthenticatedUser };
