import { observable, runInAction } from 'mobx';

const ping = observable.box<number | undefined>(undefined);
const pingIsVisible = observable.box<boolean>(false);

export const Ping = {
  get value() {
    return ping.get();
  },
  set value(value: number | undefined) {
    runInAction(() => {
      ping.set(value);
    });
  },
  get isVisible() {
    return pingIsVisible.get();
  },
  set isVisible(value: boolean) {
    runInAction(() => {
      pingIsVisible.set(value);
    });
  },
  reset: () => {
    runInAction(() => {
      ping.set(undefined);
    });
  },
};
