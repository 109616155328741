import styled from 'styled-components';

import {
  BasicParagraph,
  BasicTitle,
  BiggerSubtitle,
  BigParagraph,
  style,
} from '@genially/design-system';
import { InfoFilledIcon, WarningBasicIcon } from '@genially/icons';

export const Wrapper = styled.div(({ theme: { size } }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: `${size.units(3)} ${size.units(2)} ${size.units(12)} ${size.units(2)}`,
  gap: size.units(4),
}));

export const TextWrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  alignItems: 'baseline',
  gap: size.units(2),

  ...style.mixins.responsive.xs.downward({
    gap: size.units(1),
  }),
}));

export const StyledBasicTitle = styled(BasicTitle)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));

export const StyledBiggerSubtitle = styled(BiggerSubtitle)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));

export const StyledInfoFilledIcon = styled(InfoFilledIcon)(
  ({ theme: { color, size } }) => ({
    width: size.units(3),
    height: size.units(3),
    color: color.content.reversed.active(),
    cursor: 'help',

    ...style.mixins.responsive.xs.downward({
      width: size.units(2),
      height: size.units(2),
    }),
  }),
);

export const ErrorInfoWrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  gap: size.units(1),
  alignItems: 'baseline',
}));

export const StyledBigParagraph = styled(BigParagraph)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));

export const StyledBasicParagraph = styled(BasicParagraph)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));

export const StyledWarningBasicIcon = styled(WarningBasicIcon)(
  ({ theme: { color, size } }) => ({
    width: size.units(2),
    height: size.units(2),
    minWidth: size.units(2),
    minHeight: size.units(2),
    color: color.content.error.default(),
  }),
);
