import { v4 as uuid } from 'uuid';

import type { Logger } from '@genially/logger-lib';
import {
  createConsoleLogger,
  createMultiLogger,
  createRemoteLogger,
} from '@genially/logger-lib';
import { config } from '../../bootstrap/application/config/config';

const loggerMetadata: Record<string, unknown> = {
  sessionId: uuid(),
  userAgent: navigator.userAgent,
  env: window.location.host,
  envName: config.appEnv,
};

const createLoggerForApp = (app: string) => {
  const loggers: Logger[] = [createConsoleLogger({ app })];

  if (config.urls.loggerServer !== undefined) {
    loggers.push(createRemoteLogger({ app, remoteHost: config.urls.loggerServer }));
  }

  const multiLogger = createMultiLogger(loggers);
  multiLogger.setDefaultMetadata(loggerMetadata);

  return multiLogger;
};

let logger: Logger | undefined;

export const getLogger = () => {
  if (logger === undefined) {
    logger = createLoggerForApp('@genially/play');
  }

  return logger;
};

export interface LoggerMetadata {
  userId?: string;
  userName?: string;
}

export const setLoggerMetadata = (metadata: LoggerMetadata) => {
  Object.assign(loggerMetadata, metadata);
  logger?.setDefaultMetadata(loggerMetadata);
};
