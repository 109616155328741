import type { PlayPostMessageInteractiveQuestion } from '@genially/contracts';
import { InteractiveQuestionType } from '@genially/document-model';

export const geniallyHasSelfCorrectingQuestions = (
  interactiveQuestions: PlayPostMessageInteractiveQuestion[],
) => {
  return interactiveQuestions.some(item => {
    return (
      'Type' in item &&
      [
        InteractiveQuestionType.Quiz,
        InteractiveQuestionType.TrueFalse,
        InteractiveQuestionType.Image,
        InteractiveQuestionType.Sort,
      ].includes(item.Type as InteractiveQuestionType)
    );
  });
};
