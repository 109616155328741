import { useTranslate } from 'react-polyglot';

import { LiveTitle, StyledLiveIcon, Wrapper } from './LiveLabel.styled';

export const LiveLabel = () => {
  const t = useTranslate();

  return (
    <Wrapper>
      <StyledLiveIcon />
      <LiveTitle>{t('__new.play.liveLogo.title')}</LiveTitle>
    </Wrapper>
  );
};
