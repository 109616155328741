import { useTranslate } from 'react-polyglot';

import {
  BiggerSubtitle,
  BigSubtitle,
  BigTitle,
  SmallTitle,
  Tooltip,
  useBreakpoint,
} from '@genially/design-system';

import { RouteNames } from '../../../../../../../../bootstrap/domain/RouteNames';
import { useNavigation } from '../../../../../../../../shared/application/hooks/useNavigation';
import { useNotificationManager } from '../../../../../../../../shared/application/services/useNotificationManager';
import { shareSessionCode } from '../../../../../../../../shared/application/utils/shareSessionCode';
import { splitSessionCodeByDash } from '../../../../../../../../shared/application/utils/splitSessionCodeByDash';
import { useHostClientContext } from '../../../HostClientProvider/hooks/useHostClientContext';
import { HostGameBreakdownSteps } from '../../../HostGame/HostGame.constants';
import { HostLobbyBreakdownSteps } from '../../HostLobby.constants';
import { QRCode } from '../QRCode/QRCode';
import {
  SessionCodeWrapper,
  StyledCopyIcon,
  StyledResponsiveSessionId,
  StyledResponsiveTitle,
  TitleWrapper,
  Wrapper,
} from './ConnectionInfo.styled';

export const ConnectionInfo = () => {
  const t = useTranslate();
  const { getFullUrl } = useNavigation();
  const { isDownwards } = useBreakpoint();

  const { client } = useHostClientContext();
  const { notificationManager } = useNotificationManager();

  const isMobile = isDownwards(HostGameBreakdownSteps.forth);

  const { geniallyId, sessionId } = client;

  const sessionIdSplitByDash = splitSessionCodeByDash(client.sessionId);

  const handleCopySessionClick = async () => {
    shareSessionCode({
      geniallyId,
      sessionId,
      notificationManager,
      isMobile,
      t,
    });
  };

  return (
    <Wrapper>
      <QRCode
        url={getFullUrl({
          to: RouteNames.PlayerUsernameSelection,
          params: {
            geniallyId,
            sessionId,
          },
        })}
        sessionId={sessionIdSplitByDash}
        geniallyId={geniallyId}
      />
      <TitleWrapper>
        <StyledResponsiveTitle
          with={SmallTitle}
          on={{
            [HostLobbyBreakdownSteps.first]: BigSubtitle,
          }}
        >
          {t('__new.play.creatorLobby.join.title')}
        </StyledResponsiveTitle>

        <Tooltip
          text={t('__new.play.creatorLobby.join.tooltip')}
          variant={Tooltip.Variant.LIGHT}
        >
          <SessionCodeWrapper onClick={handleCopySessionClick}>
            <StyledResponsiveSessionId
              with={BigTitle}
              on={{
                [HostLobbyBreakdownSteps.first]: BiggerSubtitle,
              }}
            >
              {sessionIdSplitByDash}
            </StyledResponsiveSessionId>
            <StyledCopyIcon />
          </SessionCodeWrapper>
        </Tooltip>
      </TitleWrapper>
    </Wrapper>
  );
};
