import { Fragment, useEffect } from 'react';

import { PlayUserType } from '@genially/p2p-lib';

import { useNavigation } from '../../../../../../shared/application/hooks/useNavigation';
import {
  type HostProps,
  useValidateParams,
} from '../../../../../../shared/application/hooks/useValidateParams';
import { RouteNames } from '../../../../../domain/RouteNames';

export const CheckHostParamsGuard = ({
  children,
}: {
  children: (params: HostProps) => React.ReactNode;
}) => {
  const params = useValidateParams<HostProps>(PlayUserType.Host);

  const { navigate } = useNavigation();

  useEffect(() => {
    if (!params) {
      navigate({ to: RouteNames.NotFound });
    }
  }, [navigate, params]);

  if (!params) {
    return null;
  }

  return <Fragment>{children(params)}</Fragment>;
};
