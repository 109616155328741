import { MatchResult, Option } from '../MiniGame/constants';

const wins = {
  [Option.Rock]: [Option.Scissors],
  [Option.Paper]: [Option.Rock],
  [Option.Scissors]: [Option.Paper],
};

export const getMatchResult = (userSelection: Option, botSelection: Option) => {
  if (userSelection === botSelection) {
    return MatchResult.Draw;
  }

  if (wins[userSelection].includes(botSelection)) {
    return MatchResult.Win;
  }

  return MatchResult.Lose;
};
