import { Fragment, useEffect } from 'react';

import { useNavigation } from '../../../../../../shared/application/hooks/useNavigation';
import { RouteNames } from '../../../../../domain/RouteNames';
import { useAuthenticatedUser } from '../../components/AuthenticatedUserProvider/AuthenticatedUserProvider.context';

export const CheckHostGeniallyAccessGuard = ({
  children,
}: {
  children: (params: { geniallyUserId: string }) => React.ReactNode;
}) => {
  const { authenticatedUser } = useAuthenticatedUser();

  const { navigate } = useNavigation();

  useEffect(() => {
    if (!authenticatedUser) {
      navigate({ to: RouteNames.NoGeniallyAccess });
    }
  }, [navigate, authenticatedUser]);

  if (!authenticatedUser) {
    return null;
  }

  return <Fragment>{children({ geniallyUserId: authenticatedUser.id })}</Fragment>;
};
