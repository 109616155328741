import styled from 'styled-components';

export const FloatingButtons = styled.div(({ theme: { size } }) => ({
  position: 'absolute',
  left: size.units(2),
  bottom: size.units(2),

  display: 'flex',
  gap: size.units(2),
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const FloatingButton = styled.div(({ theme: { size, color, transition } }) => ({
  borderRadius: '50%',
  width: size.units(6),
  height: size.units(6),

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  border: `2px solid ${color.background.reversed.default()}`,
  backgroundColor: color.background.warning.default(),

  cursor: 'pointer',

  transition: transition.standard(),

  '&:hover': {
    width: size.units(7),
    height: size.units(7),
  },

  '> *': {
    color: color.content.primary.default(),
  },

  svg: {
    width: size.units(3.5),
    height: size.units(3.5),
  },
}));
