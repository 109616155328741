import type { ReactNode } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useTranslate } from 'react-polyglot';

import { NotificationManager } from '@genially/design-system';
import { PlayP2pRestClient } from '@genially/p2p-client';
import { PlayUserType } from '@genially/p2p-lib';

import { PlaySpinner } from '../../../../../../shared/application/components/PlaySpinner/PlaySpinner';
import { useNavigation } from '../../../../../../shared/application/hooks/useNavigation';
import type { PlayerProps } from '../../../../../../shared/application/hooks/useValidateParams';
import { useValidateParams } from '../../../../../../shared/application/hooks/useValidateParams';
import { RouteNames } from '../../../../../domain/RouteNames';

export const CheckSessionIdGuard = ({
  children,
}: {
  children: (params: {
    geniallyId: string;
    sessionId: string;
    username?: string;
  }) => ReactNode;
}) => {
  const t = useTranslate();

  const { navigate } = useNavigation();

  const [checkingSessionId, setCheckingSessionId] = useState(true);

  const params = useValidateParams<PlayerProps>(PlayUserType.Player);

  useEffect(() => {
    if (!params) {
      navigate({
        to: RouteNames.PlayerSessionFinder,
      });
    }
  }, [navigate, params]);

  useEffect(() => {
    if (!params?.geniallyId || !params?.sessionId) {
      return;
    }

    const { geniallyId, sessionId } = params;

    setCheckingSessionId(true);
    PlayP2pRestClient.discoverGeniallyId({ sessionId }).then(
      ({ geniallyId: discoveredGeniallyId }) => {
        if (!discoveredGeniallyId || discoveredGeniallyId !== geniallyId) {
          NotificationManager.warning(t('__new.play.accessError.notification'));

          navigate({
            to: RouteNames.PlayerSessionFinder,
          });

          return;
        }

        setCheckingSessionId(false);
      },
    );
  }, [navigate, params, t]);

  if (checkingSessionId) {
    return <PlaySpinner />;
  }

  if (!params?.geniallyId || !params?.sessionId) {
    return null;
  }

  const { geniallyId, sessionId, username } = params;

  return <Fragment>{children({ geniallyId, sessionId, username })}</Fragment>;
};
