import { useEffect, useState } from 'react';

import type {
  HostPlayPostMessageConnectionViewApi,
  PlayerPlayPostMessageConnectionViewApi,
} from '@genially/contracts';
import type { PenpalAsyncMethodReturns } from '@genially/ui';
import { calculate } from '@genially/utils';

import { usePlayAudio } from '../../../../hooks/usePlayAudio';
import { DrumsAnimation } from './components/DrumsAnimation/DrumsAnimation';
import { FlagAnimation } from './components/FlagAnimation/FlagAnimation';
import { PositionAnimation } from './components/PositionAnimation/PositionAnimation';
import { APPLAUSE_CHEERING_AUDIO, FlagSpeed } from './constants';
import { Wrapper } from './PodiumAnimation.styled';

enum AnimationSecuence {
  Flag,
  Participant3,
  Participant2,
  Drums,
  Participant1,
}

interface PodiumAnimationProps {
  onAnimationEnd: () => void;
  podium: string[];
  flagSpeed?: FlagSpeed;
  connection?:
    | PenpalAsyncMethodReturns<HostPlayPostMessageConnectionViewApi>
    | PenpalAsyncMethodReturns<PlayerPlayPostMessageConnectionViewApi>;
}

export const PodiumAnimation = ({
  podium,
  onAnimationEnd,
  flagSpeed = FlagSpeed.Normal,
  connection,
}: PodiumAnimationProps) => {
  const playAudio = usePlayAudio({ url: APPLAUSE_CHEERING_AUDIO, connection });

  const [animationSecuence, setAnimationSecuence] = useState<AnimationSecuence>(
    AnimationSecuence.Flag,
  );

  const [hide, setHide] = useState(false);

  const handleNextAnimation = (nextAnimation: AnimationSecuence) => () => {
    setAnimationSecuence(nextAnimation);
  };

  const handleFlagAnimationEnd = () => {
    if (podium.length === 3) {
      handleNextAnimation(AnimationSecuence.Participant3)();
    } else if (podium.length === 2) {
      handleNextAnimation(AnimationSecuence.Participant2)();
    } else {
      handleNextAnimation(AnimationSecuence.Drums)();
    }
  };

  const handlLastPodiumAnimationEnd = () => {
    setHide(true);
  };

  const handleAnimationEnd = () => {
    onAnimationEnd();
  };

  useEffect(() => {
    playAudio();
  }, [playAudio]);

  const Component = calculate(() => {
    if (animationSecuence === AnimationSecuence.Flag) {
      return (
        <FlagAnimation
          onAnimationEnd={handleFlagAnimationEnd}
          speed={flagSpeed}
          connection={connection}
        />
      );
    }

    if (animationSecuence === AnimationSecuence.Participant3) {
      return (
        <PositionAnimation
          key="participant-3-animation"
          userAlias={podium[2]}
          position={PositionAnimation.Position.Bronze}
          onAnimationEnd={handleNextAnimation(AnimationSecuence.Participant2)}
          playApplauseAudio
          connection={connection}
        />
      );
    }

    if (animationSecuence === AnimationSecuence.Participant2) {
      return (
        <PositionAnimation
          key="participant-2-animation"
          userAlias={podium[1]}
          position={PositionAnimation.Position.Silver}
          onAnimationEnd={handleNextAnimation(AnimationSecuence.Drums)}
          connection={connection}
        />
      );
    }

    if (animationSecuence === AnimationSecuence.Drums) {
      return (
        <DrumsAnimation
          onAnimationEnd={handleNextAnimation(AnimationSecuence.Participant1)}
          connection={connection}
        />
      );
    }

    if (animationSecuence === AnimationSecuence.Participant1) {
      return (
        <PositionAnimation
          key="participant-1-animation"
          userAlias={podium[0]}
          position={PositionAnimation.Position.Gold}
          size={PositionAnimation.Size.Large}
          enterAnimation={PositionAnimation.EnterAnimation.FadeIn}
          onAnimationEnd={handlLastPodiumAnimationEnd}
          connection={connection}
        />
      );
    }

    return null;
  });

  return (
    <Wrapper $hide={hide} onAnimationEnd={handleAnimationEnd}>
      {Component}
    </Wrapper>
  );
};

PodiumAnimation.FlagSpeed = FlagSpeed;
