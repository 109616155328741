import type { ElementType } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { style } from '@genially/design-system';

import { HostLobbyBreakdownSteps } from '../../HostLobby.constants';

export const Wrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: size.units(3),
}));

const fadeInKeyframes = keyframes({
  from: {
    opacity: 0,
  },

  to: {
    opacity: 1,
  },
});

const withFadeInAnimation = (Component: ElementType) =>
  styled(Component)(
    () => css`
      animation: ${fadeInKeyframes} 0.3s linear;
    `,
  );

export const Footer = withFadeInAnimation(
  styled.div(({ theme: { size } }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: size.units(1.5),

    ...style.mixins.responsive.xs.downward({
      justifyContent: 'center',

      '& button': {
        width: '100%',
      },
    }),
  })),
);
