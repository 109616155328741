import axios from 'axios';

import type { SimpleUserResponse } from '@genially/contracts';
import { decodeError } from '@genially/ui/build/utils/decodeError';

import { config } from '../../bootstrap/application/config/config';
import { GeniallyUser } from '../domain/GeniallyUser';

const client = axios.create({
  baseURL: `${config.urls.auth}/api`,
  withCredentials: true,
});

export const AuthApiService = {
  getAuthenticatedUser: async (): Promise<GeniallyUser | undefined> => {
    try {
      const response = await client.get<SimpleUserResponse>(`/user/simple`, {
        headers: {
          'Cache-Control': 'no-cache',
        },
        params: {
          v: Math.random().toString(36).substring(7),
        },
      });

      return GeniallyUser.fromPrimitives(response.data);
    } catch (e) {
      const error = decodeError(e);

      if (error.isAxiosError) {
        const unauthorizedErrorCodes = [401, 403];

        if (
          error.response.status !== undefined &&
          !unauthorizedErrorCodes.includes(error.response.status)
        ) {
          console.warn(
            'There was an unexpected error fetching the authenticated user:',
            error,
          );
        }
      } else {
        console.error(
          'There was an unexpected error fetching the authenticated user:',
          error,
        );
      }

      return undefined;
    }
  },
};
