import { observer } from 'mobx-react';

import { BaseModal, Button } from '@genially/design-system';
import type { P2pClient } from '@genially/p2p-client';

export const DebugModal = observer(
  ({
    parentElement,
    closeModal,
    client,
  }: {
    parentElement: HTMLElement;
    closeModal: () => void;
    client: P2pClient<any, any, any, any>;
  }) => {
    const windowAsAny = window as any;

    return (
      <BaseModal
        close={closeModal}
        width={BaseModal.Size.LARGE}
        parentElement={parentElement}
      >
        <pre style={{ overflowY: 'auto' }}>
          <code style={{ whiteSpace: 'break-spaces' }}>
            {JSON.stringify(client.debugInfo, null, 4)}
          </code>
        </pre>
        <Button onClick={windowAsAny.sendDebugInfoToServer}>Send info to devs</Button>
      </BaseModal>
    );
  },
);
