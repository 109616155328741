import type { GetUserQuestionAnswersRankingResponse } from '@genially/contracts';
import {
  type AnyInteractiveQuestionItemData,
  InteractiveQuestionType,
} from '@genially/document-model';
import type { HostPlayP2pClient } from '@genially/p2p-client';
import { UserTrackingData } from '@genially/ui/build/hooks/useFetchUserTrackingData/models/UserTrackingData';
import { compressFiles } from '@genially/ui/build/utils/compressFiles';
import { downloadFiles } from '@genially/ui/build/utils/downloadFiles';
import {
  FileType,
  generateBlobFromFile,
} from '@genially/ui/build/utils/generateBlobFromFile';
import { encodeCSVFields } from '@genially/utils/build/module';

import { dataFormater } from '../../../../../../../../../../../shared/application/utils/dataFormater';
import { PlayerAnswersInterpreter } from '../../../../../../../../../../../shared/infrastructure/PlayerAnswerInterpreter/PlayerAnswersInterpreter';
import { ViewApiService } from '../../../../../../../../../../../shared/infrastructure/ViewApiService';

interface UserTrackingDataRanking extends UserTrackingData {
  position: number;
}

const AnswerValueMap = {
  true: 'v',
  false: 'x',
  undefined: '-',
};

const ANSWER_DELIMETER = ' / ';

const formatDateAsYYYYMMDD = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}${month}${day}`;
};

const formatDateAsHHMM = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${hours}h${minutes}`;
};

const buildEmptyDataWithQuestions = (
  activities: AnyInteractiveQuestionItemData[],
): UserTrackingData[] => {
  return [
    UserTrackingData.of({
      activitiesDone: '',
      hits: '',
      userAlias: '',
      questions: activities.map(({ Id, Question, Type }) => {
        return {
          id: Id,
          type: Type,
          question: Question,
          slideId: '',
          userAlias: '',
          answer: undefined,
          answersSelected: undefined,
          isCorrect: undefined,
        };
      }),
      firstAccessDate: new Date(),
      sessionDuration: 0,
      totalAccess: 0,
      visitedSlidesAmountOverTotalSlides: '',
      questionsAttempts: [],
    }),
  ];
};
const getIndividualTrackingData = ({
  data,
  geniallyName,
  t,
  interactiveQuestions,
}: {
  geniallyName: string;
  data: UserTrackingData[];
  t: (key: string) => string;
  interactiveQuestions?: any[];
}) => {
  const isEmpty = Object.keys(data).length === 0;
  const userTrackingData = isEmpty
    ? buildEmptyDataWithQuestions(interactiveQuestions ?? [])
    : data;

  const commonHeader = `${window.location.href}\n${geniallyName}`;
  const newLine = encodeCSVFields(['', '', '', '', '']);

  const buildUserTrackingRows = () => {
    const headers = [t('__new.dashboard.analytics.table.columnUser')];

    const questions = userTrackingData[0].questions ?? [];
    const haveQuestions = questions.length > 0;

    if (haveQuestions) {
      headers.push(
        t('__new.dashboard.analytics.table.columnActivityDone'),
        t('__new.dashboard.analytics.table.columnSuccess'),
      );

      questions.forEach(q => {
        headers.push(q.question);

        if (
          q.type !== InteractiveQuestionType.OpenAnswer &&
          q.type !== InteractiveQuestionType.Survey
        ) {
          headers.push(t('__new.content.csv.answerSent'));
        }
      });
    }

    const header = encodeCSVFields(headers);

    const entries: string[] = [];

    if (!isEmpty) {
      for (const user of userTrackingData as UserTrackingData[]) {
        const values: string[] = [];

        values.push(user.userAlias);

        if (haveQuestions) {
          values.push(user.activitiesDone);
          values.push(user.hits);

          user.questions.forEach(question => {
            if (
              question.type !== InteractiveQuestionType.OpenAnswer &&
              question.type !== InteractiveQuestionType.Survey
            ) {
              values.push(AnswerValueMap[`${question.isCorrect}`]);
            }

            const answerWithText = question.answer?.filter(Boolean);
            values.push(answerWithText ? answerWithText.join(ANSWER_DELIMETER) : '-');
          });
        }

        entries.push(encodeCSVFields(values));
      }
    }

    return `${commonHeader}\n${newLine}\n${newLine}\n${header}\n${entries.join('\n')}`;
  };

  return buildUserTrackingRows();
};

const getRankingData = ({
  data,
  geniallyName,
  t,
  rankingData,
  interactiveQuestions,
}: {
  geniallyName: string;
  data: UserTrackingData[];
  t: (key: string) => string;
  rankingData: GetUserQuestionAnswersRankingResponse | undefined;
  interactiveQuestions?: any[];
}) => {
  const isEmpty = data.length === 0;

  if (!rankingData) {
    return '';
  }

  const userTrackingData = data.length
    ? data
    : buildEmptyDataWithQuestions(interactiveQuestions ?? []);

  const resultData = rankingData.map((user, index) => {
    const userTracking = userTrackingData.find(
      userTrackingItem => userTrackingItem.userAlias === user.userAlias,
    );

    return {
      ...user,
      ...userTracking,
      position: index + 1,
    };
  });

  const commonHeader = `${window.location.href}\n${geniallyName}`;
  const newLine = encodeCSVFields(['', '', '', '', '']);

  const buildUserTrackingRows = (): string => {
    const headers = [
      t('__new.dashboard.analytics.table.columnPosition'),
      t('__new.dashboard.analytics.table.columnUser'),
    ];

    const questions = userTrackingData[0].questions ?? [];
    const haveQuestions = questions.length > 0;

    if (haveQuestions) {
      headers.push(
        t('__new.dashboard.analytics.table.columnActivityDone'),
        t('__new.dashboard.analytics.table.columnSuccess'),
      );
    }

    const header = encodeCSVFields(headers);

    const entries: string[] = [];

    if (!isEmpty) {
      for (const user of resultData as unknown as UserTrackingDataRanking[]) {
        const values: string[] = [];

        values.push(user.position.toString());
        values.push(user.userAlias);

        if (haveQuestions) {
          values.push(user.activitiesDone);
          values.push(user.hits);
        }

        entries.push(encodeCSVFields(values));
      }
    }

    return `${commonHeader}\n${newLine}\n${newLine}\n${header}\n${entries.join('\n')}`;
  };

  return buildUserTrackingRows();
};

export const downloadGameResultsFromClient = async (
  client: HostPlayP2pClient,
  t: (key: string) => string,
) => {
  const filesToDownload = [];
  const geniallyName = await ViewApiService.getGeniallyName(client.geniallyId);
  const geniallyData = await ViewApiService.getViewData(client.geniallyId);
  const geniallyContent = dataFormater(geniallyData);

  const playerAnswersInterpreter = PlayerAnswersInterpreter.from(
    client.geniallyId,
    client.game,
  );

  const userTrackingData = await playerAnswersInterpreter.interpretUserTrackingData();
  const rankingData = await playerAnswersInterpreter.interpretRanking();

  const csvFormat = getIndividualTrackingData({
    geniallyName,
    data: userTrackingData,
    t,
    interactiveQuestions: geniallyContent.activities,
  });

  const userTrackingDataFile = {
    name: `${geniallyName}_individual.csv`,
    blob: generateBlobFromFile(csvFormat, FileType.CSV),
  };

  filesToDownload.push(userTrackingDataFile);

  const rankingCsvData = getRankingData({
    geniallyName,
    data: userTrackingData,
    t,
    rankingData,
    interactiveQuestions: geniallyContent.activities,
  });

  const rankingFile = {
    name: `${geniallyName}_ranking.csv`,
    blob: generateBlobFromFile(rankingCsvData, FileType.CSV),
  };

  filesToDownload.push(rankingFile);

  await downloadFiles(
    compressFiles(filesToDownload),
    `${geniallyName}_play_${formatDateAsYYYYMMDD(new Date())}_${formatDateAsHHMM(new Date())}.zip`,
  );
};
