import styled from 'styled-components';

import { BigTitle } from '@genially/design-system';

export const Wrapper = styled.div(({ theme: { size } }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100dvh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  textAlign: 'center',

  gap: size.units(3.5),

  padding: `0 ${size.units(3)}`,

  zIndex: 1,
}));

export const Title = styled(BigTitle)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));
