import { useEffect } from 'react';
import { observer } from 'mobx-react';

import { LogRocketService } from '../../../../../../shared/infrastructure/logrocket/LogRocketService';
import { PlayerGeniallyView } from '../shared/PlayerGeniallyView/PlayerGeniallyView';
import { PlayerPlaySidebarSummary } from './components/PlayerPlaySidebarSummary/PlayerPlaySidebarSummary';
import { PlayerGameProvider } from './PlayerGame.context';
import { Wrapper } from './PlayerGame.styled';

export const PlayerGame = observer(() => {
  useEffect(() => {
    LogRocketService.trackWithSampling('play_participant', 10);
  }, []);

  return (
    <Wrapper>
      <PlayerGameProvider>
        <PlayerGeniallyView />
        <PlayerPlaySidebarSummary />
      </PlayerGameProvider>
    </Wrapper>
  );
});
