import { useSearchParams } from 'react-router-dom';

export const useRedirectionFromLobby = () => {
  const [searchParams] = useSearchParams();

  const error = searchParams.get('error') ?? undefined;
  const username = searchParams.get('username') ?? undefined;

  return {
    error,
    username,
  };
};
