import { useTranslate } from 'react-polyglot';

import Loader from './assets/loader.mp4';
import { LoaderVideo, Title, Wrapper } from './HostPreLobby.styled';

export const HostPreLobby = () => {
  const t = useTranslate();

  return (
    <Wrapper>
      <LoaderVideo autoPlay loop muted>
        <source data-cy="prelobby-video" src={Loader as string} type="video/mp4" />
        Your browser does not support the video tag.
      </LoaderVideo>
      <Title>{t('__new.play.creatingRoom.title')}</Title>
    </Wrapper>
  );
};
