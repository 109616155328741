import type { MouseEvent } from 'react';
import { useTranslate } from 'react-polyglot';

import { Button, WarningModal } from '@genially/design-system';

export const KickPlayerConfirmModal = ({
  parentElement,
  closeModal,
  playerUsername,
  handleKickPlayer,
}: {
  parentElement: HTMLElement;
  closeModal: () => void;
  playerUsername: string;
  handleKickPlayer: () => void;
}) => {
  const t = useTranslate();

  const handleKickPlayerClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    handleKickPlayer();
    closeModal();
  };

  const handleCancelClick = () => {
    closeModal();
  };

  return (
    <WarningModal
      parentElement={parentElement}
      title={t('__new.play.expelPlayer.title')}
      description={t('__new.play.expelPlayer.description', { username: playerUsername })}
      withoutImage
      close={() => closeModal()}
      PrimaryButton={
        <Button variant={Button.Variant.DANGER} onClick={handleKickPlayerClick}>
          {t('__new.play.expelPlayer.button.expel')}
        </Button>
      }
      SecondaryButton={
        <Button variant={Button.Variant.PLAIN} onClick={handleCancelClick}>
          {t('__new.play.expelPlayer.button.cancel')}
        </Button>
      }
    />
  );
};
