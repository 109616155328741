import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import type { HostPlayP2pClient } from '@genially/p2p-client';
import {
  HostPlayP2pClientEventType,
  P2pClientEventType,
  PlayP2pClientEventType,
} from '@genially/p2p-client';
import { PlayErrorCodes } from '@genially/p2p-lib';
import { AppEnv } from '@genially/ui/build/config/appEnv';
import { useFeatureFlags } from '@genially/ui/build/hooks/useFeatureFlags';

import { config } from '../../../../../../bootstrap/application/config/config';
import { RouteNames } from '../../../../../../bootstrap/domain/RouteNames';
import { Ping } from '../../../../../../shared/application/components/PlayAppVersion/Ping';
import { useFetchInitialSlide } from '../../../../../../shared/application/hooks/useFetchInitialSlide';
import { useNavigation } from '../../../../../../shared/application/hooks/useNavigation';
import { useSleep } from '../../../../../../shared/application/hooks/useSleep';
import { HostPreLobby } from './components/HostPreLobby/HostPreLobby';
import { createHostPlayP2pClient } from './utils/createHostPlayP2pClient';

export type HostClientContextType = {
  client: HostPlayP2pClient;
};

export const HostClientContext = createContext({});

export const MinimumDelayOfPreLobby = 2000;
export const TimeoutOfSessionCreation = 120000;

export const HostClientProvider = observer(
  ({
    params,
    children,
  }: {
    params: {
      teamId: string;
      geniallyId: string;
      sessionId?: string;
      geniallyUserId: string;
    };
    children: React.ReactNode;
  }) => {
    const client = useRef<HostPlayP2pClient | undefined>(undefined);

    const { navigate, replaceUrl } = useNavigation();

    const minimumDelayOfPreLobby = useSleep(MinimumDelayOfPreLobby);
    const timeoutOfSessionCreation = useSleep(TimeoutOfSessionCreation);

    const [connecting, setConnecting] = useState(false);

    const initialSlideFetch = useFetchInitialSlide(params.geniallyId);

    const featureFlags = useFeatureFlags();

    const isReady = useCallback(
      (
        clientToAssert: HostPlayP2pClient | undefined,
      ): clientToAssert is HostPlayP2pClient => {
        return (
          !connecting &&
          !!clientToAssert &&
          !!initialSlideFetch.data &&
          !minimumDelayOfPreLobby.running
        );
      },
      [connecting, initialSlideFetch.data, minimumDelayOfPreLobby.running],
    );

    useEffect(() => {
      if (connecting) return;
      if (!initialSlideFetch.data) return;
      if (client.current) return;

      const { geniallyId, sessionId, teamId, geniallyUserId } = params;

      const newClient = createHostPlayP2pClient({
        geniallyId,
        teamId,
        geniallyUserId,
        previousSessionId: sessionId,
        initialSlide: initialSlideFetch.data,
        featureFlags,
      });

      newClient.on(PlayP2pClientEventType.Error, code => {
        if (code === PlayErrorCodes.CannotReplaceHost) {
          replaceUrl({
            to: RouteNames.PlayerSessionFinder,
          });
        } else {
          replaceUrl({
            to: RouteNames.UnknownError,
          });
        }
      });

      newClient.on(HostPlayP2pClientEventType.HostReplaced, () => {
        newClient.dispose();

        replaceUrl({
          to: RouteNames.PlayerSessionFinder,
        });
      });

      const onGameSync = () => {
        setConnecting(false);
        timeoutOfSessionCreation.stop();

        newClient.off(HostPlayP2pClientEventType.GameSync, onGameSync);
      };

      newClient.on(HostPlayP2pClientEventType.GameSync, onGameSync);

      setConnecting(true);
      client.current = newClient;

      if (config.appEnv === AppEnv.Local) {
        window.resetGame = () => {
          client.current?.resetGame();
        };
      }

      newClient.start();
    }, [
      connecting,
      initialSlideFetch.data,
      params,
      timeoutOfSessionCreation,
      replaceUrl,
      client,
      featureFlags,
    ]);

    useEffect(() => {
      return () => {
        if (client.current) {
          client.current.dispose();

          window.resetGame = undefined;
        }
      };
    }, []);

    useEffect(() => {
      if (timeoutOfSessionCreation.ended) {
        navigate({ to: RouteNames.UnknownError });
      }
    }, [navigate, timeoutOfSessionCreation]);

    useEffect(() => {
      return () => {
        Ping.reset();
      };
    }, []);

    useEffect(() => {
      if (client.current?.pingTime) {
        Ping.value = client.current.pingTime;
      }
    }, [client, client.current?.pingTime]);

    if (!isReady(client.current)) {
      return <HostPreLobby />;
    }

    const contextValue: HostClientContextType = {
      client: client.current,
    };

    return (
      <HostClientContext.Provider value={contextValue}>
        {children}
      </HostClientContext.Provider>
    );
  },
);
