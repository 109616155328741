import { type AnimationEvent, useEffect, useRef, useState } from 'react';
import confetti from 'canvas-confetti';

import type {
  HostPlayPostMessageConnectionViewApi,
  PlayerPlayPostMessageConnectionViewApi,
} from '@genially/contracts';
import { Breakpoint } from '@genially/design-system';
import { BigTitle } from '@genially/design-system/build/foundations/typefaces/BigTitle';
import { SmallTitle } from '@genially/design-system/build/foundations/typefaces/SmallTitle';
import type { PenpalAsyncMethodReturns } from '@genially/ui';

import { usePlayAudio } from '../../../../../../hooks/usePlayAudio';
import {
  APPLAUSE_AUDIO,
  CIRCUS_JUMP_AUDIO,
  CONFETTI_PARTICLES_AUDIO,
  HIGH_VELOCITY_WHOOSH_AUDIO,
  LOAD_GAME_1_AUDIO,
  LOAD_GAME_2_AUDIO,
} from '../../constants';
import { ConfettiShapes, DotConfettiShapes, LinesConfettiShapes } from './confettiShapes';
import {
  ConfettiGravitiy,
  ConfettiParticleCount,
  ExitAnimationTime,
  MedalSource,
  Position,
  PositionAnimationEnterAnimation,
  PositionAnimationSequence,
  PositionAnimationSize,
} from './constants';
import {
  Medal,
  MedalWrapper,
  StyledUserAliasAvatar,
  UserAlias,
  Wrapper,
} from './PositionAnimation.styled';

interface PositionAnimationProps {
  onAnimationEnd: () => void;
  position: Position;
  userAlias: string;
  size?: PositionAnimationSize;
  enterAnimation?: PositionAnimationEnterAnimation;
  playApplauseAudio?: boolean;
  connection?:
    | PenpalAsyncMethodReturns<HostPlayPostMessageConnectionViewApi>
    | PenpalAsyncMethodReturns<PlayerPlayPostMessageConnectionViewApi>;
}

export const PositionAnimation = ({
  onAnimationEnd,
  position,
  userAlias,
  size = PositionAnimationSize.Medium,
  enterAnimation = PositionAnimationEnterAnimation.SlideFromBack,
  playApplauseAudio,
  connection,
}: PositionAnimationProps) => {
  const playFirstAudio = usePlayAudio({ url: HIGH_VELOCITY_WHOOSH_AUDIO, connection });
  const playSecondAudio = usePlayAudio({
    url: APPLAUSE_AUDIO,
    maxDuration: 19000,
    connection,
  });
  const playConfettiAudio = usePlayAudio({ url: CONFETTI_PARTICLES_AUDIO, connection });

  const flipAudios = [
    {
      position: Position.Bronze,
      audio: LOAD_GAME_1_AUDIO,
    },
    {
      position: Position.Silver,
      audio: LOAD_GAME_2_AUDIO,
    },
    {
      position: Position.Gold,
      audio: CIRCUS_JUMP_AUDIO,
    },
  ];

  const playFlipMedalAudio = usePlayAudio({
    url: flipAudios.find(flipAudio => flipAudio.position === position)?.audio || '',
    connection,
  });

  const [animationSequence, setAnimationSequence] = useState(
    PositionAnimationSequence.Enter,
  );

  const showUserAlias =
    animationSequence === PositionAnimationSequence.Translate ||
    animationSequence === PositionAnimationSequence.Exit;

  const handleAnimationEnd = (event: AnimationEvent) => {
    event.stopPropagation();

    if (animationSequence === PositionAnimationSequence.Enter) {
      setTimeout(() => {
        setAnimationSequence(PositionAnimationSequence.Flip);
        playFlipMedalAudio();
      }, 700);
    }
  };

  const handleTextAnimationEnd = (event: AnimationEvent) => {
    event.stopPropagation();

    setTimeout(() => {
      setAnimationSequence(PositionAnimationSequence.Exit);
    }, 1500);
  };

  const handleExitTransitionEnd = () => {
    if (animationSequence === PositionAnimationSequence.Exit) {
      onAnimationEnd();
    }
  };

  const handleFlipEnd = () => {
    playConfettiAudio();

    confetti({
      particleCount: ConfettiParticleCount[position],
      startVelocity: 80,
      angle: 90,
      spread: 120,
      gravity: ConfettiGravitiy[position],

      disableForReducedMotion: true,
      shapes: DotConfettiShapes,
      colors: ['#FEF007', '#5FFFE4', '#6C29FF', '#F73C69'],

      scalar: 1.3,
    });
    confetti({
      particleCount: ConfettiParticleCount[position],
      startVelocity: 80,
      angle: 90,
      spread: 120,
      gravity: ConfettiGravitiy[position],

      disableForReducedMotion: true,
      shapes: LinesConfettiShapes,
      colors: ['#FEF007', '#5FFFE4', '#6C29FF', '#F73C69'],

      scalar: 2.5,
    });
    confetti({
      particleCount: ConfettiParticleCount[position],
      startVelocity: 80,
      angle: 90,
      spread: 120,
      gravity: ConfettiGravitiy[position],

      disableForReducedMotion: true,
      shapes: ConfettiShapes,
      colors: ['#FEF007', '#5FFFE4', '#6C29FF', '#F73C69'],

      scalar: 5,
    });

    setTimeout(() => {
      setAnimationSequence(PositionAnimationSequence.Translate);
    }, ExitAnimationTime[position]);
  };

  const playAudios = () => {
    playFirstAudio();

    if (playApplauseAudio) {
      setTimeout(() => {
        playSecondAudio();
      }, 250);
    }
  };

  useEffect(() => {
    playAudios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper
      $center={showUserAlias}
      $size={size}
      $hide={animationSequence === PositionAnimationSequence.Exit}
      onTransitionEnd={handleExitTransitionEnd}
    >
      <MedalWrapper
        $size={size}
        $animationSequence={animationSequence}
        $enterAnimation={enterAnimation}
        $pinMedal={showUserAlias}
        onAnimationEnd={handleAnimationEnd}
        onTransitionEnd={handleFlipEnd}
      >
        <Medal $pinMedal={showUserAlias} src={MedalSource[position]} />

        <StyledUserAliasAvatar name={userAlias} />
      </MedalWrapper>

      <UserAlias
        with={BigTitle}
        on={{
          [Breakpoint.xs]: SmallTitle,
        }}
        $show={showUserAlias}
        onAnimationEnd={handleTextAnimationEnd}
      >
        {userAlias}
      </UserAlias>
    </Wrapper>
  );
};

PositionAnimation.Position = Position;
PositionAnimation.Size = PositionAnimationSize;
PositionAnimation.EnterAnimation = PositionAnimationEnterAnimation;
