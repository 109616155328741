import { createGlobalStyle } from 'styled-components';

import dmSansRegular from './assets/fonts/DmSans/DMSans_18pt-Regular.ttf';
import dmSansSemiBold from './assets/fonts/DmSans/DMSans_18pt-SemiBold.ttf';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: DM;
    font-style: normal;
    font-weight: 400;
    src: local(''), url('${dmSansRegular}') format('truetype');
  }
  @font-face {
    font-family: DM;
    font-style: normal;
    font-weight: 700;
    src: local(''), url('${dmSansSemiBold}') format('truetype');
  }

  #onetrust-pc-sdk {
    font-family: 'DM';
  }

  #onetrust-consent-sdk {
    font-family: 'DM';
  }
`;
