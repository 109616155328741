import styled from 'styled-components';

import { ResponsiveText } from '../../../../../../../../shared/application/components/ResponsiveText/ResponsiveText';

export const Wrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: size.units(1.5),
}));

export const PlayersTitle = styled(ResponsiveText)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),

  textAlign: 'center',
}));
