import styled from 'styled-components';

import { style } from '@genially/design-system';

export const InputSeparator = styled.div(({ theme: { color, size } }) => ({
  width: size.units(2.5),
  height: size.units(0.75),
  backgroundColor: color.background.primary.default(),

  ...style.mixins.responsive.xs.downward({
    width: size.units(2),
    height: size.units(0.625),
  }),
}));

interface StyledInputProps {
  $hasErrors?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>(
  ({ theme: { borderRadius, font, size, color }, $hasErrors }) => ({
    height: size.units(8.5),
    width: size.units(8.5),
    padding: `0 ${size.units(1.5)}`,
    color: $hasErrors ? color.content.error.default() : color.content.primary.default(),
    borderRadius: borderRadius.medium,
    border: `1px solid ${$hasErrors ? color.border.error.default() : color.border.primary.default()}`,
    backgroundColor: color.background.primary.default(),
    textAlign: 'center',
    fontSize: size.units(6),
    fontFamily: font.family,
    fontWeight: font.weight.extraBold,

    ...style.mixins.responsive.xs.downward({
      height: size.units(5),
      width: size.units(5),
      fontSize: size.units(3.5),
      padding: `0 ${size.units(1)}`,
    }),
  }),
);

export const SpinnerWrapper = styled.div({});

export const InputsContainer = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: size.units(1.5),

  ...style.mixins.responsive.xs.downward({
    gap: size.units(1),
  }),
}));

export const Wrapper = styled.div(({ theme: { size } }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: size.units(1.5),

  [SpinnerWrapper]: {
    ...style.mixins.responsive.xs.upward({
      position: 'absolute',
      top: '50%',
      left: '100%',
      transform: `translate(calc(50% + ${size.units(1.5)}), -50%)`,
    }),
  },

  ...style.mixins.responsive.xs.downward({
    flexDirection: 'column',
    gap: size.units(4),
  }),
}));
