import { useEffect } from 'react';
import { useTranslate } from 'react-polyglot';

import { useLottieAnimationManager } from '../../../../../../../../shared/application/components/LottieAnimation/hooks/useLottieAnimationManager';
import { LottieAnimations } from '../../../../../../../../shared/application/components/LottieAnimation/types/lottieAnimations';
import { Title, Wrapper } from './PlayerPreLobby.styled';

export const PlayerPreLobby = () => {
  const t = useTranslate();

  const { play, stop } = useLottieAnimationManager();

  useEffect(() => {
    play(LottieAnimations.Spinner);
  }, [play]);

  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  return (
    <Wrapper>
      <Title>{t('__new.play.playerAccess.ready')}</Title>
    </Wrapper>
  );
};
