/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { useTranslate } from 'react-polyglot';
import { observer } from 'mobx-react';

import { Logo, Spinner } from '@genially/design-system';
import { PlayP2pClientEventType } from '@genially/p2p-client';

import { useNavigation } from '../../../../../../shared/application/hooks/useNavigation';
import { useOpenGenially } from '../../../../../../shared/application/hooks/useOpenGenially';
import { usePlayAudio } from '../../../../../../shared/application/hooks/usePlayAudio';
import { usePlayerClientContext } from '../PlayerClientProvider/hooks/usePlayerClientContext';
import handSource from './assets/hand.svg';
import rockPaperScissorsSource from './assets/rock-paper-scissors.gif';
import { MiniGame } from './components/MiniGame/MiniGame';
import {
  DescriptionWrapper,
  Header,
  ImageWrapper,
  LoaderWrapper,
  LogoWrapper,
  MiniGameWrapper,
  StyledBasicSubtitle,
  StyledBasicTitle,
  StyledImage,
  StyledSmallTitle,
  Wrapper,
} from './PlayerLobby.styled';

export const PlayerLobby = observer(() => {
  const t = useTranslate();
  const { replaceUrl } = useNavigation();

  const [isImageHover, setIsImageHover] = useState(false);

  const [isMinigameShown, setisMinigameShown] = useState(false);

  const [showHeader, setShowHeader] = useState(true);

  const handleLogoClick = useOpenGenially();

  const { client } = usePlayerClientContext();

  const openGameAudio = usePlayAudio({
    url: 'https://audios.genially.com/play/rock-paper-scissors-game/open-game.mp3',
  });

  const { geniallyId } = client;

  const usernameHasSpaces = client.myPlayerData.username.includes(' ');

  const showMinigame = () => {
    openGameAudio();

    setisMinigameShown(true);
  };

  const hideMinigame = () => {
    setIsImageHover(false);
    setisMinigameShown(false);
  };

  const handleToggleMinigame = () => {
    setShowHeader(false);
  };

  const handleHeaderAnimationEnd = () => {
    if (!showHeader) {
      if (isMinigameShown) {
        hideMinigame();
      } else {
        showMinigame();
      }

      setShowHeader(true);
    }
  };

  const handleImageMouseEnter = () => {
    setIsImageHover(true);
  };

  const handleImageMouseLeave = () => {
    setIsImageHover(false);
  };

  useEffect(() => {
    client.on(PlayP2pClientEventType.Error, error => {
      console.error(error);
    });
  }, [geniallyId, client, replaceUrl]);

  return (
    <Wrapper>
      <Header $show={showHeader} onAnimationEnd={handleHeaderAnimationEnd}>
        {isMinigameShown ? (
          <MiniGameWrapper>
            <MiniGame onClose={handleToggleMinigame} />
          </MiniGameWrapper>
        ) : (
          <ImageWrapper>
            {isImageHover && (
              <StyledBasicSubtitle>
                {t('__new.play.playerLobby.join.antetitle')}
              </StyledBasicSubtitle>
            )}
            <StyledImage
              src={isImageHover ? rockPaperScissorsSource : handSource}
              onClick={handleToggleMinigame}
              onMouseEnter={handleImageMouseEnter}
              onMouseLeave={handleImageMouseLeave}
            />
          </ImageWrapper>
        )}
      </Header>

      <StyledSmallTitle $hasSpaces={usernameHasSpaces}>
        {t('__new.play.playerLobby.join.title', {
          username: client.myPlayerData.username,
        })}
      </StyledSmallTitle>

      <DescriptionWrapper>
        <StyledBasicTitle>
          {t('__new.play.playerLobby.join.description')}
        </StyledBasicTitle>

        <LoaderWrapper>
          <Spinner />
        </LoaderWrapper>
      </DescriptionWrapper>

      <LogoWrapper onClick={handleLogoClick}>
        <Logo variant={Logo.Variant.LIGHT} hasAnimation />
      </LogoWrapper>
    </Wrapper>
  );
});
