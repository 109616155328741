import { useAuthenticatedUser } from '../../../bootstrap/application/components/app/components/AuthenticatedUserProvider/AuthenticatedUserProvider.context';
import { config } from '../../../bootstrap/application/config/config';

export const useOpenGenially = () => {
  const { authenticatedUser } = useAuthenticatedUser();

  const open = () => {
    if (authenticatedUser) {
      window.open(config.urls.dashboard, '_blank');
      return;
    }

    window.open(`${config.urls.web}?from=watermark-play`, '_blank');
  };

  return open;
};
