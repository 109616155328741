import type { ElementType } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { BasicSubtitle, BasicTitle } from '@genially/design-system';

const scaleDownCenterAnimation = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale(2)',
  },
  '100%': {
    opacity: 1,
    transform: 'scale(1)',
  },
});

const heartbetAnimation = keyframes({
  from: {
    transform: 'scale(1)',
    transformOrigin: 'center center',
    animationTimingFunction: 'ease-out',
  },
  '10%': {
    transform: 'scale(0.91)',
    animationTimingFunction: 'ease-in',
  },
  '17%': {
    transform: 'scale(0.98)',
    animationTimingFunction: 'ease-out',
  },
  '33%': {
    transform: 'scale(0.87)',
    animationTimingFunction: 'ease-in',
  },
  '45%': {
    transform: 'scale(1)',
    animationTimingFunction: 'ease-out',
  },
});

interface withImageAnimationProps {
  $runAttentionAnimation: boolean;
}

const withImageAnimation = (Component: ElementType) =>
  styled(Component)<withImageAnimationProps>(({ $runAttentionAnimation }) => {
    if ($runAttentionAnimation) {
      return css`
        animation: ${heartbetAnimation} 4s ease-in-out infinite both;
      `;
    }

    return css`
      animation: ${scaleDownCenterAnimation} 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
      animation-delay: 0.3125s;
    `;
  });

export const StyledImage = withImageAnimation(
  styled.img(({ theme: { size } }) => ({
    width: size.units(12.5),
  })),
);

const trackingInContractAnimation = keyframes({
  '0%': {
    letterSpacing: '1em',
    opacity: 0,
  },
  '40%': {
    opacity: 0.6,
  },
  '100%': {
    letterSpacing: 'normal',
    opacity: 1,
  },
});

const withTitleAnimation = (Component: ElementType) =>
  styled(Component)(
    () => css`
      animation: ${trackingInContractAnimation} 0.35s cubic-bezier(0.215, 0.61, 0.355, 1)
        both;
      animation-delay: 0.8125s;
    `,
  );

export const StyledBasicTitle = withTitleAnimation(
  styled(BasicTitle)(({ theme: { color } }) => ({
    color: color.content.reversed.default(),
    textAlign: 'center',
  })),
);

const slideInTopAnimation = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateY(-100%)',
  },
  '100%': {
    opacity: 1,
    transform: 'translateY(0)',
  },
});

const withSubtitleAnimation = (Component: ElementType) =>
  styled(Component)(
    () => css`
      animation: ${slideInTopAnimation} 0.3125s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation-delay: 1.3625s;
    `,
  );

export const StyledBasicSubtitle = withSubtitleAnimation(
  styled(BasicSubtitle)(({ theme: { color, size } }) => ({
    color: color.content.reversed.default(),
    textAlign: 'center',
    padding: size.units(1.5),
  })),
);

export const Wrapper = styled.div(({ theme: { size } }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  [StyledBasicTitle]: {
    marginTop: size.units(3),
  },

  [StyledBasicSubtitle]: {
    marginTop: size.units(1.5),
  },
}));
