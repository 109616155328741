import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { NotificationContainer, ResetCSS, style } from '@genially/design-system';

import { PlaySpinner } from '../../../../shared/application/components/PlaySpinner/PlaySpinner';
import { LanguageManagerProvider } from '../../../../shared/application/context/language-manager/LanguageManagerContext';
import { AppErrorBoundary } from './components/AppErrorBoundary/AppErrorBoundary';
import { NonCriticErrorBoundary } from './components/AppErrorBoundary/implementations/NonCriticErrorBoundary';
import { AuthenticatedUserProvider } from './components/AuthenticatedUserProvider/AuthenticatedUserProvider.context';
import { ModalProvider } from './components/ModalProvider/ModalProvider.context';
import { Routes } from './Routes/Routes';
import { GlobalStyles } from './styles/GlobalStyles.styled';

const queryClient = new QueryClient();

export const App = () => {
  return (
    <AppErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <LanguageManagerProvider loaderComponent={<PlaySpinner />}>
          <ThemeProvider theme={style.themes.newPrimary}>
            <ResetCSS />
            <GlobalStyles />
            <NotificationContainer />
            <NonCriticErrorBoundary>
              <AuthenticatedUserProvider>
                <BrowserRouter>
                  <ModalProvider>
                    <Routes />
                  </ModalProvider>
                </BrowserRouter>
              </AuthenticatedUserProvider>
            </NonCriticErrorBoundary>
          </ThemeProvider>
        </LanguageManagerProvider>
      </QueryClientProvider>
    </AppErrorBoundary>
  );
};
