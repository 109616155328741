import confetti from 'canvas-confetti';

export const DotConfettiShapes = [
  // Dot
  confetti.shapeFromPath({
    path: 'M7.425 14.324a7.09 7.09 0 1 0 0-14.18 7.09 7.09 0 0 0 0 14.18Z',
    matrix: [
      0.8333333333333334, 0, 0, 0.8333333333333334, -6.666666666666667,
      -6.666666666666667,
    ] as any,
  }),
];

export const LinesConfettiShapes = [
  // small line
  confetti.shapeFromPath({
    path: 'M22.202 33.267c-1.51 0-3-.68-3.98-1.97l-16.96-22.3a5.013 5.013 0 0 1 .95-7.01c2.19-1.68 5.33-1.25 7.01.95l16.96 22.3a5.013 5.013 0 0 1-.95 7.01c-.9.69-1.97 1.02-3.02 1.02h-.01Z',
    matrix: [
      0.3333333333333333, 0, 0, 0.3333333333333333, -4.666666666666666,
      -5.666666666666666,
    ] as any,
  }),
  confetti.shapeFromPath({
    path: 'M12.92 45.326c-2.29 0-4.35-1.58-4.88-3.91L.34 6.996a5 5 0 0 1 3.79-5.97c2.7-.61 5.37 1.09 5.97 3.79l7.7 34.42a5 5 0 0 1-4.89 6.09h.01Z',
    matrix: [
      0.23809523809523808, 0, 0, 0.23809523809523808, -2.142857142857143,
      -5.476190476190476,
    ] as any,
  }),
  confetti.shapeFromPath({
    path: 'M5.921 27.553c-.81 0-1.63-.2-2.39-.61a4.995 4.995 0 0 1-2-6.78l9.18-16.88a5 5 0 0 1 6.78-2 4.995 4.995 0 0 1 2 6.78l-9.18 16.88a4.992 4.992 0 0 1-4.4 2.61h.01Z',
    matrix: [
      0.4166666666666667, 0, 0, 0.4166666666666667, -4.583333333333334,
      -5.833333333333334,
    ] as any,
  }),
];

export const ConfettiShapes = [
  confetti.shapeFromPath({
    path: 'M33.256 38.014c-7.9 0-15.86-3.22-22.16-9.05-6.94-6.42-10.9-15.09-10.86-23.79a4.995 4.995 0 0 1 5-4.97h.03a4.99 4.99 0 0 1 4.97 5.03c-.03 5.9 2.75 11.88 7.64 16.4 4.89 4.52 11.07 6.82 16.95 6.33 2.75-.22 5.17 1.81 5.4 4.56a4.998 4.998 0 0 1-4.56 5.4c-.8.07-1.6.1-2.41.1v-.01Z',
    matrix: [
      0.2631578947368421, 0, 0, 0.2631578947368421, -5.526315789473684,
      -5.263157894736842,
    ] as any,
  }),

  confetti.shapeFromPath({
    path: 'M45.03 63.257c-.63 0-1.27-.03-1.92-.09-6.84-.65-13.94-5.96-13.96-13.7-.02-6.19 4.27-10.31 7.72-13.63.37-.36.75-.72 1.11-1.07 1.38-1.37 2.29-2.84 2.7-3.95-11.31-.89-22.96 1.26-33.17 6.18a5.01 5.01 0 0 1-4.64-.16 4.977 4.977 0 0 1-2.51-3.9c-.96-10.8 2.44-21.8 9.34-30.16a4.999 4.999 0 0 1 7.04-.67 4.999 4.999 0 0 1 .67 7.04c-3.73 4.52-6.11 10.04-6.92 15.8 11.14-3.94 23.31-5.24 34.98-3.66 1.33.18 2.53.88 3.33 1.96 4.44 5.92 1.29 13.63-3.81 18.65l-1.19 1.16c-2.29 2.2-4.66 4.48-4.65 6.39 0 1.67 2.44 3.53 4.91 3.77 3.55.34 7.6-1.35 11.18-2.85a5 5 0 0 1 3.85 9.23c-3.96 1.65-8.78 3.66-14.06 3.67v-.01Z',
    matrix: [
      0.16666666666666666, 0, 0, 0.16666666666666666, -5.333333333333333,
      -5.333333333333333,
    ] as any,
  }),
  confetti.shapeFromPath({
    path: 'M15.246 98.04c-3.75 0-7.49-.39-11.16-1.2-2.7-.6-4.4-3.27-3.8-5.96.6-2.7 3.27-4.4 5.96-3.8 18.05 4 38.34-4.43 48.24-20.04 4.14-6.52 5.29-13.09 3.07-17.57-.37-.75-.83-1.44-1.35-2.08-6.54 4.83-13.8 8.68-21.53 11.27-3.91 1.32-9.09 2.72-14.52 1.93-6.28-.91-11.6-4.91-13.55-10.18-1.34-3.62-1.09-7.69.72-11.44 2.21-4.59 6.45-8.3 11.35-9.92 7.61-2.52 15.47-.8 21.78.58 4.05.89 10.04 2.2 15.48 5.09 9.19-8.36 16.21-19.1 19.88-31a5.002 5.002 0 0 1 9.56 2.94c-4.02 13.07-11.59 24.92-21.5 34.31a20.81 20.81 0 0 1 2.65 4.07c3.83 7.75 2.53 17.72-3.59 27.36-10.07 15.86-28.94 25.64-47.68 25.64h-.01Zm11.93-60.28c-1.86 0-3.67.22-5.36.78-2.3.76-4.45 2.63-5.48 4.76-.64 1.33-.76 2.55-.35 3.64.68 1.83 2.99 3.38 5.61 3.76 3.33.48 7.03-.56 9.89-1.52 5.44-1.83 10.62-4.37 15.41-7.49-2.76-.96-5.73-1.67-8.59-2.3-3.57-.78-7.46-1.63-11.14-1.63h.01Z',
    matrix: [
      0.10416666666666667, 0, 0, 0.10416666666666667, -4.479166666666667,
      -5.208333333333334,
    ] as any,
  }),
  confetti.shapeFromPath({
    path: 'M14.627 45.663c-3.57 0-7.13-.51-10.52-1.58-2.64-.82-4.1-3.63-3.28-6.26.82-2.64 3.63-4.1 6.26-3.28 8.32 2.6 18.03.67 24.72-4.92 6.69-5.59 10.31-14.8 9.23-23.45a4.998 4.998 0 1 1 9.92-1.24c1.5 11.94-3.51 24.65-12.74 32.37-6.51 5.44-15.08 8.37-23.59 8.37v-.01Z',
    matrix: [
      0.20833333333333334, 0, 0, 0.20833333333333334, -5.416666666666667,
      -4.791666666666667,
    ] as any,
  }),

  confetti.shapeFromPath({
    path: 'M23.648 77.542c-6.59 0-12.73-1.51-17.57-6.22-6.89-6.69-7.96-18.34-2.49-27.1 4.55-7.28 13.05-12.2 22.75-13.15 2.34-.23 4.74-.24 7.22-.04a36.63 36.63 0 0 1 2.42-8.91c4.03-9.75 12.37-17.65 22.31-21.15 2.61-.92 5.46.45 6.38 3.06.92 2.61-.45 5.46-3.06 6.38-7.31 2.57-13.43 8.38-16.39 15.53-.91 2.21-1.52 4.53-1.82 6.9 2.37.66 4.81 1.49 7.35 2.48 4.05 1.59 8.61 3.76 11.81 7.8 3.5 4.41 4.95 11.64.96 16.95-3.57 4.75-10.71 6.27-16.61 3.55-4.14-1.91-7.52-5.53-10.04-10.77-1.79-3.7-2.92-7.71-3.41-11.79-2.14-.22-4.19-.23-6.14-.04-6.53.64-12.37 3.89-15.24 8.49-2.93 4.7-2.5 11.26.98 14.64 4.65 4.52 13.12 3.71 21.58 2.17a5.003 5.003 0 0 1 1.8 9.84c-4.29.78-8.62 1.38-12.77 1.38h-.02Zm20.4-34.03c.44 1.72 1.05 3.4 1.82 5 1.02 2.12 2.76 4.89 5.23 6.03 1.58.73 3.69.5 4.43-.48.76-1.02.39-3.22-.8-4.72-1.72-2.17-4.69-3.55-7.64-4.71a67.16 67.16 0 0 0-3.03-1.12h-.01Z',
    matrix: [
      0.13513513513513514, 0, 0, 0.13513513513513514, -4.45945945945946,
      -5.27027027027027,
    ] as any,
  }),
];
