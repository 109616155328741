import backgroundLinesData from '../assets/background_lines.json';
import introSpinnerData from '../assets/intro_spinner.json';
import outroSpinnerData from '../assets/outro_spinner.json';
import spinnerData from '../assets/spinner.json';
import { LottieAnimations } from './lottieAnimations';

export const LottieAnimationsSequences = {
  [LottieAnimations.BackgroundLines]: [backgroundLinesData],
  [LottieAnimations.Spinner]: [introSpinnerData, spinnerData],
  [LottieAnimations.OutroSpinner]: [outroSpinnerData],
};
