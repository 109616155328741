import { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslate } from 'react-polyglot';
import { useQuery } from 'react-query';
import { observer } from 'mobx-react';

import type {
  GetAllOpenQuestionAnswersResponse,
  GetSurveyResultResponse,
  GetUserQuestionAnswersRankingResponse,
  HostPlayPostMessageConnectionViewApi,
  PlayerPlayPostMessageConnectionViewApi,
} from '@genially/contracts';
import type { PlayerPlayP2pClient } from '@genially/p2p-client';
import { HostPlayP2pClient } from '@genially/p2p-client';
import type {
  PenpalAsyncMethodReturns,
  SelectedInteractiveQuestion,
  SidebarSummaryContextConfig,
  SidebarSummaryContextState,
  SidebarSummaryCurrentUser,
  SidebarSummarySelectedQuestion,
} from '@genially/ui';
import { SidebarSummary } from '@genially/ui/build/components/SidebarSummary/SidebarSummary';
import { SidebarSummaryProvider } from '@genially/ui/build/components/SidebarSummary/SidebarSummary.context';
import { SidebarOpenReason } from '@genially/ui/build/components/SidebarSummary/types/SidebarOpenReason';
import { SidebarType } from '@genially/ui/build/components/SidebarSummary/types/SidebarType';
import { calculateSidebarTypeFromSelectedQuestion } from '@genially/ui/build/components/SidebarSummary/utils/calculateSidebarTypeFromSelectedQuestion';
import { isMobile } from '@genially/ui/build/utils/devices/device';
import { getSavedLanguage } from '@genially/ui/build/utils/language';
import { calculate } from '@genially/utils';

import { PlayerAnswersInterpreter } from '../../../infrastructure/PlayerAnswerInterpreter/PlayerAnswersInterpreter';
import { PodiumAnimation } from './components/PodiumAnimation/PodiumAnimation';
import { PodiumAnimationWrapper, Wrapper } from './PlaySidebarSummary.styled';

interface PlaySidebarSummaryProps {
  client: HostPlayP2pClient | PlayerPlayP2pClient;
  selectedQuestion: SelectedInteractiveQuestion | undefined;
  isOpen: boolean;
  toggleOpen: (open?: boolean) => void;
  isLoading?: boolean;
  LoadingComponent?: ReactNode;
  countMySelfAsParticipant?: boolean;
  omitAnimations?: boolean;
  isLastScoredQuestion?: boolean;
  autoExpandOnOpen?: boolean;
  connection?:
    | PenpalAsyncMethodReturns<HostPlayPostMessageConnectionViewApi>
    | PenpalAsyncMethodReturns<PlayerPlayPostMessageConnectionViewApi>;
  toggleOpenAnswerVisibility?: (userAlias: string) => void;
}

export const PlaySidebarSummary = observer(
  ({
    client,
    selectedQuestion,
    isOpen,
    toggleOpen,
    isLoading,
    LoadingComponent,
    omitAnimations,
    isLastScoredQuestion,
    countMySelfAsParticipant,
    connection,
    toggleOpenAnswerVisibility,
  }: PlaySidebarSummaryProps) => {
    const t = useTranslate();
    const [renderSidebar, setRenderSidebar] = useState(isOpen);

    const [renderPodiumAnimation, setRenderPodiumAnimation] = useState(false);
    const [fullScreenSidebar, setFullScreenSidebar] = useState(false);

    const [sidebarType, setSidebarType] = useState<SidebarType>(
      calculateSidebarTypeFromSelectedQuestion(selectedQuestion),
    );

    const playerAnswersInterpreter = PlayerAnswersInterpreter.from(
      client.geniallyId,
      client.game,
    );

    useEffect(() => {
      if (isOpen) {
        setRenderSidebar(true);

        if (!!omitAnimations && !!isLastScoredQuestion) {
          setFullScreenSidebar(true);
          setRenderPodiumAnimation(true);
        } else {
          setFullScreenSidebar(false);
        }
      }
    }, [isLastScoredQuestion, isOpen, omitAnimations]);

    const selectedQuestionAnswer = useMemo(() => {
      if (client instanceof HostPlayP2pClient) return undefined;
      if (!selectedQuestion) return undefined;

      return client
        .getMyAnswers()
        .find(answer => answer.interactiveQuestionId === selectedQuestion.Id);
    }, [client, selectedQuestion]);

    const fetchSurveyData = useCallback(async (): Promise<GetSurveyResultResponse> => {
      if (!selectedQuestion) {
        return [];
      }

      return playerAnswersInterpreter.interpretSurvey(selectedQuestion.Id);
    }, [playerAnswersInterpreter, selectedQuestion]);

    const fetchRankingData =
      useCallback(async (): Promise<GetUserQuestionAnswersRankingResponse> => {
        return playerAnswersInterpreter.interpretRanking();
      }, [playerAnswersInterpreter]);

    const fetchOpenAnswerData =
      useCallback(async (): Promise<GetAllOpenQuestionAnswersResponse> => {
        if (!selectedQuestion) {
          return [];
        }

        return playerAnswersInterpreter.interpretOpenAnswers(selectedQuestion.Id);
      }, [playerAnswersInterpreter, selectedQuestion]);

    const fetch = useMemo(() => {
      if (!selectedQuestion) {
        return fetchRankingData;
      }

      switch (sidebarType) {
        case SidebarType.Survey:
          return fetchSurveyData;
        case SidebarType.Ranking:
          return fetchRankingData;
        case SidebarType.OpenAnswer:
          return fetchOpenAnswerData;
        default:
          console.warn(`Sidebar type ${sidebarType} not supported`);
          return fetchRankingData;
      }
    }, [
      fetchOpenAnswerData,
      fetchRankingData,
      fetchSurveyData,
      selectedQuestion,
      sidebarType,
    ]);

    const userAlias = calculate(() => {
      if (client instanceof HostPlayP2pClient) {
        return undefined;
      }

      return client.username;
    });

    const handleToggleOpenSidebar = useCallback(
      (open?: boolean) => toggleOpen(open),
      [toggleOpen],
    );

    const handleOnSidebarTransitionEnd = useCallback(() => {
      setRenderSidebar(false);
    }, []);

    const sidebarSummaryState = useMemo((): SidebarSummaryContextState => {
      if (isOpen) {
        return {
          isOpen: true,
          reason: SidebarOpenReason.Response,
          onSidebarCloseTransitionEnd: handleOnSidebarTransitionEnd,
          toggleOpen: handleToggleOpenSidebar,
        };
      }

      return {
        isOpen: false,
        onSidebarCloseTransitionEnd: handleOnSidebarTransitionEnd,
        toggleOpen: handleToggleOpenSidebar,
      };
    }, [handleOnSidebarTransitionEnd, handleToggleOpenSidebar, isOpen]);

    const podium = useMemo(() => {
      if (!isLastScoredQuestion) {
        return [];
      }

      const data = playerAnswersInterpreter
        .interpretRanking()
        .slice(0, 3)
        .map(ranking => ranking.userAlias);

      return data;
    }, [isLastScoredQuestion, playerAnswersInterpreter]);

    const handleAnimationEnd = useCallback(
      (type: SidebarType) => {
        if (podium.length > 0 && type === SidebarType.Ranking) {
          setRenderPodiumAnimation(true);
          setFullScreenSidebar(true);
        }
      },
      [podium],
    );

    const sidebarSummaryConfig = useMemo(
      (): SidebarSummaryContextConfig => ({
        isMobile,
        isAudioMuted: true,
        getTranslation: t,
        language: getSavedLanguage(),
        autoExpandOnOpen: isMobile ? false : fullScreenSidebar,
        omitAnimations,
        disablePodiumAnimations: true,
        hideLastUpdated: true,
        countMySelfAsParticipant,
        fetchingRefreshTime: 500,
        fetchingMaxCalls: Number.MAX_SAFE_INTEGER,
        onAnimationEnd: handleAnimationEnd,
        toggleOpenAnswerVisibility,
      }),
      [
        t,
        fullScreenSidebar,
        omitAnimations,
        countMySelfAsParticipant,
        handleAnimationEnd,
        toggleOpenAnswerVisibility,
      ],
    );

    const sidebarCurrentUser = useMemo(
      (): SidebarSummaryCurrentUser => ({
        alias: userAlias,
        isAuthenticated: false,
      }),
      [userAlias],
    );

    const sidebarSummarySelectedQuestion = useMemo(():
      | SidebarSummarySelectedQuestion
      | undefined => {
      if (!selectedQuestion) return undefined;

      return {
        ...selectedQuestion,
        isCorrect: !!selectedQuestionAnswer?.isCorrect,
        isLast: !!isLastScoredQuestion,
      };
    }, [isLastScoredQuestion, selectedQuestion, selectedQuestionAnswer?.isCorrect]);

    const handlePodiumAnimationEnd = () => {
      setRenderPodiumAnimation(false);
    };

    useEffect(() => {
      if (isOpen) {
        setSidebarType(calculateSidebarTypeFromSelectedQuestion(selectedQuestion));
      }
    }, [isOpen, selectedQuestion]);

    if (!renderSidebar) {
      return null;
    }

    const sidebarSummaryProviderType = !selectedQuestion
      ? sidebarType
      : calculateSidebarTypeFromSelectedQuestion(selectedQuestion);

    return (
      <Wrapper>
        {renderPodiumAnimation && podium.length > 0 && (
          <PodiumAnimationWrapper>
            <PodiumAnimation
              onAnimationEnd={handlePodiumAnimationEnd}
              podium={podium}
              flagSpeed={
                omitAnimations
                  ? PodiumAnimation.FlagSpeed.Slow
                  : PodiumAnimation.FlagSpeed.Normal
              }
              connection={connection}
            />
          </PodiumAnimationWrapper>
        )}

        <SidebarSummaryProvider
          type={sidebarSummaryProviderType}
          fetchSummary={fetch}
          state={sidebarSummaryState}
          config={sidebarSummaryConfig}
          selectedQuestion={sidebarSummarySelectedQuestion}
          currentUser={sidebarCurrentUser}
          selectedQuestionAnswer={selectedQuestionAnswer}
          useQuery={useQuery}
        >
          <SidebarSummary isLoading={isLoading} LoadingComponent={LoadingComponent} />
        </SidebarSummaryProvider>
      </Wrapper>
    );
  },
);
