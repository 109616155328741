import styled from 'styled-components';

import { SmallParagraph, style } from '@genially/design-system';
import { OtherVertIcon } from '@genially/icons';

import { HostGameBreakdownSteps } from '../../../../../../HostGame.constants';

export const ButtonText = styled(SmallParagraph)({});

export const LeftSide = styled.div(({ theme: { size, color, transition } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: size.units(1),

  padding: size.units(1),
  height: '100%',

  backgroundColor: color.background.secondary.default(),

  transition: transition.standard('background-color'),

  '&:hover': {
    backgroundColor: color.background.secondary.hover(),
  },

  ...style.mixins.responsive.custom(`${HostGameBreakdownSteps.third}px`).downward({
    padding: size.units(0.5),
  }),
}));

export const RightSide = styled.div(({ theme: { size, color, transition } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: size.units(1),

  width: size.units(4.75),
  height: '100%',
  paddingRight: size.units(0.25),

  backgroundColor: color.background.secondary.default(),

  transition: transition.standard('background-color'),

  '&:hover': {
    backgroundColor: color.background.secondary.hover(),
  },
}));

export const Divider = styled.div(({ theme: { color } }) => ({
  height: '100%',
  width: '1px',

  backgroundColor: color.background.reversed.default(),
}));

export const Wrapper = styled.div<{ $isMobile: boolean }>(
  ({ theme: { size, color }, $isMobile }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: size.units(5.5),
    overflow: 'hidden',

    backgroundColor: $isMobile ? undefined : color.background.secondary.default(),

    height: size.units(4),

    cursor: 'pointer',
  }),
);

export const StyledOtherVertIcon = styled(OtherVertIcon)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
  cursor: 'pointer',
}));
