import axios from 'axios';

import { createConstantRetryStrategy, withRetries } from '@genially/commons';
import type { PlayPostMessageInteractiveQuestion } from '@genially/contracts';

import { config } from '../../bootstrap/application/config/config';
import { GeniallyIncompatibleError } from '../domain/errors/GeniallyIncompatibleError';

const client = axios.create({
  baseURL: `${config.urls.view}/api/view/`,
});

const getViewData = async (id: string): Promise<any> => {
  try {
    const response = await withRetries(
      () => client.get(id),
      createConstantRetryStrategy({ wait: 2500 }),
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching view data:', error);
    throw error;
  }
};

const verifyGeniallyIsCompatible = async (id: string): Promise<void> => {
  try {
    const viewData = await getViewData(id);

    if (viewData.Published === false) {
      throw GeniallyIncompatibleError.causeIsDraft();
    }

    if (viewData.Genially.HasPassword) {
      throw GeniallyIncompatibleError.causeHasPassword();
    }
  } catch (error) {
    console.error('Error verifying that genially is compatible:', error);
    throw error;
  }
};

const getInitialSlideId = async (id: string): Promise<string> => {
  try {
    const viewData = await getViewData(id);
    return (
      viewData.Slides.find((slide: { Order: number }) => slide.Order === 1)?.Id ?? ''
    );
  } catch (error) {
    console.error('Error fetching initial slide ID:', error);
    throw error;
  }
};

const getGeniallyName = async (id: string): Promise<string> => {
  try {
    const viewData = await getViewData(id);
    return viewData.Genially.Name;
  } catch (error) {
    console.error('Error fetching genially name:', error);
    throw error;
  }
};

const getGeniallyImageRenderUrl = async (id: string): Promise<string> => {
  try {
    const viewData = await getViewData(id);
    return viewData.Genially.ImageRender;
  } catch (error) {
    console.error('Error fetching genially image render URL:', error);
    throw error;
  }
};

const getInteractiveQuestions = async (
  id: string,
): Promise<PlayPostMessageInteractiveQuestion[]> => {
  try {
    const viewData = await getViewData(id);
    return viewData.Activities;
  } catch (error) {
    console.error('Error fetching interactive questions:', error);
    throw error;
  }
};

export const ViewApiService = {
  getViewData,
  verifyGeniallyIsCompatible,
  getInitialSlideId,
  getGeniallyName,
  getGeniallyImageRenderUrl,
  getInteractiveQuestions,
};
