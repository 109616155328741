import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { NotificationManager } from './NotificationManager';

export const useNotificationManager = () => {
  const { color } = useTheme();

  return {
    notificationManager: useMemo(() => new NotificationManager(color), [color]),
  };
};
