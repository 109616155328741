import { useMemo } from 'react';

import {
  BasicParagraph,
  BasicTitle,
  BiggerParagraph,
  BiggerSubtitle,
  Breakpoint,
  Logo,
} from '@genially/design-system';

import { useOpenGenially } from '../../hooks/useOpenGenially';
import {
  ButtonContainer,
  Content,
  ErrorCode,
  GeniallyLogoWrapper,
  Image,
  LeftColumn,
  RightColumn,
  Title,
  Wrapper,
  WrapperWithLogo,
} from './ErrorPageContent.styled';

type BaseErrorPageContentProps = {
  errorCode?: number;
  title: string;
  content?: string;
  button?: React.ReactNode;
  secondButton?: React.ReactNode;
  opacity?: number;
  showLogo?: boolean;
  isOverlay?: boolean;
};

export type ErrorPageContentProps = BaseErrorPageContentProps &
  (
    | {
        imageSource: string;
        RightComponent?: never;
      }
    | {
        imageSource?: never;
        RightComponent: React.ReactNode;
      }
  );

export const ErrorPageContent = ({
  errorCode,
  content,
  button,
  secondButton,
  title,
  opacity = 1,
  showLogo = false,
  isOverlay = false,
  ...rest
}: ErrorPageContentProps) => {
  const hasRightComponent = 'RightComponent' in rest;

  const handleLogoClick = useOpenGenially();

  const RightComponent = useMemo(() => {
    if (hasRightComponent) {
      return rest.RightComponent;
    }

    if ('imageSource' in rest) {
      return <Image src={rest.imageSource} />;
    }

    return undefined;
  }, [hasRightComponent, rest]);

  return (
    <WrapperWithLogo $hasRightComponent={hasRightComponent} $isOverlay={isOverlay}>
      {showLogo && (
        <GeniallyLogoWrapper onClick={handleLogoClick}>
          <Logo variant={Logo.Variant.LIGHT} hasAnimation />
        </GeniallyLogoWrapper>
      )}
      <Wrapper
        $opacity={opacity}
        $hasRightComponent={hasRightComponent}
        $isOverlay={isOverlay}
      >
        <LeftColumn $hasRightComponent={hasRightComponent}>
          {errorCode ? <ErrorCode>{errorCode}</ErrorCode> : undefined}
          <Title with={BasicTitle} on={{ xs: BiggerSubtitle }}>
            {title}
          </Title>
          {content ? (
            <Content
              with={BiggerParagraph}
              on={{ [Breakpoint.md]: BasicParagraph }}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          ) : undefined}
          <ButtonContainer $hasRightComponent={hasRightComponent}>
            {button}
            {secondButton}
          </ButtonContainer>
        </LeftColumn>
        <RightColumn $hasRightComponent={hasRightComponent}>{RightComponent}</RightColumn>
      </Wrapper>
    </WrapperWithLogo>
  );
};
