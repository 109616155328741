import type { ElementType } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { style } from '@genially/design-system';

const fadeScaleInAnimation = (top: string) =>
  keyframes({
    from: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      opacity: 0,
      transform: 'translate(-50%, -50%) scale(0)',
    },

    '16.66%, 83.33%': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      opacity: 1,
      transform: 'translate(-50%, -50%) scale(1)',
    },

    to: {
      position: 'absolute',
      top: `${top}`,
      left: '50%',
      opacity: 1,
      transform: 'translate(-50%, 0%) scale(1)',
    },
  });

interface withFadeScaleInAnimationProps {
  $isMobile: boolean;
  $hasAnimation?: boolean;
}

const withFadeScaleInAnimation = (Component: ElementType) =>
  styled(Component)<withFadeScaleInAnimationProps>(
    ({ theme: { size }, $isMobile, $hasAnimation }) =>
      $hasAnimation
        ? css`
            animation: ${fadeScaleInAnimation(size.units($isMobile ? 3.5 : 7))} 6s
              ease-in-out;
          `
        : undefined,
  );

export const HeaderWrapper = withFadeScaleInAnimation(
  styled.div({
    display: 'flex',
    alignSelf: 'center',
    justifySelf: 'center',
  }),
);

export const Separator = styled.div(({ theme: { color, size } }) => ({
  width: '100%',
  height: '1px',
  backgroundColor: color.content.tertiary.default(),
  margin: `${size.units(1)} 0`,
}));

export const ParticipantsInfoWrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}));

export const LogoWrapper = styled.div(({ theme: { size } }) => ({
  height: size.units(6),
  cursor: 'pointer',

  ...style.mixins.responsive.xs.downward({
    height: size.units(5),
  }),

  svg: {
    width: 'auto',
  },
}));

export const Wrapper = styled.div(({ theme: { size } }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: `${size.units(7)} ${size.units(9)} ${size.units(8)}`,
  gap: size.units(3.5),
  height: '100dvh',
  overflow: 'hidden',
  width: '100%',

  ...style.mixins.responsive.xs.downward({
    padding: `${size.units(3.5)} ${size.units(2)} ${size.units(5)}`,
  }),

  [LogoWrapper]: {
    position: 'absolute',
    bottom: size.units(3),
    left: size.units(3),

    ...style.mixins.responsive.xs.downward({
      bottom: size.units(1.5),
      left: size.units(1.5),
    }),
  },
}));

const fadeInKeyframes = keyframes({
  from: {
    opacity: 0,
  },

  to: {
    opacity: 1,
  },
});

const withFadeInAnimation = (Component: ElementType) =>
  styled(Component)(
    () => css`
      animation: ${fadeInKeyframes} 0.3s linear;
    `,
  );

export const Content = withFadeInAnimation(
  styled.div({
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  }),
);
