import styled from 'styled-components';

import { style } from '@genially/design-system';

interface WrapperProps {
  $isCentered: boolean;
}

export const Wrapper = styled.div<WrapperProps>(({ theme: { size }, $isCentered }) => ({
  position: 'absolute',
  width: '100%',
  ...($isCentered
    ? {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }
    : {
        bottom: 0,
        aspectRatio: '1920 / 1080',
        ...style.mixins.responsive.md.downward({
          width: 'auto',
          height: size.units(48.375),
          left: '50%',
          transform: 'translateX(-50%)',
        }),
      }),
}));
