import { Fragment, useState } from 'react';
import { useTranslate } from 'react-polyglot';

import { Button } from '@genially/design-system';
import { NextPage24Icon, PodiumIcon } from '@genially/icons';

import { useModal } from '../../../../../../../../../../bootstrap/application/components/app/components/ModalProvider/ModalProvider.context';
import { geniallyHasSelfCorrectingQuestions } from '../../../../../../../../../../shared/application/utils/geniallyHasSelfCorrectingQuestions';
import { useHostClientContext } from '../../../../../HostClientProvider/hooks/useHostClientContext';
import { useHostGameContext } from '../../../../HostGame.context';
import { CollapsableGameControlButtons } from './components/CollapsableGameControlButtons/CollapsableGameControlButtons';
import { FinishGameModal } from './components/FinishGameModal/FinishGameModal';
import { downloadGameResultsFromClient } from './utils/downloadGameResultsFromClient';

interface GameControlButtonsProps {
  showCollapsedMenu?: boolean;
  onShowRanking: () => void;
}

export const GameControlButtons = ({
  showCollapsedMenu,
  onShowRanking,
}: GameControlButtonsProps) => {
  const t = useTranslate();

  const [finishingGame, setFinishingGame] = useState(false);

  const { client } = useHostClientContext();
  const { interactiveQuestions } = useHostGameContext();

  const hasSelfCorrectingQuestions =
    geniallyHasSelfCorrectingQuestions(interactiveQuestions);

  const { openModal, closeModal } = useModal();

  const handleShowRanking = () => {
    onShowRanking();
  };

  const handleFinishGame = async () => {
    if (finishingGame) return Promise.resolve();

    setFinishingGame(true);

    closeModal();

    try {
      await downloadGameResultsFromClient(client, t);

      await client.finishGame();
    } finally {
      setFinishingGame(false);
    }

    return Promise.resolve();
  };

  const handleFinishGameButtonClick = () => {
    openModal(parentElement => (
      <FinishGameModal
        parentElement={parentElement}
        closeModal={closeModal}
        handleFinishGame={handleFinishGame}
      />
    ));
  };

  if (!showCollapsedMenu) {
    return (
      <Fragment>
        <Button
          variant={Button.Variant.DANGER}
          StartIcon={<NextPage24Icon />}
          size={Button.Size.MEDIUM}
          onClick={handleFinishGameButtonClick}
          loading={finishingGame}
        >
          {t('__new.play.closeSession.modal.title')}
        </Button>
        {hasSelfCorrectingQuestions && (
          <Button
            variant={Button.Variant.DEFAULT}
            StartIcon={<PodiumIcon />}
            size={Button.Size.MEDIUM}
            onClick={handleShowRanking}
          >
            {t('__new.play.navbarController.button.viewRanking')}
          </Button>
        )}
      </Fragment>
    );
  }

  return (
    <CollapsableGameControlButtons
      isFinishingGame={finishingGame}
      onFinishGame={handleFinishGameButtonClick}
      onShowRanking={handleShowRanking}
    />
  );
};
