import { useEffect } from 'react';

import { PlayUserType } from '@genially/p2p-lib';

import { LogRocketService } from '../../../../../../shared/infrastructure/logrocket/LogRocketService';
import { useAuthenticatedUser } from '../../components/AuthenticatedUserProvider/AuthenticatedUserProvider.context';
import { getIsLoadTestFromUrl } from '../utils/getIsLoadTestFromUrl';
import { getUserTypeFromUrl } from '../utils/getUserTypeFromUrl';

export const useInitializeLogRocket = () => {
  const { authenticatedUser } = useAuthenticatedUser();

  useEffect(() => {
    const isLoadTest = getIsLoadTestFromUrl();

    if (isLoadTest) {
      return;
    }

    LogRocketService.init(authenticatedUser);

    const userType = getUserTypeFromUrl();

    if (userType === PlayUserType.Host) {
      LogRocketService.trackWithSampling('play_host', 20);
    }

    if (userType === PlayUserType.Player) {
      LogRocketService.trackWithSampling('play_participant_lobby', 5);
    }
  }, [authenticatedUser]);
};
