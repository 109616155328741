import React, { useEffect, useState } from 'react';

import { BasicParagraph, Spinner } from '@genially/design-system';

import { PlaySpinnerContainer } from './PlaySpinner.styled';

export const PlaySpinner = ({
  messages,
  height = 'full-screen',
}: {
  messages?: string[];
  height?: 'full-screen' | 'auto';
}) => {
  const randomMessage = messages
    ? messages[Math.floor(Math.random() * messages.length)]
    : undefined;

  const [selectedMessage, setSelectedMessage] = useState<string | undefined>(
    randomMessage,
  );

  useEffect(() => {
    if (!messages) return;

    const interval = setInterval(() => {
      setSelectedMessage(messages[Math.floor(Math.random() * messages.length)]);
    }, 5000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [messages]);

  return (
    <PlaySpinnerContainer $height={height === 'full-screen' ? '100dvh' : undefined}>
      <Spinner color="rgba(82, 95, 255, 1)" size={56} />

      {selectedMessage && <BasicParagraph>{selectedMessage}</BasicParagraph>}
    </PlaySpinnerContainer>
  );
};
