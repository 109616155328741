import type { UserData } from '@genially/ui';
import {
  hasOnetrustPermissionToLoadLogRocket,
  loadLogRocketIntegration,
} from '@genially/ui/build/business/support/integrations/LogRocket/loadLogRocketIntegration';
import { LogRocketAnonymousIntegration } from '@genially/ui/build/business/support/integrations/LogRocket/LogRocketAnonymousIntegration';
import { LogRocketEvent } from '@genially/ui/build/business/support/integrations/LogRocket/LogRocketEvent';
import { LogRocketIntegration } from '@genially/ui/build/business/support/integrations/LogRocket/LogRocketIntegration';
import { TrackService } from '@genially/ui/build/business/support/services/track/TrackService';
import config from '@genially/ui/build/config/config';

import type { GeniallyUser } from '../../domain/GeniallyUser';

let initialized = false;

const { isProduction } = config;

const logRocketService = new TrackService();

export const LogRocketService = {
  init: (geniallyUser?: GeniallyUser) => {
    if (!isProduction) return;
    if (initialized) return;
    if (!hasOnetrustPermissionToLoadLogRocket()) return;

    initialized = true;

    const startLogRocket = () => {
      if (geniallyUser) {
        const userData = {
          id: geniallyUser.id,
          firstName: geniallyUser.username,
          lastName: '',
          userName: geniallyUser.username,
          Sector: geniallyUser.sector,
          Role: geniallyUser.role,
        } as UserData;

        logRocketService.start(new LogRocketIntegration(userData));

        return;
      }

      logRocketService.start(new LogRocketAnonymousIntegration());
    };

    loadLogRocketIntegration(
      {
        appId: 'genially-z2e5p/play-ioyop',
        releaseVersion: import.meta.env.VITE_APP_RELEASE_VERSION ?? 'unknown',
        childDomains: ['*'],
      },
      true,
    ).then(loaded => {
      if (loaded) {
        startLogRocket();
      }
    });
  },
  isInitialized: () => initialized,
  track: (eventName: string) => {
    if (!isProduction) return;

    logRocketService.trackEvent(
      new LogRocketEvent({
        name: eventName,
      }),
    );
  },
  trackWithSampling: (eventName: string, samplingRate: number) => {
    if (!isProduction) return;

    logRocketService.trackEventWithSampling(
      new LogRocketEvent({
        name: eventName,
      }),
      samplingRate,
    );
  },
};
