import { useEffect, useState } from 'react';
import Lottie from 'lottie-react';

import { usePlayAudio } from '../../hooks/usePlayAudio';
import countdownData from './assets/countdown.json';
import { Wrapper } from './LottieCountdown.styled';

enum CountdownState {
  Show,
  Hiding,
  Removed,
}

interface LottieCountdownProps {
  show?: boolean;
  onCountdownEnd?: () => void;
}

const COUNTDOWN_AUDIO = 'https://audios.genially.com/play/countdown.mp3';

export const LottieCountdown = ({ show, onCountdownEnd }: LottieCountdownProps) => {
  const [countdownState, setCountdownState] = useState(CountdownState.Removed);

  const [audioSegmentsPlayed, setAudioSegmentsPlayed] = useState<number[]>([]);

  const handleComplete = () => {
    setCountdownState(CountdownState.Hiding);
    onCountdownEnd?.();
  };

  const handleHide = () => {
    setCountdownState(CountdownState.Removed);
  };

  useEffect(() => {
    if (show) {
      setCountdownState(CountdownState.Show);
    }
  }, [show]);

  const audioSegments = [
    {
      id: 1,
      start: 0,
      end: 60,
      audioSprite: [0, 1000] as [number, number],
    },
    {
      id: 2,
      start: 60,
      end: 118,
      audioSprite: [1000, 1000] as [number, number],
    },
    {
      id: 3,
      start: 118,
      end: 181,
      audioSprite: [2000, 1000] as [number, number],
    },
    {
      id: 4,
      start: 181,
      end: 262,
      audioSprite: [3000, 5000] as [number, number],
    },
  ];

  const extractSprites = (
    segments: typeof audioSegments,
  ): Record<string, [number, number]> => {
    return segments.reduce(
      (acc, segment) => {
        if (segment.audioSprite.length === 2) {
          acc[`sprite_${segment.id}`] = segment.audioSprite;
        }
        return acc;
      },
      {} as Record<string, [number, number]>,
    );
  };

  const sprites = extractSprites(audioSegments);

  const playAudio = usePlayAudio({ url: COUNTDOWN_AUDIO, sprites });

  const handleEnterFrame = (frame: any) => {
    const currentFrame = Math.floor(frame.currentTime);
    const currentAudioFrame = audioSegments.find(
      audioFrame => currentFrame >= audioFrame.start && currentFrame <= audioFrame.end,
    );

    if (currentAudioFrame && !audioSegmentsPlayed.includes(currentAudioFrame.id)) {
      playAudio(`sprite_${currentAudioFrame.id}`);

      if (!audioSegmentsPlayed.includes(currentAudioFrame.id)) {
        setAudioSegmentsPlayed([...audioSegmentsPlayed, currentAudioFrame.id]);
      }
    }
  };

  if (countdownState === CountdownState.Removed) {
    return null;
  }

  return (
    <Wrapper $hide={countdownState === CountdownState.Hiding} onAnimationEnd={handleHide}>
      <Lottie
        animationData={countdownData}
        loop={false}
        onComplete={handleComplete}
        onSegmentStart={() => console.log('SEGMENT')}
        onEnterFrame={handleEnterFrame}
      />
    </Wrapper>
  );
};
