import { Button } from '@genially/design-system';
import { GeniallyIconIcon } from '@genially/icons';

import { useCallback } from 'react';
import { useTranslate } from 'react-polyglot';
import { useSearchParams } from 'react-router-dom';
import { config } from '../../../../bootstrap/application/config/config';
import { ErrorPageContent } from '../../components/ErrorPageContent/ErrorPageContent';
import GeniallyIncompatibleErrorImage from '../assets/GeniallyIncompatibleErrorImage.png';

export const GeniallyWithPasswordErrorPage = () => {
  const t = useTranslate();
  const [queryParams] = useSearchParams();
  const geniallyId = queryParams.get('geniallyId');

  const handleButtonClick = useCallback(() => {
    window.location.href = geniallyId
      ? `${config.urls.editor}${geniallyId}`
      : `${config.urls.dashboard}`;
  }, [geniallyId]);

  return (
    <ErrorPageContent
      imageSource={GeniallyIncompatibleErrorImage}
      title={t('__new.play.audienceLimit.player.title')}
      content={t('__new.play.noAccess.host.text')}
      button={
        <Button
          StartIcon={<GeniallyIconIcon />}
          onClick={handleButtonClick}
          variant={Button.Variant.CTA}
          size={Button.Size.BIG}
        >
          {t('__new.play.draftCreation.editor.button')}
        </Button>
      }
    />
  );
};
