import { useTranslate } from 'react-polyglot';

import { Breakpoint, Button, useBreakpoint } from '@genially/design-system';
import { PlayIcon } from '@genially/icons';

import { useHostClientContext } from '../../../HostClientProvider/hooks/useHostClientContext';
import { HostLobbyBreakdownSteps } from '../../HostLobby.constants';
import { ConnectionInfo } from '../ConnectionInfo/ConnectionInfo';
import { ParticipantsInfo } from '../ParticipantsInfo/ParticipantsInfo';
import { Footer, Wrapper } from './Header.styled';

interface HeaderProps {
  showAll?: boolean;
}

export const Header = ({ showAll }: HeaderProps) => {
  const t = useTranslate();
  const { client } = useHostClientContext();

  const { isDownwards } = useBreakpoint();

  const isMobile = isDownwards(Breakpoint.xs);

  const handleStartGameButtonClick = () => {
    client.startGame();
  };

  return (
    <Wrapper>
      <ConnectionInfo />

      {showAll && (
        <Footer>
          {!isMobile && <ParticipantsInfo />}

          <Button
            StartIcon={<PlayIcon />}
            onClick={handleStartGameButtonClick}
            size={
              isDownwards(HostLobbyBreakdownSteps.first)
                ? Button.Size.MEDIUM
                : Button.Size.BIG
            }
            variant={Button.Variant.CTA}
          >
            {t('__new.play.creatorLobby.join.startButton')}
          </Button>
        </Footer>
      )}
    </Wrapper>
  );
};
