import styled from 'styled-components';

import {
  BasicParagraph,
  BigParagraph,
  BigSubtitle,
  style,
} from '@genially/design-system';
import {
  GeniallyIconIcon,
  InfoFilledIcon,
  LockIcon,
  UnlockIcon,
  UserIcon,
  WarningBasicIcon,
} from '@genially/icons';

import { HostGameBreakdownSteps } from '../../HostGame.constants';

export const LiveAndVersionWrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: size.units(0.5),
}));

interface PlayersCountProps {
  $showWarning: boolean;
}

export const PlayersCount = styled(BasicParagraph)<PlayersCountProps>(
  ({ theme: { color }, $showWarning }) => ({
    color: $showWarning
      ? color.content.warning.default()
      : color.content.reversed.default(),
    whiteSpace: 'nowrap',

    ...style.mixins.ellipsis(true),
  }),
);

interface PlayersTextProps {
  $showWarning: boolean;
}

export const PlayersText = styled(BasicParagraph)<PlayersTextProps>(
  ({ theme: { color }, $showWarning }) => ({
    color: $showWarning
      ? color.content.warning.default()
      : color.content.reversed.default(),
  }),
);

export const StyledUserIcon = styled(UserIcon)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));

export const PlayersCountWrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  gap: size.units(1),
  overflow: 'hidden',
  alignItems: 'center',
  cursor: 'default',

  ...style.mixins.responsive.custom(`${HostGameBreakdownSteps.first}px`).downward({
    [PlayersText]: {
      display: 'none',
    },
  }),
}));

export const LeftSide = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: size.units(3),
}));

export const StyledGeniallyIcon = styled(GeniallyIconIcon)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));

export const PlayLinkText = styled(BigSubtitle)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));

export const PlayIconAndLinkWrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: size.units(1),
}));

export const SessionCodeText = styled(BigParagraph)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
  cursor: 'pointer',
}));

export const BlockedText = styled(BigSubtitle)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));

export const SessionCode = styled(BigSubtitle)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
}));

export const StyledUnlockIcon = styled(UnlockIcon)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
  cursor: 'pointer',
}));

export const StyledLockIcon = styled(LockIcon)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
  cursor: 'pointer',
}));

export const StyledInfoFilledIcon = styled(InfoFilledIcon)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
  cursor: 'pointer',
}));

export const StyledWarningIcon = styled(WarningBasicIcon)(({ theme: { color } }) => ({
  color: color.content.warning.default(),
}));

export const CentralSide = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: size.units(3),

  ...style.mixins.responsive.custom(`${HostGameBreakdownSteps.forth}px`).downward({
    paddingRight: size.units(2),
  }),
}));

export const RightSide = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: size.units(4),
}));

export const Wrapper = styled.div(({ theme: { color, size } }) => ({
  width: '100%',
  height: size.units(7),

  backgroundColor: color.background.reversed.default(),

  padding: `${size.units(1)} ${size.units(2)}`,

  display: 'flex',
  gap: size.units(1),

  [CentralSide]: {
    flexGrow: 1,
  },
}));

export const CentralSideWrapper = styled.div<{ $isClickable: boolean }>(
  ({ theme: { size, transition }, $isClickable }) => ({
    display: 'flex',
    gap: size.units(3),
    alignItems: 'center',
    cursor: $isClickable ? 'pointer' : 'default',
    transition: transition.standard(),

    ...($isClickable && {
      ':hover': {
        transform: 'scale(0.95)',
      },
    }),
  }),
);
