import { Fragment } from 'react';
import { useTranslate } from 'react-polyglot';
import { observer } from 'mobx-react';

import { Ping } from './Ping';
import { VersionText } from './PlayAppVersion.styled';

export const PlayAppVersion = observer(() => {
  const t = useTranslate();

  return (
    <Fragment>
      <VersionText>
        {t('__new.play.sesionVersion.title')} {import.meta.env.VITE_APP_RELEASE_VERSION}
      </VersionText>
      {Ping.isVisible && Ping.value !== undefined && (
        <VersionText>Ping {Ping.value}ms</VersionText>
      )}
    </Fragment>
  );
});
