import { Navigate, Route, Routes as ReactRoutes } from 'react-router-dom';
import { observer } from 'mobx-react';

import { PlayUserType } from '@genially/p2p-lib';

import { HostClientProvider } from '../../../../../host/application/pages/HostGamePage/components/HostClientProvider/HostClientProvider';
import { HostGamePage } from '../../../../../host/application/pages/HostGamePage/HostGamePage';
import { PlayerClientProvider } from '../../../../../player/application/pages/PlayerGamePage/components/PlayerClientProvider/PlayerClientProvider';
import { PlayerGamePage } from '../../../../../player/application/pages/PlayerGamePage/PlayerGamePage';
import { PlayerKickedPage } from '../../../../../player/application/pages/PlayerKickedPage/PlayerKickedPage';
import { PlayerSessionSelectionPage } from '../../../../../player/application/pages/PlayerSessionSelectionPage/PlayerSessionSelectionPage';
import { PlayersLimitReachedPage } from '../../../../../player/application/pages/PlayersLimitReachedPage/PlayersLimitReachedPage';
import { PlayerUsernameSelectionPage } from '../../../../../player/application/pages/PlayerUsernameSelectionPage/PlayerUsernameSelectionPage';
import { GeniallyDraftErrorPage } from '../../../../../shared/application/pages/GeniallyDraftErrorPage/GeniallyDraftErrorPage';
import { GeniallyWithPasswordErrorPage } from '../../../../../shared/application/pages/GeniallyWithPasswordErrorPage/GeniallyWithPasswordErrorPage';
import { NoGeniallyAccessErrorPage } from '../../../../../shared/application/pages/NoGeniallyAccessErrorPage/NoGeniallyAccessErrorPage';
import { NotFoundErrorPage } from '../../../../../shared/application/pages/NotFoundErrorPage/NotFoundErrorPage';
import { UnknownErrorPage } from '../../../../../shared/application/pages/UnknownErrorPage/UnknownErrorPage';
import { RouteNames } from '../../../../domain/RouteNames';
import { PlayFeatureFlagProvider } from '../components/PlayFeatureFlagProvider/PlayFeatureFlagProvider';
import { CheckHostGeniallyAccessGuard } from './guards/CheckHostGeniallyAccessGuard';
import { CheckHostParamsGuard } from './guards/CheckHostParamsGuard';
import { CheckIsGeniallyCompatibleGuard } from './guards/CheckIsGeniallyCompatibleGuard';
import { CheckSessionIdGuard } from './guards/CheckSessionIdGuard';
import { useInitializeLogRocket } from './hooks/useInitializeLogRocket';
import { CommonLayout } from './layouts/CommonLayout/CommonLayout';
import { PlayerConfigWizardLayout } from './layouts/PlayerConfigWizardLayout/PlayerConfigWizardLayout';

export const Routes = observer(() => {
  useInitializeLogRocket();

  return (
    <ReactRoutes>
      <Route path="/" element={<CommonLayout />}>
        {[RouteNames.Host, RouteNames.HostWithoutSessionId].map(routePath => (
          <Route
            key={routePath}
            path={routePath}
            element={
              <CheckHostParamsGuard>
                {({ geniallyId, sessionId, teamId }) => (
                  <CheckIsGeniallyCompatibleGuard
                    geniallyId={geniallyId}
                    userType={PlayUserType.Host}
                  >
                    <CheckHostGeniallyAccessGuard>
                      {({ geniallyUserId }) => (
                        <PlayFeatureFlagProvider teamId={teamId}>
                          <HostClientProvider
                            params={{ sessionId, teamId, geniallyId, geniallyUserId }}
                          >
                            <HostGamePage />
                          </HostClientProvider>
                        </PlayFeatureFlagProvider>
                      )}
                    </CheckHostGeniallyAccessGuard>
                  </CheckIsGeniallyCompatibleGuard>
                )}
              </CheckHostParamsGuard>
            }
          />
        ))}

        <Route
          path={RouteNames.Player}
          element={
            <CheckSessionIdGuard>
              {({ geniallyId, sessionId, username }) => (
                <CheckIsGeniallyCompatibleGuard
                  geniallyId={geniallyId}
                  userType={PlayUserType.Player}
                >
                  <PlayerClientProvider params={{ sessionId, geniallyId, username }}>
                    <PlayerGamePage />
                  </PlayerClientProvider>
                </CheckIsGeniallyCompatibleGuard>
              )}
            </CheckSessionIdGuard>
          }
        />

        <Route element={<PlayerConfigWizardLayout />}>
          <Route
            path={RouteNames.PlayerUsernameSelection}
            element={
              <CheckSessionIdGuard>
                {({ geniallyId, sessionId }) => (
                  <PlayerUsernameSelectionPage
                    geniallyId={geniallyId}
                    sessionId={sessionId}
                  />
                )}
              </CheckSessionIdGuard>
            }
          />
          <Route
            path={RouteNames.PlayerSessionFinder}
            element={<PlayerSessionSelectionPage />}
          />
        </Route>

        <Route path={RouteNames.PlayerKicked} element={<PlayerKickedPage />} />
        <Route
          path={RouteNames.PlayersLimitReached}
          element={<PlayersLimitReachedPage />}
        />

        <Route path={RouteNames.NotFound} element={<NotFoundErrorPage />} />
        <Route path={RouteNames.UnknownError} element={<UnknownErrorPage />} />
        <Route
          path={RouteNames.NoGeniallyAccess}
          element={<NoGeniallyAccessErrorPage />}
        />
        <Route path={RouteNames.GeniallyDraft} element={<GeniallyDraftErrorPage />} />
        <Route
          path={RouteNames.GeniallyWithPassword}
          element={<GeniallyWithPasswordErrorPage />}
        />

        <Route
          path="/"
          element={<Navigate to={RouteNames.PlayerSessionFinder} replace />}
        />
        <Route path="*" element={<NotFoundErrorPage />} />
      </Route>
    </ReactRoutes>
  );
});
