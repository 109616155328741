import { useContext } from 'react';

import type { HostClientContextType } from '../HostClientProvider';
import { HostClientContext } from '../HostClientProvider';

export const useHostClientContext = () => {
  const context = useContext(HostClientContext) as HostClientContextType;

  if (Object.keys(context).length === 0) {
    throw new Error('useHostClientContext must be used within a HostClientProvider');
  }

  return context;
};
