import styled from 'styled-components';

export const PositionalContainer = styled.div<{ $zIndex: number }>(({ $zIndex }) => ({
  position: 'relative',
  zIndex: $zIndex,
}));

export const Container = styled.div({});

export const Wrapper = styled.div<{ $show: boolean }>(
  ({ theme: { color, transition }, $show }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: color.background.reversed.default(0.5),

    transition: transition.standard(),

    opacity: $show ? 1 : 0,
    pointerEvents: $show ? 'auto' : 'none',
    userSelect: $show ? 'auto' : 'none',
  }),
);
