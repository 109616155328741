import { useTranslate } from 'react-polyglot';
import { observer } from 'mobx-react';

import { BasicSubtitle, BiggerSubtitle, Breakpoint } from '@genially/design-system';

import { LiveLabel } from '../../../../../../../../shared/application/components/LiveLabel/LiveLabel';
import { useHostClientContext } from '../../../HostClientProvider/hooks/useHostClientContext';
import { PlayersTitle, Wrapper } from './ParticipantsInfo.styled';

export const ParticipantsInfo = observer(() => {
  const t = useTranslate();

  const { client } = useHostClientContext();

  return (
    <Wrapper>
      <PlayersTitle with={BiggerSubtitle} on={{ [Breakpoint.md]: BasicSubtitle }}>
        {client.players.length} {t('__new.play.creatorLobby.participants')}
      </PlayersTitle>
      <LiveLabel />
    </Wrapper>
  );
});
