import type { ElementType } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { BasicSubtitle, BasicTitle, SmallTitle, style } from '@genially/design-system';

export const LogoWrapper = styled.div(({ theme: { size } }) => ({
  height: size.units(6),
  cursor: 'pointer',

  ...style.mixins.responsive.xs.downward({
    height: size.units(5),
  }),

  svg: {
    width: 'auto',
  },
}));

export const StyledImage = styled.img(({ theme: { size } }) => ({
  width: size.units(21.25),
  cursor: 'pointer',
}));

export const MiniGameWrapper = styled.div({});

export const Wrapper = styled.div(({ theme: { size } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: size.units(4),
  padding: `${size.units(4)} ${size.units(2)} ${size.units(8)} ${size.units(2)}`,
  width: '100%',

  [StyledImage]: {
    marginBottom: size.units(1),
  },

  [LogoWrapper]: {
    position: 'absolute',
    bottom: size.units(3),
    left: size.units(3),

    ...style.mixins.responsive.xs.downward({
      bottom: size.units(1.5),
      left: size.units(1.5),
    }),
  },

  [MiniGameWrapper]: {
    marginBottom: size.units(1),
  },
}));

interface StyledSmallTitleProps {
  $hasSpaces?: boolean;
}

export const StyledSmallTitle = styled(SmallTitle)<StyledSmallTitleProps>(
  ({ theme: { color }, $hasSpaces }) => ({
    color: color.content.reversed.default(),
    textAlign: 'center',

    ...style.mixins.ellipsis(true),

    whiteSpace: $hasSpaces ? 'normal' : 'break-spaces',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflowWrap: 'anywhere',
  }),
);

export const StyledBasicTitle = styled(BasicTitle)(({ theme: { color } }) => ({
  color: color.content.reversed.default(),
  textAlign: 'center',
}));

export const LoaderWrapper = styled.div({});

export const DescriptionWrapper = styled.div(({ theme: { size } }) => ({
  position: 'relative',

  [LoaderWrapper]: {
    position: 'absolute',
    top: '50%',
    right: '0',
    transform: `translate(100%, -50%) translateX(${size.units(4)})`,
  },
}));

const fadeInKeyframes = keyframes({
  '0%': {
    opacity: 0,
  },

  '100%': {
    opacity: 1,
  },
});

const withFadeInAnimation = (Component: ElementType) =>
  styled(Component)(
    () => css`
      animation: ${fadeInKeyframes} 0.3s;
    `,
  );

export const StyledBasicSubtitle = withFadeInAnimation(
  styled(BasicSubtitle)(({ theme: { color } }) => ({
    width: 'max-content',
    color: color.content.reversed.default(),
  })),
);

export const ImageWrapper = styled.div(({ theme: { size } }) => ({
  position: 'relative',

  [StyledBasicSubtitle]: {
    position: 'absolute',
    left: '50%',
    top: 0,
    transform: `translate(-50%, -50%) translateY(-${size.units(4)})`,
  },
}));

const scaleInKeyframes = keyframes({
  '0%': {
    transform: 'scale(0)',
  },

  '100%': {
    transform: 'scale(1)',
  },
});

const scaleOutKeyframes = keyframes({
  '0%': {
    transform: 'scale(1)',
  },

  '100%': {
    transform: 'scale(0)',
  },
});

interface withScaleAnimationProps {
  $show: boolean;
}

const withScaleAnimation = (Component: ElementType) =>
  styled(Component)<withScaleAnimationProps>(
    ({ $show }) => css`
      animation: ${$show ? scaleInKeyframes : scaleOutKeyframes} 0.3s;
    `,
  );

export const Header = withScaleAnimation(styled.div({}));
