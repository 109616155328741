import styled from 'styled-components';

import { style } from '@genially/design-system';

export const Wrapper = styled.div<{ $disablePointerEvents?: boolean }>(
  ({ theme: { size }, $disablePointerEvents }) => ({
    display: 'flex',
    width: '100%',
    height: size.units(7),
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: `${size.units(2)} ${size.units(3)}`,

    ...style.mixins.responsive.custom('866px').downward({
      height: size.units(4),
      padding: `${size.units(1)} ${size.units(2)}`,
    }),

    pointerEvents: $disablePointerEvents ? 'none' : undefined,
  }),
);
