import { useState } from 'react';

import { Breakpoint, useBreakpoint } from '@genially/design-system';

import { StyledIframe, Wrapper } from './GeniallyGalleryIframe.styled';

const DESKTOP_GENIALLY = '6748608bff7ba4707c5da6b5';
const MOBILE_GENIALLY = '67505b247fa69747eefeccd4';

export const GeniallyGalleryIframe = () => {
  const { isDownwards } = useBreakpoint();

  const [loaded, setLoaded] = useState(false);

  const isMobile = isDownwards(Breakpoint.md);

  const handleLoadIframe = () => {
    // When the iframe is loaded, first a white screen is shown and then the content is loaded
    setTimeout(() => {
      setLoaded(true);
    }, 100);
  };

  return (
    <Wrapper>
      <StyledIframe
        $isLoaded={loaded}
        title="Iframe - Last Screen Live Sessions"
        src={`https://view.genially.com/${isMobile ? MOBILE_GENIALLY : DESKTOP_GENIALLY}?disablePlayIframe=true`}
        sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
        allowFullScreen
        scrolling="yes"
        frameBorder={0}
        width="100%"
        height="100%"
        onLoad={handleLoadIframe}
      />
    </Wrapper>
  );
};
