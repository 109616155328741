import { useTranslate } from 'react-polyglot';

import { Button } from '@genially/design-system';
import { NextPage24Icon } from '@genially/icons';

import { useAuthenticatedUser } from '../../../../../../../../bootstrap/application/components/app/components/AuthenticatedUserProvider/AuthenticatedUserProvider.context';
import { config } from '../../../../../../../../bootstrap/application/config/config';
import { ErrorPageContent } from '../../../../../../../../shared/application/components/ErrorPageContent/ErrorPageContent';
import SessionLostOverlayImage from './assets/SessionLostOverlayImage.png';
import { Wrapper } from './SessionLostOverlay.styled';

export const SessionLostOverlay = ({ show }: { show: boolean }) => {
  const t = useTranslate();

  const { authenticatedUser } = useAuthenticatedUser();

  const handleButtonClick = () => {
    window.location.href = authenticatedUser
      ? config.urls.dashboard
      : `${config.urls.auth}/signup?from=play`;
  };

  return (
    <Wrapper $show={show}>
      <ErrorPageContent
        isOverlay
        opacity={0.9}
        title={t('__new.play.internetError.title')}
        imageSource={SessionLostOverlayImage}
        button={
          <Button
            StartIcon={<NextPage24Icon />}
            onClick={handleButtonClick}
            variant={Button.Variant.DANGER}
            size={Button.Size.BIG}
          >
            {authenticatedUser
              ? t('__new.play.internetError.button')
              : t('__new.play.finalScreen.noAutenticatedUser.button')}
          </Button>
        }
      />
    </Wrapper>
  );
};
