import { useTranslate } from 'react-polyglot';

import { BasicParagraph, BigParagraph } from '@genially/design-system';

import {
  ErrorInfoWrapper,
  StyledResponsiveText,
  StyledWarningBasicIcon,
} from './SessionBlockedError.styled';

export const SessionBlockedError = () => {
  const t = useTranslate();

  return (
    <ErrorInfoWrapper>
      <StyledWarningBasicIcon />

      <StyledResponsiveText with={BigParagraph} on={{ xs: BasicParagraph }}>
        {t('__new.play.playerAccess.username.error.closedSession')}
      </StyledResponsiveText>
    </ErrorInfoWrapper>
  );
};
