import { useState } from 'react';
import { useTranslate } from 'react-polyglot';

import waitingSource from './assets/waiting.svg';
import {
  StyledBasicSubtitle,
  StyledBasicTitle,
  StyledImage,
  Wrapper,
} from './WaitingToResolveAnimation.styled';

export const WaitingToResolveAnimation = () => {
  const t = useTranslate();

  const [runAttentionAnimation, setRunAttentionAnimation] = useState(false);

  const handleAnimationEnd = () => {
    setRunAttentionAnimation(true);
  };

  return (
    <Wrapper>
      <StyledImage
        // src="https://static.genial.ly/genially/activities/waiting.svg"
        src={waitingSource}
        onAnimationEnd={handleAnimationEnd}
        $runAttentionAnimation={runAttentionAnimation}
      />
      <StyledBasicTitle>{t('__new.play.resultSidebar.waiting.title')}</StyledBasicTitle>
      <StyledBasicSubtitle>
        {t('__new.play.resultSidebar.waiting.description')}
      </StyledBasicSubtitle>
    </Wrapper>
  );
};
