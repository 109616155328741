import { Fragment, useCallback, useEffect, useState } from 'react';

import { Header } from '../../../../bootstrap/application/components/app/Routes/layouts/CommonLayout/components/Header/Header';
import { RouteNames } from '../../../../bootstrap/domain/RouteNames';
import { useLottieAnimationManager } from '../../../../shared/application/components/LottieAnimation/hooks/useLottieAnimationManager';
import { LottieAnimations } from '../../../../shared/application/components/LottieAnimation/types/lottieAnimations';
import { useNavigation } from '../../../../shared/application/hooks/useNavigation';
import { usePlayAudio } from '../../../../shared/application/hooks/usePlayAudio';
import { PlayerUsernameSelection } from './components/PlayerUsernameSelection/PlayerUsernameSelection';
import { useRedirectionFromLobby } from './hooks/useRedirectionFromLobby';
import { PlayerUsernameSelectionPageErrors } from './PlayerUsernameSelectionPage.constants';

const USERNAME_SELECTION_AUDIO = 'https://audios.genially.com/play/alias.mp3';

export const PlayerUsernameSelectionPage = ({
  geniallyId,
  sessionId,
}: {
  geniallyId: string;
  sessionId: string;
}) => {
  const { navigate } = useNavigation();
  const { play, addEndLoopListener, removeEndLoopListener } = useLottieAnimationManager();

  const playAudio = usePlayAudio({ url: USERNAME_SELECTION_AUDIO });

  const [username, setUsername] = useState<string | undefined>(undefined);

  const redirectionFromLobby = useRedirectionFromLobby();

  const navigateToLobby = useCallback(() => {
    if (!username) {
      return;
    }

    playAudio();

    navigate({
      to: RouteNames.Player,
      params: {
        sessionId,
        geniallyId,
        username: encodeURIComponent(username),
      },
    });
  }, [username, playAudio, navigate, sessionId, geniallyId]);

  const handleUsernameSelected = (name: string) => {
    setUsername(name);

    play(LottieAnimations.Spinner);
  };

  const handleLoopComplete = useCallback(() => {
    if (!username) {
      return;
    }

    navigateToLobby();
  }, [navigateToLobby, username]);

  useEffect(() => {
    addEndLoopListener(handleLoopComplete);

    return () => {
      removeEndLoopListener(handleLoopComplete);
    };
  }, [addEndLoopListener, handleLoopComplete, removeEndLoopListener]);

  return (
    <Fragment>
      <Header />
      <PlayerUsernameSelection
        geniallyId={geniallyId}
        sessionId={sessionId}
        username={redirectionFromLobby?.username}
        isUsernameTaken={
          redirectionFromLobby?.error === PlayerUsernameSelectionPageErrors.UsernameInUse
        }
        isSessionBlocked={
          redirectionFromLobby?.error === PlayerUsernameSelectionPageErrors.SessionBlocked
        }
        onUsernameSelected={handleUsernameSelected}
      />
    </Fragment>
  );
};
