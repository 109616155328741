import type { ElementType } from 'react';
import styled, { css, keyframes } from 'styled-components';

import {
  BasicParagraph,
  BiggerSubtitle,
  BigSubtitle,
  type Size,
  SmallTitle,
  style,
} from '@genially/design-system';
import { ArrowRightToBracketIcon } from '@genially/icons';

import { Size as ParticipantSize } from './constants';

const getParticipantNameFontSize = (size: Size, participantSize: ParticipantSize) => {
  switch (participantSize) {
    case ParticipantSize.Small:
      return {
        fontSize: size.units(2.25),
        lineHeight: size.units(3),
      };
    case ParticipantSize.Medium:
      return {
        fontSize: size.units(3),
        lineHeight: size.units(4),
      };
    case ParticipantSize.Large:
    default:
      return {
        fontSize: size.units(4),
        lineHeight: size.units(5),
      };
  }
};

interface ParticipantNameProps {
  $size: ParticipantSize;
}

export const ParticipantName = styled(SmallTitle)<ParticipantNameProps>(
  ({ theme: { color, size, transition }, $size }) => ({
    color: color.content.reversed.default(),

    transition: transition.standard('font-size', 'line-height'),

    ...getParticipantNameFontSize(size, $size),
  }),
);

interface ParticipantNameCommonProps {
  $isDanger: boolean;
  $hasSpaces: boolean;
}

export const ParticipantNameLarge = styled(SmallTitle)<ParticipantNameCommonProps>(
  ({ theme: { color, transition }, $isDanger, $hasSpaces }) => ({
    color: $isDanger ? color.content.error.default() : color.content.reversed.default(),
    transition: transition.standard('color'),

    ...style.mixins.ellipsis(true),

    whiteSpace: $hasSpaces ? 'normal' : 'break-spaces',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflowWrap: 'anywhere',
  }),
);

export const ParticipantNameMedium = styled(BiggerSubtitle)<ParticipantNameCommonProps>(
  ({ theme: { color, transition }, $isDanger, $hasSpaces }) => ({
    color: $isDanger ? color.content.error.default() : color.content.reversed.default(),
    transition: transition.standard('color'),

    ...style.mixins.ellipsis(true),

    whiteSpace: $hasSpaces ? 'normal' : 'break-spaces',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflowWrap: 'anywhere',
  }),
);

export const ParticipantNameSmall = styled(BigSubtitle)<ParticipantNameCommonProps>(
  ({ theme: { color, transition }, $isDanger, $hasSpaces }) => ({
    color: $isDanger ? color.content.error.default() : color.content.reversed.default(),
    transition: transition.standard('color'),

    ...style.mixins.ellipsis(true),

    whiteSpace: $hasSpaces ? 'normal' : 'break-spaces',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflowWrap: 'anywhere',
  }),
);

export const ParticipantNameParagraph = styled(
  BasicParagraph,
)<ParticipantNameCommonProps>(
  ({ theme: { color, transition }, $isDanger, $hasSpaces }) => ({
    color: $isDanger ? color.content.error.default() : color.content.reversed.default(),
    transition: transition.standard('color'),

    ...style.mixins.ellipsis(true),

    whiteSpace: $hasSpaces ? 'normal' : 'break-spaces',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflowWrap: 'anywhere',
  }),
);

const scaleInBounceKeyframes = keyframes({
  '0%': {
    transform: 'scale(1)',
  },
  '25%': {
    transform: 'scale(1.5)',
  },
  '50%': {
    transform: 'scale(1)',
  },
  '75%': {
    transform: 'scale(1.25)',
  },
  '100%': {
    transform: 'scale(1)',
  },
});

interface withScaleInBounceAnimationProps {
  $disableEnterAnimation?: boolean;
}

const withScaleInBounceAnimation = (Component: ElementType) =>
  styled(Component)<withScaleInBounceAnimationProps>(({ $disableEnterAnimation }) =>
    $disableEnterAnimation
      ? undefined
      : css`
          animation: ${scaleInBounceKeyframes} 1s ease;
        `,
  );

export const Wrapper = withScaleInBounceAnimation(
  styled.div(({ theme: { color, size } }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: size.units(1.5),
    padding: size.units(2),
    borderRadius: size.units(1),
    cursor: 'pointer',

    backgroundColor: color.background.tertiary.default(0.05),
    height: 'max-content',

    ...style.mixins.responsive.xs.downward({
      padding: `${size.units(1)} ${size.units(3)}`,
    }),

    [`${ParticipantNameParagraph}`]: {
      flex: '1',
    },
  })),
);

export const IconWrapper = styled.div(({ theme: { color, size } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: size.units(5),
  height: size.units(5),
  borderRadius: '100%',
  backgroundColor: color.background.danger.default(),
  border: `2px solid ${color.border.primary.hover()}`,

  ...style.mixins.responsive.xs.downward({
    width: size.units(4),
    height: size.units(4),
  }),
}));

export const StyledArrowRightToBracketIcon = styled(ArrowRightToBracketIcon)(
  ({ theme: { color } }) => ({
    color: color.content.reversed.default(),
  }),
);

export const UserAliasAvatarWrapper = styled.div(({ theme: { size } }) => ({
  position: 'relative',

  [IconWrapper]: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));
