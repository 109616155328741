import React from 'react';
import { useTranslate } from 'react-polyglot';

import { Button } from '@genially/design-system';

import { config } from '../../../../bootstrap/application/config/config';
import { ErrorPageContent } from '../../../../shared/application/components/ErrorPageContent/ErrorPageContent';
import UnknownErrorImage from '../../../../shared/application/pages/UnknownErrorPage/assets/UnknownErrorImage.png';

export const PlayerKickedPage = () => {
  const t = useTranslate();

  const handleButtonClick = () => {
    window.location.href = config.urls.play;
  };

  return (
    <ErrorPageContent
      imageSource={UnknownErrorImage as string}
      title={t('__new.play.expelPlayer.screenOut.title')}
      content={t('__new.play.expelPlayer.screenOut.description')}
      button={
        <Button
          onClick={handleButtonClick}
          variant={Button.Variant.CTA}
          size={Button.Size.BIG}
        >
          {t('__new.play.expelPlayer.screenOut.button')}
        </Button>
      }
    />
  );
};
