import type { ErrorInfo, ReactNode } from 'react';
import React from 'react';

export interface ErrorBoundaryProps {
  children?: ReactNode;
  onError?: (error: unknown, errorInfo: ErrorInfo) => void;
  errorComponent?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: unknown;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: unknown) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error: unknown, errorInfo: ErrorInfo) {
    const { onError } = this.props;

    if (onError) {
      onError(error, errorInfo);
    }
  }

  render() {
    const { hasError } = this.state;
    const { children, errorComponent } = this.props;

    if (hasError) {
      return errorComponent ?? null;
    }

    return children;
  }
}
