import React, { useEffect } from 'react';
import { useTranslate } from 'react-polyglot';

import { Button } from '@genially/design-system';
import { GeniallyIconIcon } from '@genially/icons';

import { config } from '../../../../../../bootstrap/application/config/config';
import { RouteNames } from '../../../../../../bootstrap/domain/RouteNames';
import { FullPageContent } from '../../../../../../shared/application/components/FullPageContent/FullPageContent';
import { useNavigation } from '../../../../../../shared/application/hooks/useNavigation';
import { useHostClientContext } from '../HostClientProvider/hooks/useHostClientContext';
import image from './assets/image.png';

export const HostGameFinished = () => {
  const t = useTranslate();

  const { client } = useHostClientContext();

  const { navigate } = useNavigation();

  const handleGoToDashboard = () => {
    window.location.href = config.urls.dashboard;
  };

  useEffect(() => {
    if (!client.game.isFinished) {
      navigate({
        to: RouteNames.Host,
        params: {
          teamId: client.teamId,
          geniallyId: client.geniallyId,
          sessionId: client.sessionId,
        },
      });
    }
  }, [
    client.game.isFinished,
    client.geniallyId,
    client.sessionId,
    client.teamId,
    navigate,
  ]);

  if (!client.game.isFinished) {
    // To avoid the blinking of the page when the user is redirected to the Host page
    return null;
  }

  return (
    <FullPageContent
      title={t('__new.play.finalScreen.title')}
      content={t('__new.play.finalScreen.description')}
      imageSource={image}
      button={
        <Button
          variant={Button.Variant.CTA}
          StartIcon={<GeniallyIconIcon />}
          onClick={handleGoToDashboard}
        >
          {t('__new.play.finalScreen.button')}
        </Button>
      }
    />
  );
};
