import { useEffect } from 'react';

import type { PlayP2pClient } from '@genially/p2p-client';

import { useScreenWakeLock } from './useScreenWakeLock';

interface useScreenWakeLockWhilePlayingProps {
  client: PlayP2pClient;
}

export const useScreenWakeLockWhilePlaying = ({
  client,
}: useScreenWakeLockWhilePlayingProps) => {
  const { request: requestScreenWakeLock, release: releaseScreenWakeLock } =
    useScreenWakeLock();

  useEffect(() => {
    if (client.game.isFinished) {
      releaseScreenWakeLock();
    } else if (client.game.isStarted) {
      requestScreenWakeLock();
    }
  }, [
    client.game.isFinished,
    client.game.isStarted,
    releaseScreenWakeLock,
    requestScreenWakeLock,
  ]);
};
