import styled from 'styled-components';

import { style } from '@genially/design-system';

import { HostLobbyBreakdownSteps } from '../../HostLobby.constants';

export const TextLabelWrapper = styled.div(({ theme: { transition } }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  opacity: 0,
  transition: transition.standard(),
}));

export const Wrapper = styled.div(({ theme: { size, color, transition } }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: transition.standard(),
  padding: size.units(0.5),
  outline: `2px solid ${color.border.primary.default()}`,
  outlineOffset: 0,

  WebkitMask: `
      conic-gradient(at 20px 20px, #0000 75%, #000 0)
      0 0/calc(100% - 20px) calc(100% - 20px),
      linear-gradient(#000 0 0) content-box
    `,

  '> svg': {
    width: '100%',
    height: '100%',
    transition: transition.standard(),

    maxWidth: size.units(15),

    ...style.mixins.responsive.custom(`${HostLobbyBreakdownSteps.first}px`).downward({
      maxWidth: size.units(8.5),
    }),

    ...style.mixins.responsive.xs.downward({
      maxWidth: size.units(7),
    }),
  },

  ':hover': {
    outlineOffset: '-2px',

    '> svg': {
      transform: 'scale(0.95)',
    },

    [TextLabelWrapper]: {
      ...style.mixins.responsive.custom(`${HostLobbyBreakdownSteps.second}px`).upward({
        opacity: 1,
      }),
    },
  },

  [TextLabelWrapper]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
}));
