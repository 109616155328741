import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslate } from 'react-polyglot';

import { usePlayAudio } from '../../../../../../../../shared/application/hooks/usePlayAudio';
import { getMatchResult } from '../utils/getMatchResult';
import { Shape } from './components/Shape/Shape';
import { AnimationSequence, MatchResult, Option, ResourceSrc } from './constants';
import {
  ContentWrapper,
  GameWrapper,
  OptionImage,
  OptionsWrapper,
  OptionWrapper,
  ResultBotImage,
  ResultBotImageWrapper,
  ResultPlayerImage,
  ResultPlayerImageWrapper,
  ResultWrapper,
  ShapeImageScaleAnimationWrapper,
  ShapeImageWrapper,
  ShapeWrapper,
  StyledBiggerSubtitle,
  StyledCloseSmallIcon,
  StyledMuteIcon,
  StyledSmallParagraph,
  StyledSmallSubtitle,
  StyledSoundIcon,
  Wrapper,
} from './MiniGame.styled';

interface MiniGameProps {
  onClose: () => void;
}

export const MiniGame = ({ onClose }: MiniGameProps) => {
  const t = useTranslate();

  const [userSelection, setUserSelection] = useState<Option | undefined>(undefined);
  const [botSelection, setBotSelection] = useState<Option | undefined>(undefined);
  const [showSelection, setShowSelection] = useState(false);
  const [matchResult, setMatchResult] = useState<MatchResult | undefined>(undefined);
  const [isMuted, setIsMuted] = useState(false);

  const optionClickAudio = usePlayAudio({
    url: 'https://audios.genially.com/play/rock-paper-scissors-game/option-click.mp3',
  });
  const matchWinAudio = usePlayAudio({
    url: 'https://audios.genially.com/play/rock-paper-scissors-game/match-win.mp3',
  });
  const matchLoseAudio = usePlayAudio({
    url: 'https://audios.genially.com/play/rock-paper-scissors-game/match-lose.mp3',
  });
  const matchDrawAudio = usePlayAudio({
    url: 'https://audios.genially.com/play/rock-paper-scissors-game/match-draw.mp3',
  });

  const [animationSequence, setAnimationSequence] = useState<AnimationSequence>(
    AnimationSequence.Stop,
  );

  const playAudio = useCallback(
    (audio: () => Promise<void>) => {
      if (isMuted) {
        return;
      }

      audio();
    },
    [isMuted],
  );

  const playMatchResultAudio = (result: MatchResult) => {
    if (result === MatchResult.Win) {
      playAudio(matchWinAudio);
    }

    if (result === MatchResult.Lose) {
      playAudio(matchLoseAudio);
    }

    playAudio(matchDrawAudio);
  };

  const handleOptionClick = (option: Option) => () => {
    playAudio(optionClickAudio);

    const botRandomSelection = Math.floor(
      Math.random() * Object.keys(ResourceSrc).length,
    );

    setShowSelection(false);
    setUserSelection(option);
    setBotSelection(botRandomSelection);

    setAnimationSequence(AnimationSequence.Start);
  };

  const handleAnimationEnd = () => {
    if (animationSequence === AnimationSequence.Start) {
      setShowSelection(true);
      setAnimationSequence(AnimationSequence.ShowResult);
    } else if (animationSequence === AnimationSequence.ShowResult) {
      const result = getMatchResult(userSelection!, botSelection!);
      playMatchResultAudio(result);
      setMatchResult(result);
      setAnimationSequence(AnimationSequence.Stop);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleToggleMute = () => {
    setIsMuted(prev => !prev);
  };

  const mathDescriptionText = useMemo(() => {
    if (userSelection === undefined || botSelection === undefined) {
      return t('__new.play.playerLobby.join.card.messageGo');
    }

    if (animationSequence === AnimationSequence.Start) {
      return t('__new.play.playerLobby.join.card.messageWait');
    }

    if (matchResult === MatchResult.Win) {
      return t('__new.play.playerLobby.join.card.messageWin');
    }

    if (matchResult === MatchResult.Lose) {
      return t('__new.play.playerLobby.join.card.messageLose');
    }

    return t('__new.play.playerLobby.join.card.messageDraw');
  }, [userSelection, botSelection, animationSequence, matchResult, t]);

  return (
    <Wrapper>
      <ContentWrapper>
        {isMuted ? (
          <StyledMuteIcon onClick={handleToggleMute} />
        ) : (
          <StyledSoundIcon onClick={handleToggleMute} />
        )}
        <StyledCloseSmallIcon onClick={handleClose} />

        <GameWrapper>
          {matchResult !== undefined && (
            <ShapeWrapper>
              <ShapeImageScaleAnimationWrapper
                $show={animationSequence === AnimationSequence.Stop}
              >
                <ShapeImageWrapper>
                  <Shape status={matchResult} />
                </ShapeImageWrapper>
              </ShapeImageScaleAnimationWrapper>
            </ShapeWrapper>
          )}
          <ResultWrapper>
            <StyledSmallParagraph>
              {t('__new.play.playerLobby.join.card.player')}
            </StyledSmallParagraph>
            <ResultPlayerImageWrapper
              $animationSequence={animationSequence}
              onAnimationEnd={handleAnimationEnd}
            >
              <ResultPlayerImage
                $option={showSelection && userSelection ? userSelection : Option.Rock}
                src={
                  showSelection && userSelection
                    ? ResourceSrc[userSelection]
                    : ResourceSrc[Option.Rock]
                }
              />
            </ResultPlayerImageWrapper>
          </ResultWrapper>
          <ResultWrapper>
            <StyledSmallParagraph>
              {t('__new.play.playerLobby.join.card.bot')}
            </StyledSmallParagraph>
            <ResultBotImageWrapper
              $animationSequence={animationSequence}
              onAnimationEnd={handleAnimationEnd}
            >
              <ResultBotImage
                $option={showSelection && botSelection ? botSelection : Option.Rock}
                src={
                  showSelection && botSelection
                    ? ResourceSrc[botSelection]
                    : ResourceSrc[Option.Rock]
                }
              />
            </ResultBotImageWrapper>
          </ResultWrapper>
        </GameWrapper>
        <StyledBiggerSubtitle>{mathDescriptionText}</StyledBiggerSubtitle>
      </ContentWrapper>

      <OptionsWrapper>
        <OptionWrapper>
          <OptionImage
            src={ResourceSrc[Option.Rock]}
            $option={Option.Rock}
            $isSelected={userSelection === Option.Rock}
            onClick={handleOptionClick(Option.Rock)}
          />
          <StyledSmallSubtitle>
            {t('__new.play.playerLobby.join.rock')}
          </StyledSmallSubtitle>
        </OptionWrapper>
        <OptionWrapper>
          <OptionImage
            src={ResourceSrc[Option.Paper]}
            $option={Option.Paper}
            $isSelected={userSelection === Option.Paper}
            onClick={handleOptionClick(Option.Paper)}
          />
          <StyledSmallSubtitle>
            {t('__new.play.playerLobby.join.paper')}
          </StyledSmallSubtitle>
        </OptionWrapper>
        <OptionWrapper>
          <OptionImage
            src={ResourceSrc[Option.Scissors]}
            $option={Option.Scissors}
            $isSelected={userSelection === Option.Scissors}
            onClick={handleOptionClick(Option.Scissors)}
          />
          <StyledSmallSubtitle>
            {t('__new.play.playerLobby.join.scissors')}
          </StyledSmallSubtitle>
        </OptionWrapper>
      </OptionsWrapper>
    </Wrapper>
  );
};
