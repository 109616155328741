import type { ErrorInfo, ReactNode } from 'react';
import React from 'react';

import {
  logExceptionToExternalService,
  logToExternalService,
} from '@genially/ui/build/services/externalLogger';

import { ErrorBoundary } from '../../../../../../shared/application/components/ErrorBoundary/ErrorBoundary';
import { getLogger } from '../../../../../../shared/infrastructure/logger';

interface AppErrorBoundaryProps {
  children: ReactNode;
  errorComponent?: ReactNode;
}

export const AppErrorBoundary = ({ children, errorComponent }: AppErrorBoundaryProps) => {
  const handleError = (error: unknown, errorInfo: ErrorInfo) => {
    const logger = getLogger();

    const label = `FATAL_CRASH`;

    if (typeof error === 'string') {
      logToExternalService(error);

      logger.error(label, {
        error,
      });
    }

    if (error instanceof Error) {
      logExceptionToExternalService(error);

      logger.error(label, {
        error: error.message,
        stack: error.stack,
        errorInfo,
      });
    }
  };

  return (
    <ErrorBoundary onError={handleError} errorComponent={errorComponent}>
      {children}
    </ErrorBoundary>
  );
};
