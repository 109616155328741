import styled from 'styled-components';

export const PlaySpinnerContainer = styled.div<{ $height?: string }>(({ $height }) => ({
  height: $height ?? 'auto',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  alignItems: 'center',
  justifyContent: 'center',
}));
