import { useEffect } from 'react';
import Lottie from 'lottie-react';

import type {
  HostPlayPostMessageConnectionViewApi,
  PlayerPlayPostMessageConnectionViewApi,
} from '@genially/contracts';
import type { PenpalAsyncMethodReturns } from '@genially/ui';

import { usePlayAudio } from '../../../../../../hooks/usePlayAudio';
import { FlagSpeed, GAME_TV_SHOW_AUDIO } from '../../constants';
import flagAnimationData from './assets/flag-animation.json';
import flagSlowAnimationData from './assets/flag-slow-animation.json';

interface FlagAnimationProps {
  onAnimationEnd: () => void;
  speed?: FlagSpeed;
  connection?:
    | PenpalAsyncMethodReturns<HostPlayPostMessageConnectionViewApi>
    | PenpalAsyncMethodReturns<PlayerPlayPostMessageConnectionViewApi>;
}

export const FlagAnimation = ({
  onAnimationEnd,
  speed = FlagSpeed.Normal,
  connection,
}: FlagAnimationProps) => {
  const playAudio = usePlayAudio({ url: GAME_TV_SHOW_AUDIO, connection });

  useEffect(() => {
    playAudio();
  }, [playAudio]);

  return (
    <Lottie
      animationData={
        speed === FlagSpeed.Normal ? flagAnimationData : flagSlowAnimationData
      }
      loop={0}
      onComplete={onAnimationEnd}
    />
  );
};
