import type { ReactNode } from 'react';
import React, { useEffect } from 'react';

import FeatureFlagProvider from '@genially/ui/build/components/FeatureFlag/FeatureFlagProvider';

import { PlaySpinner } from '../../../../../../shared/application/components/PlaySpinner/PlaySpinner';
import { useNavigation } from '../../../../../../shared/application/hooks/useNavigation';
import { RouteNames } from '../../../../../domain/RouteNames';
import { useFetchFeatureFlags } from './hooks/useFetchFeatureFlags';

export const PlayFeatureFlagProvider = ({
  children,
  teamId,
}: {
  children: ReactNode;
  teamId: string;
}) => {
  const { navigate } = useNavigation();

  const { loadingFeatureFlags, featureFlags, fetchFeatureFlagError } =
    useFetchFeatureFlags(teamId);

  useEffect(() => {
    if (fetchFeatureFlagError) {
      navigate({ to: RouteNames.NoGeniallyAccess });
    }
  }, [fetchFeatureFlagError, navigate]);

  if (loadingFeatureFlags) {
    return <PlaySpinner />;
  }

  return (
    <FeatureFlagProvider featureFlags={featureFlags}>{children}</FeatureFlagProvider>
  );
};
