import { useTranslate } from 'react-polyglot';

import { Button } from '@genially/design-system';
import { GeniallyIconIcon } from '@genially/icons';

import { config } from '../../../../bootstrap/application/config/config';
import { ErrorPageContent } from '../../components/ErrorPageContent/ErrorPageContent';
import NotFoundErrorImage from './assets/NotFoundErrorImage.png';

export const NotFoundErrorPage = () => {
  const t = useTranslate();

  const handleButtonClick = () => {
    window.location.href = config.urls.dashboard;
  };

  return (
    <ErrorPageContent
      errorCode={404}
      imageSource={NotFoundErrorImage as string}
      title={t('__new.play.errorScreen.title')}
      content={t('__new.play.errorScreen.description')}
      button={
        <Button
          StartIcon={<GeniallyIconIcon />}
          onClick={handleButtonClick}
          variant={Button.Variant.CTA}
          size={Button.Size.BIG}
        >
          {t('__new.play.errorScreen.button.return')}
        </Button>
      }
    />
  );
};
