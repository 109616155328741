import React from 'react';
import { useTranslate } from 'react-polyglot';

import { ErrorPageContent } from '../../../../shared/application/components/ErrorPageContent/ErrorPageContent';
import GeniallyIncompatibleErrorImage from '../../../../shared/application/pages/assets/GeniallyIncompatibleErrorImage.png';

export const PlayersLimitReachedPage = () => {
  const t = useTranslate();

  return (
    <ErrorPageContent
      imageSource={GeniallyIncompatibleErrorImage as string}
      title={t('__new.play.audienceLimit.player.title')}
      content={t('__new.play.audienceLimit.player.description')}
    />
  );
};
