import { useTranslate } from 'react-polyglot';
import { observer } from 'mobx-react';
import { useTheme } from 'styled-components';

import {
  BaseModal,
  BasicTitle,
  Button,
  Tooltip,
  useBreakpoint,
} from '@genially/design-system';
import { ShareIcon } from '@genially/icons';

import { HostGameBreakdownSteps } from '../../../../host/application/pages/HostGamePage/components/HostGame/HostGame.constants';
import { useNotificationManager } from '../../services/useNotificationManager';
import { shareSessionCode } from '../../utils/shareSessionCode';
import {
  ButtonsWrapper,
  ContentWrapper,
  StyledBasicParagraph,
  StyledBigSubtitle,
  StyledCopyIcon,
  StyledQRCodeSVG,
  TextWrapper,
  TopWrapper,
} from './QrCodeModal.styled';

export const QrCodeModal = observer(
  ({
    parentElement,
    closeModal,
    url,
    sessionId,
    geniallyId,
  }: {
    parentElement: HTMLElement;
    closeModal: () => void;
    url: string;
    sessionId: string;
    geniallyId: string;
  }) => {
    const t = useTranslate();
    const theme = useTheme();

    const { notificationManager } = useNotificationManager();
    const { isDownwards } = useBreakpoint();

    const isMobile = isDownwards(HostGameBreakdownSteps.forth);

    const backgroundColor = theme.color.content.reversed.default();
    const foregroundColor = theme.color.content.primary.default();

    const handleCopySessionClick = async () => {
      shareSessionCode({
        geniallyId,
        sessionId,
        isMobile,
        notificationManager,
        t,
      });
    };

    const DescriptionComponent = isMobile ? StyledBasicParagraph : StyledBigSubtitle;

    return (
      <BaseModal
        close={closeModal}
        width="55%"
        parentElement={parentElement}
        height={isMobile ? '50%' : '70%'}
      >
        <BaseModal.Content>
          <ContentWrapper>
            <TopWrapper>
              <DescriptionComponent>
                {t('__new.play.creatorLobby.join.QRinstructions.instructions')}
              </DescriptionComponent>

              <Tooltip
                text={t('__new.play.creatorLobby.join.tooltip')}
                variant={Tooltip.Variant.LIGHT}
              >
                <TextWrapper onClick={handleCopySessionClick}>
                  <BasicTitle>{sessionId}</BasicTitle>

                  {!isMobile && <StyledCopyIcon />}
                </TextWrapper>
              </Tooltip>
            </TopWrapper>

            <StyledQRCodeSVG
              value={url}
              bgColor={backgroundColor}
              fgColor={foregroundColor}
            />

            {isMobile && (
              <ButtonsWrapper>
                <Button
                  StartIcon={<ShareIcon />}
                  width="100%"
                  onClick={handleCopySessionClick}
                >
                  {t('__new.action.share')}
                </Button>
              </ButtonsWrapper>
            )}
          </ContentWrapper>
        </BaseModal.Content>
      </BaseModal>
    );
  },
);
