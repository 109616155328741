import { Fragment, useEffect, useState } from 'react';

import { PlayUserType } from '@genially/p2p-lib';
import { useEffectOnce } from '@genially/ui/build/hooks/useEffectOnce';

import { PlaySpinner } from '../../../../../../shared/application/components/PlaySpinner/PlaySpinner';
import type { RouteNavigation } from '../../../../../../shared/application/hooks/useNavigation';
import { useNavigation } from '../../../../../../shared/application/hooks/useNavigation';
import {
  GeniallyIncompatibleError,
  GeniallyIncompatibleErrorCause,
} from '../../../../../../shared/domain/errors/GeniallyIncompatibleError';
import { getLogger } from '../../../../../../shared/infrastructure/logger';
import { ViewApiService } from '../../../../../../shared/infrastructure/ViewApiService';
import { RouteNames } from '../../../../../domain/RouteNames';

const errorToRoute = (
  error: Error,
  geniallyId: string,
  userType: PlayUserType,
): RouteNavigation => {
  const isGeniallyIncompatible = error instanceof GeniallyIncompatibleError;

  if (!isGeniallyIncompatible) {
    return { to: RouteNames.UnknownError };
  }

  if (userType === PlayUserType.Player) {
    return { to: RouteNames.NoGeniallyAccess };
  }

  const errorCauseToRouteMap: Record<GeniallyIncompatibleErrorCause, RouteNavigation> = {
    [GeniallyIncompatibleErrorCause.DRAFT]: {
      to: RouteNames.GeniallyDraft,
      params: { geniallyId },
    },
    [GeniallyIncompatibleErrorCause.HAS_PASSWORD]: {
      to: RouteNames.GeniallyWithPassword,
      params: { geniallyId },
    },
  };

  return errorCauseToRouteMap[error.cause];
};

export const CheckIsGeniallyCompatibleGuard = ({
  children,
  geniallyId,
  userType,
}: {
  children: React.ReactNode;
  geniallyId: string;
  userType: PlayUserType;
}) => {
  const [checking, setChecking] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>(undefined);
  const { navigate } = useNavigation();

  useEffectOnce(() => {
    ViewApiService.verifyGeniallyIsCompatible(geniallyId)
      .catch(e => {
        setError(e);
      })
      .finally(() => {
        setChecking(false);
      });
  });

  useEffect(() => {
    if (checking || !error) {
      return;
    }

    getLogger().error('Genially is incompatible', {
      error,
      geniallyId,
      userType,
    });

    navigate(errorToRoute(error, geniallyId, userType));
  }, [navigate, checking, error, geniallyId, userType]);

  if (checking) {
    return <PlaySpinner />;
  }

  return <Fragment>{children}</Fragment>;
};
