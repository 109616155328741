import type { ElementType } from 'react';
import styled, { css, keyframes } from 'styled-components';

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
        
    to {
        opacity: 0;
    }
`;

interface WithAnimationProps {
  $hide?: boolean;
}

const withAnimation = (Component: ElementType) =>
  styled(Component)<WithAnimationProps>(
    ({ $hide }) =>
      $hide &&
      css`
        animation: ${fadeOut} 0.5s ease-in-out;
      `,
  );

export const Wrapper = withAnimation(
  styled.div(({ theme: { color } }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: color.background.reversed.default(),
    zIndex: 1,
  })),
);
