import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { TrackingInteractiveQuestionType } from '@genially/contracts';
import { PlayerPlayP2pClientEventType } from '@genially/p2p-client';

import { PlaySidebarSummary } from '../../../../../../../../shared/application/components/PlaySidebarSummary/PlaySidebarSummary';
import { usePlayerClientContext } from '../../../PlayerClientProvider/hooks/usePlayerClientContext';
import { usePlayerGameContext } from '../../PlayerGame.context';
import { WaitingToResolveAnimation } from './components/WaitingToResolveAnimation/WaitingToResolveAnimation';
import { Wrapper } from './PlayerPlaySidebarSummary.styled';

export const PlayerPlaySidebarSummary = observer(() => {
  const { client } = usePlayerClientContext();
  const { viewConnection, sidebarSummary, interactiveQuestions } = usePlayerGameContext();

  const [showWaitingToResolveAnimation, setShowWaitingToResolveAnimation] =
    useState(false);

  const handleOpenSidebarEvent = useCallback(
    async ({
      interactiveQuestionId,
      isLastScored,
    }: {
      interactiveQuestionId: string;
      isLastScored?: boolean;
    }) => {
      if (!viewConnection.isConnected) return;

      const interactiveQuestion = await interactiveQuestions.find(
        i => i.Id === interactiveQuestionId,
      );

      if (!interactiveQuestion) {
        return;
      }

      if (
        sidebarSummary.selectedQuestion?.interactiveQuestion.Id === interactiveQuestion.Id
      ) {
        return;
      }

      sidebarSummary.setSelectedQuestion({
        interactiveQuestion,
        isLastScored: isLastScored ?? false,
      });
      sidebarSummary.open();

      setShowWaitingToResolveAnimation(false);
    },
    [interactiveQuestions, sidebarSummary, viewConnection.isConnected],
  );

  const handleInteractiveQuestionAnswered = useCallback(
    ({
      interactiveQuestionId,
      type,
      isEmptyAnswer,
    }: {
      interactiveQuestionId: string;
      type: TrackingInteractiveQuestionType;
      isEmptyAnswer?: boolean;
    }) => {
      if (
        [
          TrackingInteractiveQuestionType.OpenAnswer,
          TrackingInteractiveQuestionType.Survey,
        ].includes(type)
      ) {
        handleOpenSidebarEvent({ interactiveQuestionId });
      } else if (!isEmptyAnswer) {
        setShowWaitingToResolveAnimation(true);
        sidebarSummary.open();
      }
    },
    [handleOpenSidebarEvent, sidebarSummary],
  );

  useEffect(() => {
    client.on(
      PlayerPlayP2pClientEventType.InteractiveQuestionResolved,
      handleOpenSidebarEvent,
    );

    return () => {
      client.off(
        PlayerPlayP2pClientEventType.InteractiveQuestionResolved,
        handleOpenSidebarEvent,
      );
    };
  }, [client, handleOpenSidebarEvent]);

  useEffect(() => {
    client.on(
      PlayerPlayP2pClientEventType.InteractiveQuestionAnswered,
      handleInteractiveQuestionAnswered,
    );

    return () => {
      client.off(
        PlayerPlayP2pClientEventType.InteractiveQuestionAnswered,
        handleInteractiveQuestionAnswered,
      );
    };
  }, [client, handleInteractiveQuestionAnswered]);

  const handleToggleSidebarOpen = () => {
    sidebarSummary.toggle();
  };

  return (
    <Wrapper>
      <PlaySidebarSummary
        client={client}
        isOpen={sidebarSummary.isOpen}
        toggleOpen={handleToggleSidebarOpen}
        selectedQuestion={sidebarSummary.selectedQuestion?.interactiveQuestion}
        isLoading={showWaitingToResolveAnimation}
        LoadingComponent={<WaitingToResolveAnimation />}
        isLastScoredQuestion={sidebarSummary.selectedQuestion?.isLastScored}
        connection={viewConnection.connection}
      />
    </Wrapper>
  );
});
