import { useMemo, useState } from 'react';

import { Breakpoint, useBreakpoint } from '@genially/design-system';
import { UserAliasAvatar } from '@genially/ui/build/components/UserAliasAvatar/UserAliasAvatar';

import { Size } from './constants';
import {
  IconWrapper,
  // ParticipantName,
  ParticipantNameLarge,
  ParticipantNameMedium,
  ParticipantNameParagraph,
  ParticipantNameSmall,
  StyledArrowRightToBracketIcon,
  UserAliasAvatarWrapper,
  Wrapper,
} from './Participant.styled';

interface ParticipantProps {
  name: string;
  size: Size;
  onClick?: () => void;
}

export const Participant = ({ name, size, onClick, ...rest }: ParticipantProps) => {
  const [isHover, setIsHover] = useState(false);

  const { isDownwards } = useBreakpoint();
  const isMobile = isDownwards(Breakpoint.xs);

  const userAliasHasSpaces = name.includes(' ');

  const ParticipantName = useMemo(() => {
    if (isMobile) {
      return ParticipantNameParagraph;
    }

    switch (size) {
      case Size.Small:
        return ParticipantNameSmall;
      case Size.Medium:
        return ParticipantNameMedium;
      case Size.Large:
      default:
        return ParticipantNameLarge;
    }
  }, [isMobile, size]);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleClick = () => {
    onClick?.();
  };

  return (
    <Wrapper
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <UserAliasAvatarWrapper>
        <UserAliasAvatar
          name={name}
          size={isMobile ? UserAliasAvatar.Size.Small : UserAliasAvatar.Size.Medium}
        />

        {isHover && !isMobile && (
          <IconWrapper>
            <StyledArrowRightToBracketIcon />
          </IconWrapper>
        )}
      </UserAliasAvatarWrapper>

      {/* <ParticipantName $size={size}>{name}</ParticipantName> */}
      <ParticipantName $hasSpaces={userAliasHasSpaces} $isDanger={isHover}>
        {name}
      </ParticipantName>

      {isMobile && <StyledArrowRightToBracketIcon />}
    </Wrapper>
  );
};

Participant.Size = Size;
