import { useState } from 'react';

import type { PlayPostMessageInteractiveQuestion } from '@genially/contracts';
import { useEffectOnce } from '@genially/ui/build/hooks/useEffectOnce';

import { ViewApiService } from '../../infrastructure/ViewApiService';

let cachedInteractiveQuestions: PlayPostMessageInteractiveQuestion[] | undefined;

export const useGetInteractiveQuestion = (geniallyId: string) => {
  const [isLoading, setIsLoading] = useState(cachedInteractiveQuestions === undefined);

  useEffectOnce(() => {
    if (cachedInteractiveQuestions) {
      return;
    }

    setIsLoading(true);
    ViewApiService.getInteractiveQuestions(geniallyId)
      .then(interactiveQuestions => {
        cachedInteractiveQuestions = interactiveQuestions;
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  return { isLoading, interactiveQuestions: cachedInteractiveQuestions ?? [] };
};
