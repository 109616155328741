import React, { Fragment } from 'react';
import { observer } from 'mobx-react';

import { Header } from '../../../../bootstrap/application/components/app/Routes/layouts/CommonLayout/components/Header/Header';
import { PlayerSessionSelection } from './components/PlayerSessionSelection/PlayerSessionSelection';

export const PlayerSessionSelectionPage = observer(() => {
  return (
    <Fragment>
      <Header />
      <PlayerSessionSelection />
    </Fragment>
  );
});
