import type { ReactNode } from 'react';

import { UnknownErrorPage } from '../../../../../../../shared/application/pages/UnknownErrorPage/UnknownErrorPage';
import { AppErrorBoundary } from '../AppErrorBoundary';

export const NonCriticErrorBoundary = ({ children }: { children: ReactNode }) => {
  return (
    <AppErrorBoundary errorComponent={<UnknownErrorPage />}>{children}</AppErrorBoundary>
  );
};
