import { useTranslate } from 'react-polyglot';
import { QRCodeSVG } from 'qrcode.react';
import { useTheme } from 'styled-components';

import { TextLabel } from '@genially/design-system';

import { useModal } from '../../../../../../../../bootstrap/application/components/app/components/ModalProvider/ModalProvider.context';
import { QrCodeModal } from '../../../../../../../../shared/application/components/QrCodeModal/QrCodeModal';
import { TextLabelWrapper, Wrapper } from './QRCode.styled';

export const QRCode = ({
  url,
  sessionId,
  geniallyId,
}: {
  url: string;
  sessionId: string;
  geniallyId: string;
}) => {
  const theme = useTheme();
  const t = useTranslate();

  const { openModal, closeModal } = useModal();

  const backgroundColor = theme.color.content.primary.default();
  const foregroundColor = theme.color.content.reversed.default();

  const handleOpenModal = () => {
    openModal(parentElement => (
      <QrCodeModal
        url={url}
        sessionId={sessionId}
        geniallyId={geniallyId}
        parentElement={parentElement}
        closeModal={closeModal}
      />
    ));
  };

  return (
    <Wrapper onClick={handleOpenModal}>
      <TextLabelWrapper>
        <TextLabel
          text={t('__new.play.creatorLobby.join.QRinstructions.tooltip')}
          variant={TextLabel.Variant.LIGHT}
        />
      </TextLabelWrapper>
      <QRCodeSVG value={url} bgColor={backgroundColor} fgColor={foregroundColor} />
    </Wrapper>
  );
};
