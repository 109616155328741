import type { ReactNode } from 'react';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { I18n } from 'react-polyglot';
import Polyglot from 'node-polyglot';

import { reportMissingKey } from '@genially/ui/build/services/reportMissingKeyService';
import { getSavedOrBrowserLanguage } from '@genially/ui/build/utils/language';

import type { Translations } from './utils/fetchLanguageFile';
import { fetchLanguageFile } from './utils/fetchLanguageFile';
import { logMissingKeys } from './utils/logMissingKeys';

interface LanguageManagerProviderProps {
  children: ReactNode;
  loaderComponent: ReactNode;
}

const polyglot = new Polyglot();

export const LanguageManagerProvider = ({
  children,
  loaderComponent,
}: LanguageManagerProviderProps) => {
  const language = useRef(getSavedOrBrowserLanguage());
  const [keys, setKeys] = useState<Translations | undefined>(undefined);

  useEffect(() => {
    fetchLanguageFile(language.current)
      .then(translations => {
        setKeys(translations);

        polyglot.clear();
        polyglot.extend(translations);

        document.querySelector('html')?.setAttribute('lang', language.current);
      })
      .catch(e => {
        console.error(e);

        throw new Error('Failed to fetch initial language');
      });
  }, []);

  const handleMissingKey = (key: string) => {
    reportMissingKey({
      key,
      logMissingKeys,
    });

    return key;
  };

  if (keys === undefined) {
    return <Fragment>{loaderComponent}</Fragment>;
  }

  return (
    <I18n locale={language.current} messages={keys} onMissingKey={handleMissingKey}>
      {children}
    </I18n>
  );
};
