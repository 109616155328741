import React from 'react';
import { observer } from 'mobx-react';

import { GameControlHeader } from './components/GameControlHeader/GameControlHeader';
import { HostGeniallyView } from './components/HostGeniallyView/HostGeniallyView';
import { HostPlaySidebarSummary } from './components/HostPlaySidebarSummary/HostPlaySidebarSummary';
import { HostGameProvider } from './HostGame.context';
import { ContentWrapper, PlaySidebarSummaryWrapper, Wrapper } from './HostGame.styled';

export const HostGame = observer(() => {
  return (
    <HostGameProvider>
      <Wrapper>
        <GameControlHeader />

        <ContentWrapper>
          <HostGeniallyView showGeniallyAsFullHeight />

          <PlaySidebarSummaryWrapper>
            <HostPlaySidebarSummary />
          </PlaySidebarSummaryWrapper>
        </ContentWrapper>
      </Wrapper>
    </HostGameProvider>
  );
});
