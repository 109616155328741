import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { Logo } from '@genially/design-system';

import { useLottieAnimationManager } from '../../../../../../../shared/application/components/LottieAnimation/hooks/useLottieAnimationManager';
import { LottieAnimations } from '../../../../../../../shared/application/components/LottieAnimation/types/lottieAnimations';
import { useOpenGenially } from '../../../../../../../shared/application/hooks/useOpenGenially';
import { LogoWrapper, Wrapper } from './PlayerConfigWizardLayout.styled';

export const PlayerConfigWizardLayout = () => {
  const { play, stop } = useLottieAnimationManager();

  const handleLogoClick = useOpenGenially();

  useEffect(() => {
    play(LottieAnimations.BackgroundLines);

    return () => {
      stop();
    };
  }, [play, stop]);

  return (
    <Wrapper>
      <Outlet />

      <LogoWrapper onClick={handleLogoClick}>
        <Logo variant={Logo.Variant.LIGHT} hasAnimation />
      </LogoWrapper>
    </Wrapper>
  );
};
