import { Outlet } from 'react-router-dom';

import { LottieAnimationManagerProvider } from '../../../../../../../shared/application/components/LottieAnimation/context/LottieAnimationManager.context';
import { LottieAnimationWrapper, Wrapper } from './CommonLayout.styled';

export const CommonLayout = () => {
  return (
    <Wrapper>
      <LottieAnimationManagerProvider Wrapper={LottieAnimationWrapper}>
        <Outlet />
      </LottieAnimationManagerProvider>
    </Wrapper>
  );
};
