import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { StyledMemoryIcon } from './BenchmarkButton.styled';

export const BenchmarkButton = () => {
  const { color } = useTheme();
  const [pressureColor, setPressureColor] = useState(0);

  useEffect(() => {
    if (!window.PressureObserver) {
      console.warn('PressureObserver only available in Chrome');

      return undefined;
    }

    const observer = new window.PressureObserver(changes => {
      switch (changes[0].state) {
        case 'nominal':
          setPressureColor(undefined);
          break;
        case 'fair':
          setPressureColor(color.content.success.default());
          break;
        case 'serious':
          setPressureColor(color.content.warning.default());
          break;
        case 'critical':
          setPressureColor(color.content.error.default());
          break;
        default:
          setPressureColor(undefined);
      }
    });

    observer.observe('cpu');

    return () => {
      observer.unobserve('cpu');
    };
  }, [color.content.error, color.content.success, color.content.warning]);

  return <StyledMemoryIcon $color={pressureColor} />;
};
