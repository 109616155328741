import { Flipper } from 'react-flip-toolkit';
import styled from 'styled-components';

import { style } from '@genially/design-system';
import { ArrowRightToBracketIcon } from '@genially/icons';

export const Wrapper = styled(Flipper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.size.units(1),
  overflowX: 'hidden',
  overflowY: 'auto',
  padding: `0 ${theme.size.units(1.5)}`,

  height: '100%',

  ...style.mixins.scrollbar(theme),
}));

export const KickIcon = styled(ArrowRightToBracketIcon)(({ theme: { color, size } }) => ({
  width: size.units(2),
  height: size.units(2),
  color: color.content.tertiary.default(),
  cursor: 'pointer',
}));
