import styled from 'styled-components';

import { style } from '@genially/design-system';

export const ContentWrapper = styled.div({
  display: 'flex',
});

export const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',

  [ContentWrapper]: {
    flexGrow: 1,
  },
});

export const PlaySidebarSummaryWrapper = styled.div({
  maxWidth: '40%',

  ...style.mixins.responsive.sm.downward({
    maxWidth: '100%',
  }),
});
