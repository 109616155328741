import { useEffect } from 'react';
import Lottie from 'lottie-react';

import type {
  HostPlayPostMessageConnectionViewApi,
  PlayerPlayPostMessageConnectionViewApi,
} from '@genially/contracts';
import type { PenpalAsyncMethodReturns } from '@genially/ui';

import { usePlayAudio } from '../../../../../../hooks/usePlayAudio';
import { FINAL_OUTCOME_AUDIO } from '../../constants';
import drumsAnimationData from './assets/drums-animation.json';

interface DrumsAnimationProps {
  onAnimationEnd: () => void;
  connection?:
    | PenpalAsyncMethodReturns<HostPlayPostMessageConnectionViewApi>
    | PenpalAsyncMethodReturns<PlayerPlayPostMessageConnectionViewApi>;
}

export const DrumsAnimation = ({ onAnimationEnd, connection }: DrumsAnimationProps) => {
  const playAudio = usePlayAudio({ url: FINAL_OUTCOME_AUDIO, connection });

  useEffect(() => {
    playAudio();
  }, [playAudio]);

  return (
    <Lottie animationData={drumsAnimationData} loop={1} onLoopComplete={onAnimationEnd} />
  );
};
