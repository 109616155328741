export enum FlagSpeed {
  Slow,
  Normal,
}

export const APPLAUSE_CHEERING_AUDIO =
  'https://audios.genially.com/play/applause_cheering.mp3';

export const APPLAUSE_AUDIO = 'https://audios.genially.com/play/applause.mp3';

export const CIRCUS_JUMP_AUDIO = 'https://audios.genially.com/play/circus_jump.mp3';

export const CONFETTI_PARTICLES_AUDIO =
  'https://audios.genially.com/play/confetti_particles.mp3';

export const FINAL_OUTCOME_AUDIO = 'https://audios.genially.com/play/final_outcome.mp3';

export const GAME_TV_SHOW_AUDIO = 'https://audios.genially.com/play/game_tv_show.mp3';

export const HIGH_VELOCITY_WHOOSH_AUDIO =
  'https://audios.genially.com/play/high_velocity_whoosh.mp3';

export const LOAD_GAME_1_AUDIO = 'https://audios.genially.com/play/load_game_1.mp3';

export const LOAD_GAME_2_AUDIO = 'https://audios.genially.com/play/load_game_2.mp3';
