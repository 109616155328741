import { useEffect } from 'react';
import screenfull from 'screenfull';

export const useFullScreen = ({
  isConnected,
  setIsFullScreen,
}: {
  isConnected: boolean;
  setIsFullScreen?: (isFullScreen: boolean) => void;
}) => {
  const toggleFullScreen = () => {
    if (screenfull.isEnabled) {
      if (screenfull.isFullscreen) {
        screenfull.exit();
      } else {
        screenfull.request();
      }
    }
  };

  useEffect(() => {
    if (!isConnected) return undefined;
    if (!screenfull.isEnabled) return undefined;

    const handleFullScreenChange = () => {
      setIsFullScreen?.(screenfull.isFullscreen);
    };

    screenfull.on('change', handleFullScreenChange);

    return () => {
      screenfull.off('change', handleFullScreenChange);
    };
  }, [isConnected, setIsFullScreen]);

  return { toggleFullScreen, isAvailable: screenfull.isEnabled };
};
